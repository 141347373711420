import React, { createContext, useState, useContext } from "react";

const CheckUserStatusContext = createContext();

export const UserStatusProvider = ({ children }) => {
  const [userStatus, setUserStatus] = useState("");

  return (
    <CheckUserStatusContext.Provider value={{ userStatus, setUserStatus }}>
      {children}
    </CheckUserStatusContext.Provider>
  );
};

export const useUserStatusProvider = () => useContext(CheckUserStatusContext);
