import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import UploaderComponent from "components/UploaderComponent";
import useMediaQuerry from "hooks/useMediaQuerry";
import { useUploadProgress } from "context/uploaderContext";
import UploadDocumentsForSynthesization from "hooks/uploadDocuments";
import { Toast } from "primereact/toast";
import { useUploadedFiles } from "context/upladedFilesContext";

export const DialogUploaderComponent = ({ files, setFiles, visible, setVisible, onCancel }) => {
  const [disableUpload, setDisableUpload] = useState(true);
  const { uploadedFiles, setUploadedFiles } = useUploadedFiles();
  const MAX_FILE_SIZE = 30 * 1024 * 1024;
  const toast = useRef(null);
  const { isMobile } = useMediaQuerry();
  const {
    postDocumentFiles,
    data: submitDocument,
    error: submitDocumentErr,
    loading,
    cancelRequest,
  } = UploadDocumentsForSynthesization();
  const { uploadProgress, setUploadProgress } = useUploadProgress();

  useEffect(() => {
    if (submitDocument?.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "File(s) uploaded successfully.",
        life: 1000,
      });
      setUploadedFiles([
        ...(uploadedFiles || []),
        ...(submitDocument?.results?.uploaded_files || []),
      ]);
    }
    if (submitDocumentErr?.status === 400) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: submitDocumentErr?.message || "An error occurred during the upload.",
        life: 3000,
      });
    }
  }, [submitDocument, submitDocumentErr]);

  const onUploadProgress = (event) => {
    const { loaded, total } = event;
    const percentCompleted = Math.round((loaded * 100) / total);
    setUploadProgress((prev) => ({
      ...prev,
      ...files.reduce((acc, file, index) => {
        acc[file.name] = percentCompleted;
        return acc;
      }, {}),
    }));
  };

  const handleUpload = () => {
    const formData = new FormData();
    files.forEach((fileObject, index) => {
      formData.append("files", fileObject);
    });
    postDocumentFiles(formData, onUploadProgress);
  };

  useEffect(() => {
    const allFilesComplete = Object.values(uploadProgress).every((value) => value === 100);
    setDisableUpload(!allFilesComplete);
  }, [uploadProgress]);

  const handleDialogClose = () => {
    cancelRequest();
    setVisible(false);
    setFiles([]);
    onCancel();
    setUploadProgress({});
  };
  const handleOnToastClose = () => {
    if (submitDocument?.status === 200) {
      handleDialogClose();
    }
  };

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} position="top-right" onHide={handleOnToastClose} />
      <Dialog
        header="Upload Files"
        visible={visible}
        draggable={false}
        style={{ width: isMobile ? "80vw" : "40vw" }}
        onHide={() => {
          if (!visible) return;
          cancelRequest();
          onCancel();
          setFiles([]);
          setVisible(false);
          setUploadProgress({});
        }}
      >
        <UploaderComponent
          files={files}
          setFiles={setFiles}
          fileSize={MAX_FILE_SIZE}
          disableFileLimit={10}
          uploaderComponent="dialog"
          acceptedFiles=".pdf,.doc,.docx"
          isCancelTrue={true}
          cancelRequest={cancelRequest}
        />
        <div className="flex gap-2 justify-content-end">
          <Button
            label="Cancel"
            onClick={handleDialogClose}
            className="text-sm text-gray-500 bg-white border-0 justify-end"
          />
          <Button
            label="Upload"
            loading={loading}
            disabled={files?.length < 1 || disableUpload}
            className="text-sm bg-blue-500"
            onClick={handleUpload}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default DialogUploaderComponent;
