import Step1 from "assets/WelcomeImages/Step1.svg";
import Step2 from "assets/WelcomeImages/Step2.svg";
import Step3 from "assets/WelcomeImages/Step3.svg";
import Step4 from "assets/WelcomeImages/Step4.svg";
import { Button } from "primereact/button";
import { Carousel } from "primereact/carousel";
import { Dialog } from "primereact/dialog";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./index.css";
import { Tag } from "primereact/tag";

const steps = [
  {
    image: Step1,
    title: "Let's Get Started",
    description:
      "Welcome to effortless document synthesis! Follow these 3 simple Steps, and you'll have your synthesized document in no time.",
  },
  {
    image: Step2,
    title: "Upload Your Documents",
    description:
      "Begin by uploading your files directly from your device, OneDrive, or Google Drive.",
  },
  {
    image: Step3,
    title: "Choose Your Summary Style",
    description:
      "Now, select how you want your output. Opt for a concise summary or a detailed one, tailored to your needs.",
  },
  {
    image: Step4,
    title: "Format & Generate",
    description:
      "Lastly, choose your preferred format. When you're ready, just hit “Generate,” and your synthesized document will be ready.",
  },
];

const Welcome = ({ visible = false, onHide = () => {} }) => {
  const [page, setPage] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const onBackClick = () => {
    if (page > 0) {
      setPage(page - 1);
    } else {
      onHide();
    }
  };

  const onNextClick = () => {
    if (page < steps.length - 1) {
      setPage(page + 1);
    } else {
      onHide();
    }
  };

  useEffect(() => {
    const fadeOut = () => setOpacity(0);
    const fadeIn = () => setOpacity(1);

    fadeOut();
    const timer = setTimeout(fadeIn, 300); // Adjust the timing for smoother fade

    return () => clearTimeout(timer);
  }, [page]);

  const itemTemplate = (step) => (
    <div
      className="flex flex-column gap-2 h-full"
      style={{ transition: "opacity 0.3s ease-in-out", opacity: opacity }}
    >
      {steps.indexOf(step) !== 0 && (
        <Tag
          className="bg-white text-gray-500 w-3 border-round-xl mx-auto"
          value={`Step ${steps.indexOf(step)}/3`}
        />
      )}
      <div className="flex justify-content-center">
        <h1 className="text-2xl text-center">{step.title}</h1>
      </div>
      <div className="flex justify-content-center text-center">
        <p className="text-gray-500 m-0">{step.description}</p>
      </div>
      <div className="flex justify-content-center h-full">
        <img src={step.image} alt="placeholder" className="w-8" />
      </div>
    </div>
  );

  return (
    <Dialog
      className="welcome"
      visible={visible}
      onHide={onHide}
      showHeader={false}
      contentClassName="p-0 pb-5 welcome-container border-round-lg"
    >
      <div className="flex justify-content-end w-full px-3 pb-0 pt-3">
        <Button
          icon="pi pi-times"
          className="border-circle bg-white text-gray-500 border-0 welcome-close"
          onClick={onHide}
        />
      </div>

      <div className="p-2 md:py-0 flex justify-content-center align-items-center">
        <div className="w-full md:w-10 mx-auto">
          <Carousel
            page={page}
            value={steps}
            numVisible={1}
            numScroll={1}
            itemTemplate={itemTemplate}
            responsiveOptions={[]}
          />
          <div className="flex justify-content-center mt-2 gap-5 xs:mb-1 sm:mb-2 xl:mb-4">
            <Button
              pt={{
                label: {
                  className: "font-medium",
                },
              }}
              label={page === 0 ? "Skip" : "Back"}
              className="bg-white text-gray-800 w-5 xl:w-4 skip-button "
              onClick={onBackClick}
            />
            <Button
              pt={{
                label: {
                  className: "font-medium",
                },
              }}
              label={page === steps.length - 1 ? "Start Using" : page === 0 ? "Begin" : "Next"}
              className="bg-primary text-white w-5 xl:w-4"
              onClick={onNextClick}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

Welcome.propTypes = {
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

export default Welcome;
