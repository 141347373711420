import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { getFileIcon } from "utils/helper";
import { ReactComponent as AddCategory } from "assets/SpaceIcons/AddCategory.svg";
import { RadioButton } from "primereact/radiobutton";
import { ProgressSpinner } from "primereact/progressspinner";
import "./index.css";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import PDFViewerComponent from "components/DocumentEngine/FileViewer/FileViewerComponent";
import useApi from "services/api";
import { useParams } from "react-router-dom";
import useSpaces from "hooks/useSpace";
import LocalDrive from "assets/SpaceIcons/LocalDrive.svg";
import CategoryList from "./CategoryList";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Menu } from "primereact/menu";
import { Checkbox } from "primereact/checkbox";
import { Tag } from "primereact/tag";
import { Chip } from "primereact/chip";
import { OverlayPanel } from "primereact/overlaypanel";

const tableStyles = {
  wrapper: {
    position: "relative",
    maxHeight: "fit-content",
  },
  header: {
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  body: {
    overflowY: "auto",
    maxHeight: "calc(400px - 43px)",
  },
};

const ActionIcons = ({ onPreviewClick, onEditClick, onFactCheckClick, onDeleteClick }) => (
  <div className="action-icons">
    <i
      className="pi pi-pencil cursor-pointer"
      onClick={onEditClick}
      aria-controls="popup_menu_right"
      aria-haspopup
    ></i>
    <i className="pi pi-eye cursor-pointer" onClick={onPreviewClick}></i>
    <i className="pi pi-file cursor-pointer" onClick={onFactCheckClick}></i>
    <i className="pi pi-trash cursor-pointer" onClick={onDeleteClick}></i>
  </div>
);

const DocumentTable = ({
  documents,
  selectedCategory,
  setDocuments,
  loading,
  categories,
  fetchCategories,
  getFilesFolders,
  setPreviewDocument,
  setShowPreview,
  refreshData,
}) => {
  const menuRight = useRef(null);
  const { spaceId } = useParams();
  const { updateSpaceCategory, deleteDocument } = useSpaces();
  const [expandedRows, setExpandedRows] = useState(() => {
    const initialExpanded = {};
    documents.forEach((doc) => {
      if (doc.isFolder) {
        initialExpanded[doc.id] = true;
      }
    });
    return initialExpanded;
  });
  const [showSummaryDialog, setShowSummaryDialog] = useState(false);
  const [selectedSummary, setSelectedSummary] = useState("");
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const toast = useRef(null);
  const [expandedLoading, setExpandedLoading] = useState({});
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const items = [
    {
      items: [
        {
          template: () => (
            <div className="flex flex-column gap-2 w-full">
              <h3 className="text-sm font-bold mb-2 mt-0">Edit Category</h3>
              <IconField iconPosition="left">
                <InputIcon className="pi pi-search"> </InputIcon>
                <InputText
                  placeholder="Search categories"
                  className="w-full"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </IconField>
            </div>
          ),
        },
        {
          template: () => (
            <div className="flex flex-column gap-2 w-full my-3">
              {categories
                .filter(
                  (category) =>
                    category.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
                    category.id !== "all",
                )
                .map((category) => (
                  <div key={category.id} className="p-field-checkbox flex items-center w-full">
                    <Checkbox
                      inputId={category.id}
                      checked={selectedCategories.includes(category.id)}
                      onChange={() => handleCategorySelect(category.id)}
                      className="mr-2 flex-none"
                    />
                    <label
                      htmlFor={category.id}
                      className="flex-1 flex align-items-center gap-2"
                      style={{ maxWidth: "calc(100% - 5rem)" }}
                    >
                      <div
                        className="border-circle flex-shrink-0 my-auto"
                        style={{ backgroundColor: category.color, width: "12px", height: "12px" }}
                      />
                      <span className="truncate whitespace-nowrap overflow-hidden text-ellipsis">
                        {category.name}
                      </span>
                    </label>
                  </div>
                ))}
            </div>
          ),
        },
        {
          template: () => (
            <div className="flex flex-column gap-2 w-full mt-1 pt-2 align-items-end border-top-1 border-gray-300 rounded-lg">
              <Button
                loading={isSaving}
                className="p-button-primary text-xs font-light w-max py-1 px-2"
                onClick={async (event) => {
                  setIsSaving(true);
                  try {
                    await updateSpaceCategory(selectedDocument, selectedCategories);
                    if (menuRight.current) {
                      menuRight.current.toggle(event);
                    }
                    toast.current.show({
                      severity: "success",
                      summary: "Success",
                      detail: "Category updated successfully",
                      life: 3000,
                    });
                    setSelectedCategories([]);
                    const documentsData = await getFilesFolders(spaceId);
                    setDocuments(documentsData);
                    await fetchCategories(documentsData.length);
                  } catch (error) {
                    toast.current.show({
                      severity: "error",
                      summary: "Error",
                      detail: "Failed to update category",
                      life: 3000,
                    });
                  } finally {
                    setIsSaving(false);
                  }
                }}
                label="Save Changes"
                disabled={isSaving}
              />
            </div>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    const loadFolderContents = async () => {
      for (const doc of documents) {
        if (doc.isFolder) {
          try {
            const expandedData = await getFilesFolders(spaceId, doc.id);
            setDocuments((prevData) => {
              const filteredData = prevData.filter((item) => item.parentFolder !== doc.id);
              return [...filteredData, ...expandedData];
            });
          } catch (error) {
            // console.error("Error fetching folder contents:", error);
          }
        }
      }
    };

    loadFolderContents();
  }, []);

  const handleEditClick = (event, rowData) => {
    event.stopPropagation();
    menuRight.current.toggle(event);
    setSelectedDocument(rowData?.id);
    setSelectedCategories(
      rowData?.documentCategory
        .map((category) => {
          if (typeof category === "string" && category.toLowerCase() === "uncategorized") {
            return null;
          }
          return category?.id;
        })
        .filter(Boolean) || [],
    );
  };

  const handlePreviewClick = (e, rowData) => {
    e.preventDefault();
    e.stopPropagation();

    if (!rowData) return;

    setPreviewDocument({
      fileUrl: rowData.fileUrl || "https://pdfobject.com/pdf/sample.pdf",
      fileName: rowData.templateName || rowData.fileName || "document.pdf",
    });
    setShowPreview(true);
  };

  const handleDeleteClick = async (e) => {
    try {
      await deleteDocument(documentToDelete.id);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Document deleted successfully",
        life: 3000,
      });
      refreshData();
    } catch (error) {
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.detail || "Failed to delete document",
        life: 3000,
      });
    } finally {
      setDeleteDialogVisible(false);
      setDocumentToDelete(null);
      setIsDeleting(false);
    }
  };

  const allowExpansion = (rowData) => {
    return rowData.isFolder;
  };

  const onRowExpand = async (event) => {
    try {
      setExpandedLoading((prev) => ({ ...prev, [event.data.id]: true }));

      const expandedData = await getFilesFolders(spaceId, event.data.id);
      setDocuments((prevData) => {
        const filteredData = prevData.filter((item) => item.parentFolder !== event.data.id);
        return [...filteredData, ...expandedData];
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch folder contents",
        life: 3000,
      });
    } finally {
      setExpandedLoading((prev) => ({ ...prev, [event.data.id]: false }));
    }
  };

  const header = (
    <div className="data-content-header flex flex-col justify-content-end">
      <div className="search-bar flex align-items-center justify-center bg-white border border-gray-300 rounded-lg">
        <span className="p-input-icon-left">
          <i className="pi pi-search pl-3 font-extralight text-[#6C757D] opacity-80" />
        </span>

        <IconField iconPosition="left">
          <InputIcon className="pi pi-search text-sm" />
          <InputText
            value={search}
            className="text-sm w-18rem"
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search by file or folder"
          />
        </IconField>
      </div>
      <div className="flex align-items-center justify-center">
        <Button
          icon="pi pi-folder"
          severity="info"
          className="pt-0"
          label="New Folder"
          iconPos="right"
        />
      </div>
    </div>
  );

  const documentCategoryTemplate = (rowData) => {
    return <CategoryTags rowData={rowData} />;
  };

  const sourceBodyTemplate = (rowData) => {
    return !rowData?.isFolder ? (
      <div className="source-container">
        <div className="flex align-items-center">
          <img
            src={rowData?.source?.connector?.icon_url || LocalDrive}
            alt={rowData?.source?.connector?.name}
            className="source-icon flex-shrink-0"
            style={{
              width: "20px",
              height: "20px",
              minWidth: "20px",
            }}
          />
          <span className="ml-2 text-sm">{rowData?.source?.connector?.name}</span>
        </div>
        {/* {rowData?.source?.email && (
          <span className="source-email">{rowData.source.email}</span>
        )} */}
      </div>
    ) : null;
  };

  const statusBodyTemplate = (rowData) => {
    if (rowData?.isFolder) return null;

    const getStatusClass = (status) => {
      switch (status?.toLowerCase()) {
        case "processing":
          return "warning";
        case "completed":
          return "success";
        case "failed":
          return "danger";
        default:
          return "";
      }
    };

    const isHovered = hoveredRowId === rowData.id;

    if (isHovered) {
      return (
        <ActionIcons
          onEditClick={(e) => handleEditClick(e, rowData)}
          onPreviewClick={(e) => handlePreviewClick(e, rowData)}
          onFactCheckClick={(e) => {
            e.stopPropagation();
          }}
          onDeleteClick={(e) => {
            e.stopPropagation();
            setDocumentToDelete(rowData);
            setDeleteDialogVisible(true);
          }}
        />
      );
    }

    return (
      <div className="status-indicator">
        {/* <span className={getStatusClass(rowData?.status?.state)}>
          {rowData?.status?.state}
        </span> */}
        <Tag
          className="w-max text-xs font-medium"
          value={rowData?.status?.state}
          severity={getStatusClass(rowData?.status?.state)}
        />
        <span className="timestamp">{rowData?.status?.date}</span>
      </div>
    );
  };

  const fileNameTemplate = (rowData) => {
    const handleChevronClick = (e, rowData) => {
      e.stopPropagation();

      if (!rowData.isFolder) return;

      const isCurrentlyExpanded = expandedRows[rowData.id];

      if (isCurrentlyExpanded) {
        const newExpandedRows = { ...expandedRows };
        delete newExpandedRows[rowData.id];
        setExpandedRows(newExpandedRows);
      } else {
        setExpandedRows({
          ...expandedRows,
          [rowData.id]: true,
        });
        onRowExpand({ data: rowData });
      }
    };

    const isExpanded = expandedRows[rowData.id];

    return (
      <div className="flex align-items-center">
        {rowData?.isFolder ? (
          <i
            className={`pi ${isExpanded ? "pi-chevron-down" : "pi-chevron-right"} mr-2 folder-chevron-icon`}
            onClick={(e) => handleChevronClick(e, rowData)}
          ></i>
        ) : (
          <i className={`${getFileIcon(rowData?.fileName)} mr-2`}></i>
        )}
        <span className={`summary-text ${rowData?.isFolder && "font-bold"}`}>
          {rowData?.fileName}
        </span>
      </div>
    );
  };

  const summaryBodyTemplate = (rowData) => {
    const handleShowFullSummary = (e) => {
      e.stopPropagation(); // Prevent row expansion when clicking the icon
      setSelectedSummary(rowData.summary);
      setShowSummaryDialog(true);
    };

    return !rowData.isFolder && rowData?.summary ? (
      <div className="flex align-items-center">
        <div style={{ width: "80%" }}>
          <span className="summary-text">{rowData?.summary}</span>
        </div>
        <i
          className="pi pi-chevron-down ml-2 cursor-pointer"
          onClick={handleShowFullSummary}
          style={{ flexShrink: 0 }}
        ></i>
      </div>
    ) : (
      <></>
    );
  };

  const dialogFooter = (
    <div>
      <Divider className="text-[#DEE2E6]" />
      <Button label="Okay" onClick={() => setShowSummaryDialog(false)} />
    </div>
  );

  const SkeletonRow = () => {
    return (
      <div className="flex align-items-center gap-3 p-2">
        <div className="w-3rem h-2rem bg-gray-200 border-round"></div>
        <div className="w-8 h-2rem bg-gray-200 border-round"></div>
        <div className="w-8 h-2rem bg-gray-200 border-round"></div>
        <div className="w-8 h-2rem bg-gray-200 border-round"></div>
        <div className="w-3rem h-2rem bg-gray-200 border-round"></div>
      </div>
    );
  };

  const parentDocuments = documents.filter((doc) => {
    if (doc.parentFolder !== null) return false;
    if (selectedCategory === "all") return true;

    if (doc.isFolder) {
      return documents.some(
        (child) =>
          // Check immediate children
          (child.parentFolder === doc.id &&
            child.documentCategory.map((e) => e.id).includes(selectedCategory)) ||
          // Check grandchildren
          documents.some(
            (grandChild) =>
              grandChild.parentFolder === child.id &&
              grandChild.documentCategory.map((e) => e.id).includes(selectedCategory),
          ),
      );
    } else {
      // For non-folder documents, check their own categories
      return doc.documentCategory.map((e) => e.id).includes(selectedCategory);
    }
  });

  const rowExpansionTemplate = (data) => {
    const childDocuments = documents.filter((doc) => doc.parentFolder === data.id);

    const filteredChildren =
      selectedCategory === "all"
        ? childDocuments
        : childDocuments.filter((doc) => {
          if (doc.isFolder) {
            return documents.some(
              (child) =>
                child.parentFolder === doc.id &&
                child.documentCategory.map((e) => e.id).includes(selectedCategory),
            );
          }
          return doc.documentCategory.map((e) => e.id).includes(selectedCategory);
        });

    return expandedLoading[data.id] ? (
      <div className="p-2">
        <SkeletonRow />
        <SkeletonRow />
      </div>
    ) : (
      <DataTable
        value={filteredChildren}
        className="p-datatable-sm"
        showHeaders={false}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowExpand={onRowExpand}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="id"
        selectionMode="single"
        onRowMouseEnter={(e) => setHoveredRowId(e.data.id)}
        onRowMouseLeave={() => setHoveredRowId(null)}
        onRowClick={(e) => {
          if (e.originalEvent.target.closest(".action-icons")) {
            e.originalEvent.stopPropagation();
            return;
          }
        }}
      >
        <Column body={fileNameTemplate} />
        <Column body={sourceBodyTemplate} />
        <Column body={summaryBodyTemplate} />
        <Column
          header="Document Category"
          body={documentCategoryTemplate}
          headerStyle={tableStyles.header}
        />
        <Column body={statusBodyTemplate} />
      </DataTable>
    );
  };

  const rowClassName = (rowData) => {
    return {
      "cursor-pointer": !rowData.isFolder,
      "hoverable-row": !rowData.isFolder,
    };
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(categoryId)
        ? prevSelected.filter((id) => id !== categoryId)
        : [...prevSelected, categoryId],
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <Menu model={items} popup ref={menuRight} className="p-3 w-full max-w-17rem" />
      <Dialog
        header="Summary"
        visible={showSummaryDialog}
        style={{ width: "50vw" }}
        onHide={() => setShowSummaryDialog(false)}
        footer={dialogFooter}
        draggable={false}
      >
        <p className="m-0">{selectedSummary}</p>
      </Dialog>
      <Dialog
        visible={deleteDialogVisible}
        onHide={() => setDeleteDialogVisible(false)}
        header="Confirmation"
        modal
        draggable={false}
        style={{ width: "50vw" }}
        className="delete-dialog"
        footer={
          <div className="flex justify-content-end">
            <Button
              label="No"
              icon="pi pi-times"
              severity="secondary"
              className="border-none"
              onClick={() => {
                setDeleteDialogVisible(false);
                setDocumentToDelete(null);
              }}
              outlined
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              loading={isDeleting}
              onClick={() => {
                setIsDeleting(true);
                handleDeleteClick();
              }}
              autoFocus
            />
          </div>
        }
      >
        <div className="inline-flex justify-content-center items-baseline gap-2">
          <i className="pi pi-exclamation-triangle mt-3 text-black text-3xl my-auto" />
          <p className="text-gray-600 font-light text-base">Are you sure you want to delete it?</p>
        </div>
      </Dialog>
      <div style={tableStyles.wrapper}>
        <div className="p-datatable-header">{header}</div>

        {loading ? (
          <div className="p-2">
            <SkeletonRow />
            <SkeletonRow />
            <SkeletonRow />
          </div>
        ) : (
          <DataTable
            value={parentDocuments}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            onRowExpand={onRowExpand}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            className="p-datatable-sm"
            scrollable
            scrollHeight="400px"
            tableStyle={{ minWidth: "50rem" }}
            rowClassName={rowClassName}
            selectionMode="single"
            scrollMode="virtual"
            onRowMouseEnter={(e) => setHoveredRowId(e.data.id)}
            onRowMouseLeave={() => setHoveredRowId(null)}
            onRowClick={(e) => {
              if (e.originalEvent.target.closest(".action-icons")) {
                e.originalEvent.stopPropagation();
                return;
              }
            }}
          >
            <Column body={fileNameTemplate} header="File Name" />
            <Column body={sourceBodyTemplate} header="Source" />
            <Column body={summaryBodyTemplate} header="Summary" />
            <Column body={documentCategoryTemplate} header="Document Category" />
            <Column body={statusBodyTemplate} header="Indexing Status" />
          </DataTable>
        )}
      </div>
    </>
  );
};

const DottedSpinner = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.99937" cy="1.9359" r="1.4359" fill="#2D00F7" />
    <circle cx="7.99937" cy="15.0643" r="1.4359" fill="#BFBFBF" />
    <circle
      cx="1.4359"
      cy="8.50014"
      r="1.4359"
      transform="rotate(-90 1.4359 8.50014)"
      fill="#BFBFBF"
    />
    <circle
      cx="14.5638"
      cy="8.50014"
      r="1.4359"
      transform="rotate(-90 14.5638 8.50014)"
      fill="#BFBFBF"
    />
    <circle
      cx="3.35781"
      cy="3.8584"
      r="1.4359"
      transform="rotate(-45 3.35781 3.8584)"
      fill="#BFBFBF"
    />
    <circle
      cx="12.641"
      cy="13.1416"
      r="1.4359"
      transform="rotate(-45 12.641 13.1416)"
      fill="#BFBFBF"
    />
    <circle
      cx="12.6416"
      cy="3.8583"
      r="1.4359"
      transform="rotate(45 12.6416 3.8583)"
      fill="#BFBFBF"
    />
    <circle
      cx="3.3584"
      cy="13.1415"
      r="1.4359"
      transform="rotate(45 3.3584 13.1415)"
      fill="#BFBFBF"
    />
  </svg>
);

const DataContent = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const { spaceId } = useParams();
  const [documents, setDocuments] = useState([]);
  const { getFilesFolders, getSpaceCategoriesCount } = useSpaces();
  const toast = useRef(null);
  const [loading, setLoading] = useState(true);

  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);

  const [previewDocument, setPreviewDocument] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const fetchCategories = useCallback(
    async (totalFiles) => {
      if (!spaceId) return;

      try {
        setCategoriesLoading(true);
        const categoriesData = await getSpaceCategoriesCount(spaceId);

        if (categoriesData) {
          const allCategories = [
            ...categoriesData.map((cat) => ({
              id: cat.id,
              name: cat.name,
              total: cat.no_of_files_attached,
              color: cat.color || "#3B82F6",
            })),
          ];
          setCategories(allCategories);
        }
      } catch (error) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch categories",
        });
      } finally {
        setCategoriesLoading(false);
      }
    },
    [spaceId, getSpaceCategoriesCount, toast],
  );

  const fetchInitialData = async () => {
    if (!spaceId) return;

    setLoading(true);
    try {
      const documentsData = await getFilesFolders(spaceId);
      setDocuments(documentsData);
      const totalFiles = documentsData.length;
      await fetchCategories(totalFiles);
    } catch (error) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data",
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, [spaceId]);

  const filteredDocuments = useMemo(
    () =>
      selectedCategory === "all"
        ? documents
        : documents.filter((doc) => {
          if (doc.isFolder) {
            const hasMatchingChildren = documents.some(
              (child) =>
                child.parentFolder === doc.id &&
                child.documentCategory.map((e) => e.id).includes(selectedCategory),
            );
            return hasMatchingChildren;
          }
          return doc.documentCategory.map((e) => e.id).includes(selectedCategory);
        }),
    [documents, selectedCategory],
  );

  const handleClosePreview = useCallback(() => {
    setShowPreview(false);
    setPreviewDocument(null);
  }, []);

  return (
    <div className="data-content-container">
      <div className="grid">
        <Toast ref={toast} />
        <div className="col-fixed data-content-category-list pt-0 mt-7" style={{ width: "20%" }}>
          <CategoryList
            selectedCategory={selectedCategory}
            onCategorySelect={setSelectedCategory}
            spaceId={spaceId}
            categories={categories}
            loading={categoriesLoading}
            onRefreshCategories={fetchCategories}
            toast={toast}
          />
        </div>
        <div className="col data-content-table pt-0">
          <DocumentTable
            documents={filteredDocuments}
            selectedCategory={selectedCategory}
            setDocuments={setDocuments}
            loading={loading}
            categories={categories}
            fetchCategories={fetchCategories}
            getFilesFolders={getFilesFolders}
            setPreviewDocument={setPreviewDocument}
            setShowPreview={setShowPreview}
            refreshData={fetchInitialData}
          />
        </div>
      </div>
      {showPreview && previewDocument && (
        <PDFViewerComponent
          key={`pdf-viewer-${previewDocument.fileUrl}`}
          fileUrl={previewDocument.fileUrl}
          fileName={previewDocument.fileName}
          onClose={handleClosePreview}
        />
      )}
    </div>
  );
};

const CategoryTags = ({ rowData }) => {
  const overlayPanelRef = useRef(null);
  const content = (category, className = "") => (
    <div className="flex flex-row align-items-center py-1 px-2">
      <span
        className="border-circle flex align-items-center justify-content-center"
        style={{ width: "8px", height: "8px", backgroundColor: category.color }}
      ></span>
      <span
        className={`ml-2 font-light text-xs text-overflow-ellipsis overflow-hidden whitespace-nowrap ${className}`}
        style={{ maxWidth: "5rem" }}
      >
        {category.name}
      </span>
    </div>
  );

  if (rowData?.isFolder) {
    return null;
  }

  if (rowData?.documentCategory?.includes("uncategorized")) {
    return (
      <div className="flex flex-row align-items-center">
        <div>
          <DottedSpinner />
        </div>
        <span className="text-sm ml-2">Uncategorized</span>
      </div>
    );
  }

  const categories = rowData?.documentCategory || [];

  if (categories.length === 0) {
    return null;
  }

  if (categories.length <= 2) {
    return (
      <div className="flex flex-wrap gap-1">
        {categories.map((category, index) => (
          <Chip className="px-1" template={content(category)} key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-wrap gap-1">
      <div>
        <Chip className="px-1" template={content(categories[0])} />
        <Chip className="px-1" template={content(categories[1])} />
        <Chip
          className="px-1 cursor-pointer text-xs count-chip"
          label={`+${categories.length - 2}`}
          onClick={(e) => overlayPanelRef.current.toggle(e)}
        />
      </div>
      <OverlayPanel
        ref={overlayPanelRef}
        pt={{
          content: {
            className: "p-1",
            style: { minWidth: "10rem" },
          },
        }}
      >
        <div className="flex flex-column gap-1">
          {categories.slice(2).map((category, index) => (
            <Chip
              className="px-1 bg-white text-sm"
              template={content(category, "text-sm")}
              key={index}
            />
          ))}
        </div>
      </OverlayPanel>
    </div>
  );
};

export default DataContent;
