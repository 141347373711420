// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.facts-checklist-report {
  width: 62rem;

  .action-button {
    color: #3b82f6;
  }
  .p-accordion .p-accordion-header .p-accordion-header-link {
    border-radius: 6px !important;
  }

  .facts-checklist-report-extracted-data {
    width: fit-content;
  }
  .confidence-number {
    width: 0.75rem;
    height: 0.75rem;
    font-size: 0.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-left: 0.375rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Spaces/Facts/FactSheet.css"],"names":[],"mappings":"AAAA;EACE,YAAY;;EAEZ;IACE,cAAc;EAChB;EACA;IACE,6BAA6B;EAC/B;;EAEA;IACE,kBAAkB;EACpB;EACA;IACE,cAAc;IACd,eAAe;IACf,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,qBAAqB;EACvB;AACF","sourcesContent":[".facts-checklist-report {\n  width: 62rem;\n\n  .action-button {\n    color: #3b82f6;\n  }\n  .p-accordion .p-accordion-header .p-accordion-header-link {\n    border-radius: 6px !important;\n  }\n\n  .facts-checklist-report-extracted-data {\n    width: fit-content;\n  }\n  .confidence-number {\n    width: 0.75rem;\n    height: 0.75rem;\n    font-size: 0.375rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    align-self: center;\n    margin-left: 0.375rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
