import React, { useState, useRef, Fragment, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { SelectButton } from "primereact/selectbutton";
import "./CreditSummary.css";
import useCreditSummary from "hooks/useCreditSummary";
import { FilterMatchMode } from "primereact/api";
import { Paginator } from "primereact/paginator";
import { Calendar } from "primereact/calendar";
import { Chip } from "primereact/chip";
import { format } from "date-fns";
import { convertDateObjectToTimestamp, capitalizeFirstLetter } from "utils/utils";

export default function CreditsHistory() {
  const toast = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const { getCreditHistory, loading } = useCreditSummary();
  const [selectedRange, setSelectedRange] = useState("7D");
  const [creditHistory, setCreditHistory] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [creditPagination, setCreditPagination] = useState(null);
  const calendarRef = useRef(null);
  const [filters, setFilters] = useState({
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      email: { value: "", matchMode: FilterMatchMode.CONTAINS },
      start_date: { value: "", matchMode: FilterMatchMode.EQUALS },
      end_date: { value: "", matchMode: FilterMatchMode.EQUALS },
    },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _lazyState = { ...lazyState };

    _lazyState.filters.email.value = value;
    _lazyState.first = 0;
    _lazyState.page = 1;

    setLazyState(_lazyState);
    setGlobalFilterValue(value);
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onDateSelectionChange = () => {
    // const formattedDate = (date) => new Date(date).toISOString().slice(0, 19);
    const today = new Date();
    const sevenDaysAgo = new Date(today).setDate(today.getDate() - 6);
    let endDate = null;
    if (dateRange?.length > 1) {
      endDate = convertDateObjectToTimestamp(dateRange[1]).replace("00:00:00", "23:59:59");
    }

    let _lazyState = { ...lazyState };
    _lazyState.filters.start_date.value =
      selectedRange === "custom"
        ? dateRange && convertDateObjectToTimestamp(dateRange[0])
        : selectedRange === "7D"
          ? `${convertDateObjectToTimestamp(sevenDaysAgo).split("T")[0]}T00:00:00`
          : `${convertDateObjectToTimestamp(today).split("T")[0]}T00:00:00`;
    _lazyState.filters.end_date.value =
      selectedRange === "custom" && endDate
        ? endDate
        : selectedRange === "7D" || selectedRange === "1D"
          ? `${convertDateObjectToTimestamp(today).split("T")[0]}T23:59:59`
          : "";

    _lazyState.first = 0;
    _lazyState.page = 1;
    setLazyState(_lazyState);
  };

  const dateFomatChange = (date) => {
    const timestamp = format(date, "dd/MM/yyyy");
    return timestamp;
  };

  const fetchCreditHistory = async () => {
    const { page, rows, filters } = lazyState;
    const params = {
      page,
      page_size: rows,
      email: filters.email.value,
      start_date: filters.start_date.value,
      end_date: filters.end_date.value,
    };
    const data = await getCreditHistory(params);
    if (data.results.data) {
      setCreditHistory(data.results.data.data);
      const dataResult = { ...data };
      delete dataResult.results.data.data;
      setCreditPagination(dataResult.results.data);
    }
  };

  useEffect(() => {
    fetchCreditHistory();
  }, [lazyState]);

  // const toggleCalendar = () => {
  //   setShowDatePicker(!showDatePicker);
  // };

  useEffect(() => {
    if (selectedRange === "custom" && dateRange && dateRange[0] && dateRange[1]) {
      setShowDatePicker(false);
    }
    onDateSelectionChange();
    // }
  }, [dateRange, selectedRange]);

  useEffect(() => {
    if (selectedRange === "custom") {
      setShowDatePicker(true);
    } else {
      // setDateRange(null);
      setDateRange(null);
      setShowDatePicker(false);
      //   fetchCreditHistory();
    }
  }, [selectedRange]);

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end w-full">
        <div className="flex justify-content-end gap-2">
          {dateRange?.length > 1 && dateRange[1] && (
            <Chip
              style={{
                fontSize: "10px",
                fontWeight: 600,
                height: "1rem",
              }}
              label={
                dateRange && dateRange?.length === 2
                  ? `${dateFomatChange(dateRange[0])}-${dateFomatChange(dateRange[1])}`
                  : ""
              }
              className="border-1 border-400 mt-3"
              removable
              removeIcon="pi pi-times text-xs"
              onRemove={() => {
                setDateRange(null);
                // showDatePicker(false);
              }}
            />
          )}
          <SelectButton
            pt={{
              button: {
                className: "text-500  font-semibold text-sm",
              },
            }}
            size={"small"}
            value={selectedRange}
            className="credit-history-btn"
            onChange={(e) => {
              if (e.value === "custom" || selectedRange === "custom") {
                setShowDatePicker(true);
              }
              if (e.value === null) {
                return;
              }
              setSelectedRange(e.value);
              // if (e.value === "custom") {
              //   toggleCalendar();
              // }
            }}
            options={[
              {
                label: "1D",
                value: "1D",
              },
              {
                label: "7D",
                value: "7D",
              },
              { icon: "pi pi-calendar", value: "custom" },
            ]}
            itemTemplate={(option) =>
              option?.icon ? <i className="pi pi-calendar" /> : <span>{option.label}</span>
            }
          />
          {showDatePicker && (
            <div style={{ position: "absolute", zIndex: 1000, top: "5rem" }} ref={calendarRef}>
              <Calendar
                value={dateRange}
                onChange={(e) => setDateRange(e.value)}
                selectionMode="range"
                readOnlyInput
                showIcon
                className="ml-2 credit"
                dateFormat="d/m/y"
                inline
              />
            </div>
          )}
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search text-sm" />
            <InputText
              value={globalFilterValue}
              className="text-sm h-2"
              onChange={onGlobalFilterChange}
              placeholder="Search user Email"
            />
          </IconField>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const handlePageChange = (event) => {
    const { rows: currRows, page, first: currFirst } = event;

    let _lazyState = { ...lazyState };
    _lazyState.first = currFirst;
    _lazyState.rows = currRows;
    _lazyState.page = parseInt(page) + 1;
    setLazyState(_lazyState);
  };

  const createdAtTransaction = (rowData) => {
    const { transaction_date } = rowData;
    const timestamp = moment(transaction_date).format("DD/MM/YYYY");

    const transactionDate = <span className=" mt-1">{timestamp}</span>;
    return transactionDate;
  };

  const getAllocatedValue = (rowData) => {
    const { allocated_to } = rowData;
    const allocated = allocated_to["email"];
    return allocated;
  };

  const formatTransactionType = (rowData) => {
    const { transaction_type } = rowData;
    return capitalizeFirstLetter(transaction_type);
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="card my-5 creditSummaryTable" style={{ height: "70vh" }}>
        <div className="text-2xl font-medium mb-3">Organization Credit Summary</div>

        <DataTable
          value={creditHistory}
          rowsPerPageOptions={[5, 10, 20]}
          rows={10}
          loading={loading}
          tableStyle={{ minWidth: "50rem" }}
          filters={filters}
          header={header}
          emptyMessage="No credit transaction found"
          onFilter={(e) => setFilters(e.filters)}
          className="w-full"
          scrollable
          scrollHeight="flex"
          dataKey="id"
          selectionMode={"checkbox"}
        >
          <Column
            field="transaction_date"
            header="Transaction Date"
            style={{ width: "8%" }}
            body={createdAtTransaction}
          ></Column>
          <Column
            field="transaction_type"
            header="Transaction Type"
            // sortable
            style={{ width: "14%" }}
            body={formatTransactionType}
          ></Column>
          <Column
            field="allocated_to"
            header="Allocated To"
            style={{ width: "14%" }}
            body={getAllocatedValue}
          ></Column>
          <Column
            field="transaction_amount"
            header="Credits"
            // sortable
            style={{ width: "16%" }}
          ></Column>
          <Column
            field="org_credit_pool_closing_balance"
            header="Org Credit Pool Closing Balance"
            style={{ width: "18%" }}
          ></Column>
        </DataTable>
        <Paginator
          first={lazyState.first}
          rows={creditPagination?.page_size}
          template={{
            layout:
              "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
          }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          totalRecords={creditPagination?.total_pages}
          rowsPerPageOptions={[10, 20, 30]}
          onPageChange={handlePageChange}
        ></Paginator>
      </div>
    </Fragment>
  );
}
