import useApi from "services/api";
import { API_URL } from "../constants";

const UploadDocumentsForSynthesization = () => {
  const { data, loading, error, upload, cancelRequest, get } = useApi();
  const contentType = {
    "Content-Type": "multipart/form-data",
  };
  const postDocumentFiles = async (body, onUploadProgress) => {
    const data = await upload(`${API_URL}/file/upload/`, body, contentType, onUploadProgress, {});
    return data;
  };

  const getCarbonToken = async () => {
    const response = await get(`${API_URL}/carbon/access_token`);
    return response;
  };
  return { postDocumentFiles, getCarbonToken, data, loading, error, cancelRequest };
};

export default UploadDocumentsForSynthesization;
