import { format, parse } from "date-fns";

export const capitalizeFirstLetter = (word) => {
  return word?.charAt?.(0)?.toUpperCase() + word?.slice?.(1)?.toLowerCase();
};

export const getTimeFromDate = (dateVal) => {
  const date = new Date(dateVal);

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  // const seconds = date.getUTCSeconds();
  const time = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
  return time;
};

export const getFormattedDate = (dateVal) => {
  const date = new Date(dateVal);

  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
  const day = date.getUTCDate().toString().padStart(2, "0");
  const year = date.getUTCFullYear();

  // Format the date as MM/DD/YYYY
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const getDateFromString = (dateString, format = "Y-m-d") => {
  // Parse the input date
  const parsedDate = new Date(dateString);

  // Format the date in the desired output format
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(parsedDate.getDate()).padStart(2, "0");

  const outputDate = format === "Y-m-d" ? `${year}-${month}-${day}` : `${day}/${month}/${year}`;
  // const outputDate = `${day}/${month}/${year}`;
  return outputDate;
};

export const checkDateBetween = (date, startDate, endDate) => {
  const dateToCheck = new Date(date);
  const startDateVal = new Date(startDate);
  const endDateVal = new Date(endDate);

  // Check if the date is between the start and end dates
  return dateToCheck >= startDateVal && dateToCheck <= endDateVal;
};

export const convertDateObjectToTimestamp = (dateObj) => {
  const formattedDate = format(dateObj, "yyyy-MM-dd'T'HH:mm:ss");
  return formattedDate;
};

export const getInitials = (name) => {
  if (name) {
    const initials = name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
    return initials;
  }
  return "N/A";
};