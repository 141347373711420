import React, { useState, useEffect } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import SidebarComponent from "./Sidebar/SideBar";
import { DashboardNavigationMap, DashboardmenuItems } from "constants/SidebarConfig";
import { useLocation, useNavigate } from "react-router-dom";
import useMediaQuerry from "../hooks/useMediaQuerry";
import "./MainLayout.css";
import { useKeycloak } from "@react-keycloak/web";

function MainLayout() {
  const { keycloak } = useKeycloak();
  const { authenticated } = keycloak;
  const navigate = useNavigate();
  const { isMobile, isLandScape } = useMediaQuerry();
  const [selectedItem, setSelectedItem] = useState("agent");

  const handleClick = (icon) => {
    setSelectedItem(icon);

    if (DashboardNavigationMap[icon]) {
      navigate(DashboardNavigationMap[icon]);
    }
  };

  useEffect(() => {
    handleClick(selectedItem);
  }, []);

  const menuItemsConfig = DashboardmenuItems.map((item) => ({
    ...item,
    icon: item.icon(selectedItem),
    command: () => {
      const lowerCaseLabel = item.label.toLowerCase();
      setSelectedItem(lowerCaseLabel);
      handleClick(lowerCaseLabel);
    },
  }));

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {authenticated ? (
        <div className="layout main">
          <div className="grid grid-nogutter contentAreaLayout">
            <div className="col-fixed max-w-5rem" style={{ width: "5rem" }}>
              <SidebarComponent
                menuItems={menuItemsConfig}
                selectedItem={selectedItem}
                view="dashboard"
              />
            </div>
            <div
              className={`main-content ${isMobile || isLandScape ? "" : "overflow-hidden"} col flex flex-column`}
            >
              <Header menuItems={menuItemsConfig} selectedItem={selectedItem} view="dashboard" />
              <div className="contentArea flex-grow-1">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-content-center h-screen align-items-center">
          <ProgressSpinner />
        </div>
      )}
    </div>
  );
}

export default MainLayout;
