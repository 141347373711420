import React from "react";

const UserManagementLogo = ({ selectedItem }) => {
  return selectedItem === "usermgt." ? (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7.75C11.3572 7.75 10.7289 7.55939 10.1944 7.20228C9.65994 6.84516 9.24338 6.33758 8.99739 5.74372C8.75141 5.14986 8.68705 4.4964 8.81245 3.86596C8.93785 3.23552 9.24738 2.65643 9.7019 2.2019C10.1564 1.74738 10.7355 1.43785 11.366 1.31245C11.9964 1.18705 12.6499 1.25141 13.2437 1.49739C13.8376 1.74338 14.3452 2.15994 14.7023 2.6944C15.0594 3.22886 15.25 3.85721 15.25 4.5C15.25 5.36196 14.9076 6.18861 14.2981 6.7981C13.6886 7.40759 12.862 7.75 12 7.75ZM12 1.75C11.4561 1.75 10.9244 1.91129 10.4722 2.21346C10.0199 2.51563 9.66747 2.94513 9.45933 3.44762C9.25119 3.95012 9.19673 4.50305 9.30284 5.0365C9.40895 5.56995 9.67086 6.05995 10.0555 6.44454C10.4401 6.82914 10.9301 7.09105 11.4635 7.19716C11.997 7.30327 12.5499 7.24881 13.0524 7.04067C13.5549 6.83253 13.9844 6.48006 14.2865 6.02782C14.5887 5.57558 14.75 5.0439 14.75 4.5C14.75 3.77066 14.4603 3.07118 13.9445 2.55546C13.4288 2.03973 12.7293 1.75 12 1.75Z"
        fill="#1581ED"
        stroke="#1581ED"
      />
      <path
        d="M19.1739 14.6739C19.1275 14.7203 19.0654 14.7474 19 14.7499C18.9346 14.7474 18.8725 14.7203 18.8261 14.6739C18.7787 14.6265 18.7515 14.5627 18.75 14.4958C18.7496 13.9676 18.678 13.4444 18.4583 12.9622C18.2344 12.4709 17.8705 12.0523 17.3355 11.7193C16.2952 11.0718 14.6101 10.75 12 10.75C9.38987 10.75 7.70478 11.0718 6.66453 11.7193C6.12953 12.0523 5.7656 12.4709 5.54172 12.9622C5.32145 13.4457 5.25 13.9703 5.25 14.5C5.25 14.5663 5.22366 14.6299 5.17678 14.6768C5.12989 14.7237 5.0663 14.75 5 14.75C4.9337 14.75 4.87011 14.7237 4.82322 14.6768C4.77634 14.6299 4.75 14.5663 4.75 14.5C4.75 13.4149 5.05727 12.6394 5.52201 12.0701C5.99292 11.4933 6.65633 11.091 7.42452 10.8141C8.97697 10.2546 10.827 10.25 12 10.25C13.173 10.25 15.023 10.2546 16.5755 10.8141C17.3437 11.091 18.0071 11.4933 18.478 12.0701C18.9421 12.6387 19.2492 13.413 19.25 14.4959C19.2485 14.5628 19.2213 14.6266 19.1739 14.6739Z"
        fill="#1581ED"
        stroke="#1581ED"
      />
      <path
        d="M6.53176 3.55757L6.54506 3.55886L6.5584 3.55943C6.59241 3.56089 6.62576 3.56928 6.65641 3.58409C6.68706 3.59889 6.71436 3.6198 6.73664 3.64553C6.75893 3.67126 6.77572 3.70127 6.786 3.73372C6.79628 3.76617 6.79982 3.80037 6.79641 3.83424C6.793 3.86811 6.78271 3.90092 6.76617 3.93067C6.74964 3.96042 6.7272 3.98648 6.70023 4.00725C6.67326 4.02802 6.64234 4.04307 6.60936 4.05147C6.57637 4.05987 6.54202 4.06144 6.5084 4.0561L6.494 4.05381L6.47948 4.05236C6.21742 4.02623 5.95278 4.05318 5.70137 4.1316C5.45197 4.20939 5.22067 4.33625 5.02103 4.5047C4.8197 4.66817 4.65261 4.86979 4.52937 5.09802C4.40539 5.32762 4.32825 5.57957 4.30245 5.83921C4.2751 6.10128 4.30007 6.36617 4.37591 6.61852C4.45192 6.87139 4.57748 7.10661 4.74529 7.31046C4.9131 7.51432 5.1198 7.68275 5.35335 7.80592C5.5869 7.9291 5.84263 8.00457 6.10563 8.02793L6.10726 8.02807C6.53918 8.06502 6.97159 7.96273 7.34118 7.73618L7.34248 7.73538C7.39937 7.70026 7.46788 7.68919 7.53294 7.70459C7.598 7.71999 7.65427 7.7606 7.68939 7.8175C7.7245 7.87439 7.73557 7.9429 7.72017 8.00796C7.70477 8.07302 7.66416 8.12929 7.60727 8.16441L7.60724 8.16436L7.601 8.16834C7.21591 8.41395 6.77093 8.54935 6.31437 8.55989H6.02508C5.37028 8.49054 4.76845 8.16697 4.34942 7.65826C3.92551 7.14362 3.7234 6.48166 3.78755 5.818C3.85171 5.15434 4.17687 4.54335 4.69151 4.11944C5.20615 3.69553 5.86811 3.49342 6.53176 3.55757Z"
        fill="#1581ED"
        stroke="#1581ED"
      />
      <path
        d="M1.17391 13.9239C1.12753 13.9703 1.06539 13.9974 1 13.9999C0.934611 13.9974 0.872471 13.9703 0.826087 13.9239C0.778735 13.8766 0.751502 13.8128 0.750001 13.7459C0.750377 12.417 0.934195 11.4432 1.4438 10.7972C1.93154 10.1789 2.81225 9.75 4.5 9.75C4.5663 9.75 4.62989 9.77634 4.67678 9.82322C4.72366 9.87011 4.75 9.9337 4.75 10C4.75 10.0663 4.72366 10.1299 4.67678 10.1768C4.62989 10.2237 4.5663 10.25 4.5 10.25C3.31164 10.25 2.41111 10.4296 1.8609 11.0847C1.59069 11.4063 1.44202 11.8009 1.35818 12.2378C1.27509 12.6707 1.25012 13.1759 1.25 13.7459C1.24851 13.8128 1.22128 13.8765 1.17391 13.9239Z"
        fill="#1581ED"
        stroke="#1581ED"
      />
    </svg>
  ) : (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 7.75C11.3572 7.75 10.7289 7.55939 10.1944 7.20228C9.65994 6.84516 9.24338 6.33758 8.99739 5.74372C8.75141 5.14986 8.68705 4.4964 8.81245 3.86596C8.93785 3.23552 9.24738 2.65643 9.7019 2.2019C10.1564 1.74738 10.7355 1.43785 11.366 1.31245C11.9964 1.18705 12.6499 1.25141 13.2437 1.49739C13.8376 1.74338 14.3452 2.15994 14.7023 2.6944C15.0594 3.22886 15.25 3.85721 15.25 4.5C15.25 5.36196 14.9076 6.18861 14.2981 6.7981C13.6886 7.40759 12.862 7.75 12 7.75ZM12 1.75C11.4561 1.75 10.9244 1.91129 10.4722 2.21346C10.0199 2.51563 9.66747 2.94513 9.45933 3.44762C9.25119 3.95012 9.19673 4.50305 9.30284 5.0365C9.40895 5.56995 9.67086 6.05995 10.0555 6.44454C10.4401 6.82914 10.9301 7.09105 11.4635 7.19716C11.997 7.30327 12.5499 7.24881 13.0524 7.04067C13.5549 6.83253 13.9844 6.48006 14.2865 6.02782C14.5887 5.57558 14.75 5.0439 14.75 4.5C14.75 3.77066 14.4603 3.07118 13.9445 2.55546C13.4288 2.03973 12.7293 1.75 12 1.75Z"
        fill="#DADAFC"
        stroke="#DADAFC"
      />
      <path
        d="M19.1739 14.6739C19.1275 14.7203 19.0654 14.7474 19 14.7499C18.9346 14.7474 18.8725 14.7203 18.8261 14.6739C18.7787 14.6265 18.7515 14.5627 18.75 14.4958C18.7496 13.9676 18.678 13.4444 18.4583 12.9622C18.2344 12.4709 17.8705 12.0523 17.3355 11.7193C16.2952 11.0718 14.6101 10.75 12 10.75C9.38987 10.75 7.70478 11.0718 6.66453 11.7193C6.12953 12.0523 5.7656 12.4709 5.54172 12.9622C5.32145 13.4457 5.25 13.9703 5.25 14.5C5.25 14.5663 5.22366 14.6299 5.17678 14.6768C5.12989 14.7237 5.0663 14.75 5 14.75C4.9337 14.75 4.87011 14.7237 4.82322 14.6768C4.77634 14.6299 4.75 14.5663 4.75 14.5C4.75 13.4149 5.05727 12.6394 5.52201 12.0701C5.99292 11.4933 6.65633 11.091 7.42452 10.8141C8.97697 10.2546 10.827 10.25 12 10.25C13.173 10.25 15.023 10.2546 16.5755 10.8141C17.3437 11.091 18.0071 11.4933 18.478 12.0701C18.9421 12.6387 19.2492 13.413 19.25 14.4959C19.2485 14.5628 19.2213 14.6266 19.1739 14.6739Z"
        fill="#DADAFC"
        stroke="#DADAFC"
      />
      <path
        d="M6.53176 3.55757L6.54506 3.55886L6.5584 3.55943C6.59241 3.56089 6.62576 3.56928 6.65641 3.58409C6.68706 3.59889 6.71436 3.6198 6.73664 3.64553C6.75893 3.67126 6.77572 3.70127 6.786 3.73372C6.79628 3.76617 6.79982 3.80037 6.79641 3.83424C6.793 3.86811 6.78271 3.90092 6.76617 3.93067C6.74964 3.96042 6.7272 3.98648 6.70023 4.00725C6.67326 4.02802 6.64234 4.04307 6.60936 4.05147C6.57637 4.05987 6.54202 4.06144 6.5084 4.0561L6.494 4.05381L6.47948 4.05236C6.21742 4.02623 5.95278 4.05318 5.70137 4.1316C5.45197 4.20939 5.22067 4.33625 5.02103 4.5047C4.8197 4.66817 4.65261 4.86979 4.52937 5.09802C4.40539 5.32762 4.32825 5.57957 4.30245 5.83921C4.2751 6.10128 4.30007 6.36617 4.37591 6.61852C4.45192 6.87139 4.57748 7.10661 4.74529 7.31046C4.9131 7.51432 5.1198 7.68275 5.35335 7.80592C5.5869 7.9291 5.84263 8.00457 6.10563 8.02793L6.10726 8.02807C6.53918 8.06502 6.97159 7.96273 7.34118 7.73618L7.34248 7.73538C7.39937 7.70026 7.46788 7.68919 7.53294 7.70459C7.598 7.71999 7.65427 7.7606 7.68939 7.8175C7.7245 7.87439 7.73557 7.9429 7.72017 8.00796C7.70477 8.07302 7.66416 8.12929 7.60727 8.16441L7.60724 8.16436L7.601 8.16834C7.21591 8.41395 6.77093 8.54935 6.31437 8.55989H6.02508C5.37028 8.49054 4.76845 8.16697 4.34942 7.65826C3.92551 7.14362 3.7234 6.48166 3.78755 5.818C3.85171 5.15434 4.17687 4.54335 4.69151 4.11944C5.20615 3.69553 5.86811 3.49342 6.53176 3.55757Z"
        fill="#DADAFC"
        stroke="#DADAFC"
      />
      <path
        d="M1.17391 13.9239C1.12753 13.9703 1.06539 13.9974 1 13.9999C0.934611 13.9974 0.872471 13.9703 0.826087 13.9239C0.778735 13.8766 0.751502 13.8128 0.750001 13.7459C0.750377 12.417 0.934195 11.4432 1.4438 10.7972C1.93154 10.1789 2.81225 9.75 4.5 9.75C4.5663 9.75 4.62989 9.77634 4.67678 9.82322C4.72366 9.87011 4.75 9.9337 4.75 10C4.75 10.0663 4.72366 10.1299 4.67678 10.1768C4.62989 10.2237 4.5663 10.25 4.5 10.25C3.31164 10.25 2.41111 10.4296 1.8609 11.0847C1.59069 11.4063 1.44202 11.8009 1.35818 12.2378C1.27509 12.6707 1.25012 13.1759 1.25 13.7459C1.24851 13.8128 1.22128 13.8765 1.17391 13.9239Z"
        fill="#DADAFC"
        stroke="#DADAFC"
      />
    </svg>
  );
};

export default UserManagementLogo;
