import { Divider } from "primereact/divider";
import { Sidebar } from "primereact/sidebar";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ManageAllocation from "./ManageAllocation";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import GetUsersList from "hooks/getUsers";
import useUser from "hooks/useUser";
import { ProgressSpinner } from "primereact/progressspinner";
import { useKeycloak } from "@react-keycloak/web";

const ManageAllocationSidebar = ({
  userId = "",
  isVisible = false,
  onHide = () => {},
  toast = null,
}) => {
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [isEditRecurring, setIsEditRecurring] = React.useState(false);
  const [fetchedAllocationType, setFetchedAllocationType] = useState("recurring");
  const { allocateCredits, deleteRecurringAllocation } = useUser();
  const [allocationType, setAllocationType] = React.useState({
    allocation_type: "one_time",
    credits_to_be_allotted: 100,
    is_daily_limit_enabled: false,
    daily_limit: "",
    frequency: "",
    frequency_unit: "",
  });
  const { keycloak } = useKeycloak();
  const adminId = keycloak.tokenParsed?.user_id;

  const { getUserDetails } = GetUsersList();
  const [admin, setAdmin] = useState(null);
  const [user, setUser] = useState(null);

  const fetchUser = () => {
    setLoading(true);
    getUserDetails(adminId)
      .then((data) => {
        setAdmin(data?.results);
      })
      .catch((err) => {
        console.log("detail", err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error fetching credit pool",
          life: 3000,
        });
      });
    getUserDetails(userId)
      .then((data) => {
        setFetchedAllocationType(data?.results?.allocation_type);
        setAllocationType({
          allocation_type: data?.results?.allocation_type,
          credits_to_be_allotted:
            data?.results?.allocation_type === "recurring"
              ? data?.results?.last_credits_allotted
              : 100,
          is_daily_limit_enabled: !!data?.results?.daily_limit,
          daily_limit: data?.results?.daily_limit,
          frequency: data?.results?.frequency,
          frequency_unit: data?.results?.frequency_unit,
        });
        setUser(data?.results);
      })
      .catch((err) => {
        console.log("detail", err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: err?.response?.data?.message,
          life: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let allocationData = {
        ...allocationType,
        credits_to_be_allotted: Number(allocationType.credits_to_be_allotted),
        frequency: Number(allocationType.frequency) || null,
        frequency_unit: allocationType.frequency_unit || null,
        daily_limit: Number(allocationType.daily_limit),
      };

      if (allocationType.allocation_type === "unlimited") {
        allocationData = {
          allocation_type: "unlimited",
        };
      }

      const res = await allocateCredits(userId, allocationData);

      if (res) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Credit limit updated successfully.",
          life: 3000,
        });
        onHide(true);
        setIsEditRecurring(false);
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err?.response?.data?.message,
        life: 3000,
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  const onDeleteRecurring = () => {
    confirmDialog({
      pt: {
        content: {
          className: "align-items-start",
        },
        header: {
          className: "text-base font-semibold",
        },
      },
      message: (
        <div>
          <p className="text-sm m-0 font-light">
            Are you sure you want to delete Allocation Type for the user?
          </p>
          <p
            style={{
              background: "rgba(255, 241, 218, 1)",
            }}
            className="text-sm p-3 mt-3 border-round-md"
          >
            Their Allocation Type will be changed to{" "}
            <span className="font-semibold">One-Time.</span>
          </p>
        </div>
      ),

      header: "Delete Allocation Type",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        // deleteAllocation();
        setLoading(true);
        try {
          const res = await deleteRecurringAllocation(userId);

          toast.current.show({
            severity: "success",
            summary: "Allocation Type Deleted",
            detail: "Allocation Type has been deleted successfully",
            life: 3000,
          });
          if (res) {
            onHide(true);
          }
        } catch (err) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: err?.response?.data?.message,
            life: 3000,
          });
        }
      },
      reject: () => {
        // reject action
      },
      acceptIcon: "pi pi-check",
      acceptLabel: "Delete",
      rejectIcon: "pi pi-times",
      rejectLabel: "Cancel",
      acceptClassName: "p-button-danger text-sm",
      rejectClassName: "p-button-outlined p-button-secondary text-sm mr-4",
    });
  };

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      className="bg-white w-26rem"
      pt={{
        header: {
          className: "pb-0",
        },
        content: {
          className: "p-0",
        },
      }}
      header={<h2 className="text-2xl font-semibold m-0">Manage Credits</h2>}
      onHide={() => onHide()}
    >
      <ConfirmDialog />
      {loading && (
        <div className="w-26rem absolute top-0 right-0 h-screen bg-white opacity-50 z-5">
          <div className="flex justify-content-center align-items-center h-full">
            <ProgressSpinner />
          </div>
        </div>
      )}
      <Divider className="mb-1" />
      <div className="flex flex-column">
        <div
          className="p-4"
          style={{
            height: "calc(100vh - 11rem)",
            overflowY: "auto",
          }}
        >
          <div className="mt-1 ml-1 surface-50 border-round-md w-11 h-6rem pl-3 pt-2">
            <p className="text-sm m-0 font-light mb-3">{"You're managing credits for: "}</p>
            <p className="font-semibold text-base m-0 ">{user?.email}</p>
            <p className="mt-1 text-sm font-light">Their credit balance: {user?.credit_balance}</p>
          </div>
          <Divider className="mt-3" />

          {fetchedAllocationType !== "recurring" ||
          (fetchedAllocationType === "recurring" && isEditRecurring) ? (
            <div className="mt-2">
              <ManageAllocation
                allocationType={allocationType}
                setAllocationType={setAllocationType}
                errors={errors}
                setErrors={setErrors}
                isEditRecurring={isEditRecurring}
                email={user?.email}
                creditPool={admin?.credit_balance}
              />
            </div>
          ) : (
            <div className="mt-4">
              <div className="flex justify-content-between gap-3">
                <p className="text-sm m-0 font-light">Allocation Type:</p>
                <div className="flex gap-3">
                  <span
                    className="pi pi-pencil text-500 cursor-pointer text-lg text-blue-400"
                    onClick={() => setIsEditRecurring(true)}
                  />
                  <span
                    className="pi pi-trash text-500 text-red-500 cursor-pointer text-lg"
                    onClick={onDeleteRecurring}
                  />
                </div>
              </div>
              <p className="text-sm m-0 mt-2 font-semibold">
                Recurring Credits : {user?.last_credits_allotted} (Every {user?.frequency}{" "}
                {user?.frequency_unit})
              </p>
            </div>
          )}
        </div>

        {fetchedAllocationType !== "recurring" ||
        (fetchedAllocationType === "recurring" && isEditRecurring) ? (
          <div className="w-full">
            <Divider />
            <div className="flex justify-content-end gap-3 pr-3">
              <Button
                label="Cancel"
                text
                size="small"
                className="w-20 text-500"
                onClick={() => {
                  if (isEditRecurring) {
                    setIsEditRecurring(false);
                  } else {
                    onHide();
                  }
                }}
              />
              <Button
                label="Confirm"
                size="small"
                className="w-20"
                onClick={handleSubmit}
                loading={submitLoading}
                disabled={Object.keys(errors).length > 0 || loading}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Sidebar>
  );
};

export default ManageAllocationSidebar;
