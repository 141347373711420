import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import calendaricon from "../../../assets/TemplateIcons/calendarIcon.png";
import { Image } from "primereact/image";

const UploadSuccessDialog = ({ visible, onHide }) => {
  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      modal
      header={null}
      footer={null}
      className="p-fluid"
      style={{ width: "500px" }}
    >
      <div className="flex flex-column align-items-center text-center">
        <Image src={calendaricon} className={`gap-3 text-xs  w-2 h-2`} />
        <p className="text-gray-600 mb-4 text-xs">
          Your template has been successfully uploaded. It is currently being processed and may take
          <b> up to 7 days </b> to complete. We’ll keep you updated. Once ready, you’ll be able to
          use it for any task.
        </p>
        <Button label="Okay" icon="pi pi-check" className="p-button-primary w-3" onClick={onHide} />
      </div>
    </Dialog>
  );
};

export default UploadSuccessDialog;
