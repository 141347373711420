import axios from "axios";

export async function getFilesFromCarbon(carbonAccessToken, jobUUID) {
  return await axios.post(
    `https://api.carbon.ai/user_files_v2`,
    {
      filters: {
        tags: {
          tag1: jobUUID,
        },
      },
      include_raw_file: true,
      include_parsed_text_file: true,
      include_additional_files: true,
    },
    {
      headers: {
        Authorization: `Token ${carbonAccessToken}`,
        Organization: null,
      },
    },
  );
}

export async function getCarbonOauthUrl(carbonAccessToken, jobUUID, type) {
  return await axios.post(
    "https://api.carbon.ai/integrations/oauth_url",
    {
      tags: {
        tag1: jobUUID,
      },
      service: type === "googleDrive" ? "GOOGLE_DRIVE" : "ONEDRIVE",
      skip_embedding_generation: true,
      sync_files_on_connection: false,
      connecting_new_account: true,
      enable_file_picker: true,
      request_id: jobUUID,
      scope: jobUUID,
    },
    {
      headers: {
        Authorization: `Token ${carbonAccessToken}`,
        Organization: null,
      },
    },
  );
}
