import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Router from "./router/";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.css"; // NOTE core css
import "primeicons/primeicons.css"; // NOTE icons
import "primeflex/primeflex.css"; // NOTE css utility
import AppContextProvider from "context/ContextWrapper";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppContextProvider>
    <Router />
  </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
