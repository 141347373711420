import useApi from "services/api";
import { API_URL } from "../constants";

const Authentication = () => {
  const { get, patch, data, loading, error } = useApi();
  const emailVerification = async (email) => {
    const encodedEmail = encodeURIComponent(email);
    const data = await get(`${API_URL}/intelliapp/users/check-user-exists?email=${encodedEmail}`);
    return data;
  };

  const updateUserStatus = async (body) => {
    const data = await patch(`${API_URL}/intelliapp/user/update-keycloak-status`, body);
    return data;
  };

  return { emailVerification, updateUserStatus, data, loading, error };
};

export default Authentication;
