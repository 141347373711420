import useApi from "services/api";
import { API_URL } from "../constants";

const useTask = () => {
  const { get, post } = useApi();

  const getTask = async (id) => {
    const data = await get(`${API_URL}/summary_job/` + id);
    return data;
  };

  const getOutputTypes = async () => {
    const data = await get(`${API_URL}/output_formats`);
    return data;
  };

  const provideFeedback = async (feedback) => {
    const data = await post(
      `${API_URL}/summary_job/feedback?job_id=${feedback.job_id}&feedback=${feedback.feedback}`,
    );
    return data;
  };

  const getAuditLog = async (job_id) => {
    const data = await get(`${API_URL}/summary_job/audit_logs/${job_id}`);
    return data;
  };

  const provideError = async (body) => {
    const data = await post(`${API_URL}/summary_job/error`, body);
    return data;
  };

  return { getTask, getOutputTypes, provideFeedback, provideError, getAuditLog };
};

export default useTask;
