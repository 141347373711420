import React from "react";
import CoinIcon from "../../../assets/CreditOverview/CreditsIcon.svg";
import { SummaryCard } from "pages/Dashboard/CreditSummary/CreditSummary";
import DownArrow from "../../../assets/CreditOverview/ArrowDown.svg";
import OrgTransactionsChart from "pages/Dashboard/CreditSummary/OrgTranscationsChart";
import { Divider } from "primereact/divider";

const CreditInfoTab = () => {
  return (
    <div>
      <div className="flex w-full h-full gap-2">
        <div className="grid">
          <div className="flex justify-content-between gap-2">
            <SummaryCard
              title={"Organization Credits Balance"}
              value={0}
              icon={CoinIcon}
              iconSize={32}
              // loading={loadingCred}
            />
            <SummaryCard
              title={"Today's Usage"}
              value={0}
              icon={DownArrow}
              iconSize={28}
              // loading={loadingCred}
            />
            <SummaryCard
              title={"Last 7 days Usage"}
              value={0}
              icon={DownArrow}
              iconSize={28}
              // loading={loadingCred}
            />
          </div>
          <Divider />
          <div>
            <p>Allocation type</p>
          </div>
        </div>
        <div>
          <Divider layout="vertical" className="flex items-stretch" />
        </div>
        <div className="flex-1">
          <OrgTransactionsChart />
        </div>
      </div>
    </div>
  );
};

export default CreditInfoTab;
