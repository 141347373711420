import SourceInfoHeader from "components/Spaces/SourceInfoHeader";
import CustomerInsightsList from "components/Spaces/CustomerInsights";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useRef, useState, Fragment } from "react";
import useMediaQuery from "hooks/useMediaQuerry";
import { TabView, TabPanel } from "primereact/tabview";
import { useParams } from "react-router-dom";
import "./CustomerInsights.css";
import DataContent from "components/Spaces/DataContent";
import Facts from "components/Spaces/Facts";

const CustomerInsights = () => {
  const [visible, setVisible] = useState(false);
  const { spaceId } = useParams();
  const [fetchData, setFetchData] = useState(false);
  const { isMobile } = useMediaQuery();
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef(null);
  const [sourceData, setSourceData] = useState([]);

  const renderSourceInfo = () => (
    <Fragment>
      <CustomerInsightsList
        spaceId={spaceId}
        sourceData={sourceData}
        setSourceData={setSourceData}
      />
    </Fragment>
  );

  const renderDataContent = () => (
    <Fragment>
      <DataContent />
    </Fragment>
  );

  const renderFacts = () => (
    <Fragment>
      <Facts />
    </Fragment>
  );

  return (
    <div className="surface-0 h-full spaces-container">
      <div
        className={`flex ${!isMobile && "align-items-center"} flex-column lg:justify-content-between lg:flex-row`}
        style={{ marginLeft: "1vw" }}
      >
        <Toast ref={toast} />
        {/* <div className="templatesHeader" style={{ width: "70vw" }}>
          <p className="font-semibold" style={{ fontSize: "1.313rem" }}>
            Customer_Insights
          </p>
          <p className="font-thin" style={{ fontWeight: 300, lineHeight: "4vh" }}>
            Explore the vast universe of customer insights
          </p>
        </div> */}
        {/* <div className="mt-3 lg:mt-0 chat-now-btn"> */}
        {/* <Button
            label="Chat Now"
            icon={<ChatNow />}
            className="text-sm"
            onClick={() => setVisible(true)}
          /> */}

        {/* <span className="pi pi-ellipsis-v text-500 cursor-pointer text-lg ml-5" />
        </div> */}
      </div>
      {/* <span style={{ color: "#6C757D", marginLeft: "1vw" }}>Created at: 4/03/2023</span> */}

      <SourceInfoHeader spaceId={spaceId} isMobile={isMobile} />
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
        style={{ marginTop: "1vh", height: "calc(100% - 12rem)" }}
      >
        <TabPanel header="Source Info">{renderSourceInfo()}</TabPanel>
        <TabPanel className="data-content-panel" header="Data">
          {renderDataContent()}
        </TabPanel>
        <TabPanel className="h-full" header="Facts">
          {renderFacts()}
        </TabPanel>
        <TabPanel header="Action Log">
          <p>Action Log tab content goes here...</p>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default CustomerInsights;
