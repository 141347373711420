import React from "react";
import { Button } from "primereact/button";
import "./Pagination.css"; // You'll need to create this CSS file
import useMediaQuerry from "hooks/useMediaQuerry";

const Pagination = ({ currentPage, totalRecords, onPageChange }) => {
  const recordsPerPage = 10;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const { isMobile } = useMediaQuerry();

  return (
    <div
      className={`${isMobile ? "pagination-container-mobile w-screen" : "pagination-container-desktop w-[30px]"} absolute align-items-center justify-content-center flex`}
    >
      <Button
        icon="pi pi-angle-double-left"
        className="p-button-text"
        disabled={currentPage === 1}
        onClick={() => onPageChange(1)}
      />
      <Button
        icon="pi pi-angle-left"
        className="p-button-text"
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      />
      <span className="page-info">
        ({currentPage} of {totalPages})
      </span>
      <Button
        icon="pi pi-angle-right"
        className="p-button-text"
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      />
      <Button
        icon="pi pi-angle-double-right"
        className="p-button-text"
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(totalPages)}
      />
    </div>
  );
};

export default Pagination;
