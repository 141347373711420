import { Chart } from "primereact/chart";
import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useState, useRef } from "react";
import useCreditSummary from "hooks/useCreditSummary";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { getDateFromString, checkDateBetween } from "../../../utils/utils";
import { differenceInDays, eachMonthOfInterval, format } from "date-fns";
import { ProgressSpinner } from "primereact/progressspinner";
import "./OrgTransactionChart.css";

function OrgTransactionsChart() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [selectedRange, setSelectedRange] = useState("7D");
  const [weekData, setWeekData] = useState({});
  const [customData, setCustomData] = useState({});
  const [oneDayData, setOneDayData] = useState({});
  const [isNoData, setIsNoData] = useState({});
  const [dateRange, setDateRange] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [loadingGraph, setLoadingGraph] = useState(false);
  const { getCreditGraph, loading } = useCreditSummary();
  const toast = useRef(null);
  const today = new Date();
  const sevenDaysAgo = new Date(today);
  const calendarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dayDiff = dateRange && differenceInDays(dateRange[1], dateRange[0]);
  let labels =
    selectedRange === "7D"
      ? Array.from({ length: 7 }, (_, i) => {
          const date = new Date(today);
          date.setDate(date.getDate() - i);
          return date.toLocaleDateString("en-US", { month: "short", day: "2-digit" });
        }).reverse()
      : [4, 8, 12, 16, 20, 24];

  const generateCustomDateLabels = (startDate, endDate) => {
    let interval;
    let dateFormat;

    interval = eachMonthOfInterval({ start: startDate, end: endDate });
    dateFormat = "MMM yyyy";

    return interval.map((date) => format(date, dateFormat));
  };

  useEffect(() => {
    setLoadingGraph(loading);
  }, [loading]);

  useEffect(() => {
    if (selectedRange === "custom" && dateRange && dateRange[0] && dateRange[1]) {
      fetchCreditGraph();
      toggleCalendar();
    }
  }, [dateRange]);

  const toggleCalendar = () => {
    setShowDatePicker(!showDatePicker);
  };

  const fetchCreditGraph = async () => {
    try {
      sevenDaysAgo.setDate(today.getDate() - 6);

      const formatDate = (date) => format(date, "yyyy-MM-dd"); //date.toISOString().split("T")[0];

      if (selectedRange === "7D") {
        const weekDataVal = await getCreditGraph({
          date_gte: formatDate(sevenDaysAgo),
          date_lte: formatDate(today),
        });
        setWeekData(weekDataVal);
      } else if (selectedRange === "custom" && dateRange?.length > 1) {
        const customData = await getCreditGraph({
          date_gte: formatDate(dateRange[0]),
          date_lte: formatDate(dateRange[1]),
        });
        setCustomData(customData);
      } else {
        const oneDayDataVal = await getCreditGraph({
          date_gte: formatDate(today),
          date_lte: formatDate(today),
        });
        setOneDayData(oneDayDataVal);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please try again later",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    if (selectedRange === "custom") {
      setShowDatePicker(true);
    } else {
      setDateRange(null);
      setShowDatePicker(false);
      fetchCreditGraph();
    }
  }, [selectedRange]);

  useEffect(() => {
    if (weekData?.results?.data) {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--text-color");
      const textColorSecondary = documentStyle.getPropertyValue("--text-color-secondary");
      const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

      const labelHours = [0, 4, 8, 12, 16, 20].map(
        (hour) => `${hour.toString().padStart(2, "0")}:00`,
      );

      let sortedData = null;
      let creditDataVal = null;
      if (dayDiff < 31 && selectedRange === "custom") {
        sortedData = customData?.results?.data.sort((a, b) => {
          return new Date(a.created_at) - new Date(b.created_at);
        });
        creditDataVal = sortedData?.map((item) => item.credits);
        sortedData = sortedData?.map((item) => getDateFromString(item.created_at, "d-m-y"));
      } else if (selectedRange === "custom") {
        labels = generateCustomDateLabels(dateRange[0], dateRange[1]);
      }

      const creditData =
        selectedRange === "7D"
          ? labels.map((label) => {
              const matchingData = weekData?.results?.data?.find((item) => {
                const itemDate = new Date(item.created_at);

                return (
                  itemDate.toLocaleDateString("en-US", { month: "short", day: "2-digit" }) === label
                );
              });
              return matchingData ? matchingData.credits : 0;
            })
          : selectedRange === "custom" && dateRange.length > 1 && dayDiff > 31
            ? labels?.map((dateLabel, index) => {
                const currentDate = getDateFromString(dateLabel);
                const nextDate =
                  index < labels.length - 1
                    ? getDateFromString(labels[index + 1])
                    : dateRange && getDateFromString(dateRange[1]);
                const matchingData = customData?.results?.data.filter((item) => {
                  const itemDate = item?.created_at.split("T")[0];

                  return checkDateBetween(itemDate, currentDate, nextDate);
                });
                return matchingData?.reduce((sum, item) => sum + item.credits, 0);
              })
            : selectedRange === "1D"
              ? labelHours.map((label, index) => {
                  const startHour = parseInt(label.split(":")[0]);
                  const endHour =
                    index === labels.length - 1
                      ? 24
                      : parseInt(labelHours[index + 1].split(":")[0]);

                  const matchingData = oneDayData?.results?.data?.filter((item) => {
                    const itemDate = new Date(item.created_at);
                    const itemHour = itemDate.getHours();
                    return itemHour >= startHour && itemHour < endHour;
                  });

                  return matchingData?.reduce((sum, item) => sum + item.credits, 0);
                })
              : [0];

      const data = {
        labels: selectedRange === "custom" && dayDiff < 31 ? sortedData : labels,
        datasets: [
          {
            label: "Credit Transactions",
            data: selectedRange === "custom" && dayDiff < 31 ? creditDataVal : creditData,
            fill: false,
            borderColor: documentStyle.getPropertyValue("--blue-500"),
            tension: 0,
            pointRadius: 4,
            pointBackgroundColor: "#fff",
            borderWidth: 1.5,
          },
        ],
      };

      setIsNoData(
        data.datasets[0].data.every((value) => value === 0) || data.datasets[0].data.length === 0,
      );

      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColor,
              font: {
                weight: 400,
              },
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            min: 0,
            ticks: {
              color: textColor,
              font: {
                weight: 400,
              },
              stepSize: 250,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
        // plugins: [noDataPlugin]
      };

      setChartData(data);
      setChartOptions(options);
    }
  }, [weekData?.results?.data, oneDayData?.results?.data, customData?.results?.data]);

  return (
    <div className="border-round-xs border-300 border-1 h-full p-3">
      <Toast ref={toast} />
      <div className="flex justify-content-between align-items-center">
        <h2 className="m-0 text-lg text-700 font-semibold">{"Org Credit Transactions"}</h2>
        <SelectButton
          pt={{
            button: {
              className: "text-500 font-semibold text-sm",
            },
          }}
          size={"small"}
          value={selectedRange}
          onChange={(e) => {
            if (e.value === "custom" || selectedRange === "custom") {
              setShowDatePicker(true);
            }
            if (e.value === null) {
              return;
            }

            setSelectedRange(e.value);
          }}
          options={[
            {
              label: "1D",
              value: "1D",
            },
            {
              label: "7D",
              value: "7D",
            },
            { icon: "pi pi-calendar", value: "custom" },
          ]}
          itemTemplate={(option) =>
            option?.icon ? <i className="pi pi-calendar" /> : <span>{option.label}</span>
          }
        />
      </div>
      {showDatePicker && (
        <div style={{ position: "relative", zIndex: 1000 }} ref={calendarRef}>
          <Calendar
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            readOnlyInput
            showIcon
            className="ml-2 credit"
            dateFormat="d/m/y"
            inline
          />
        </div>
      )}

      <div className="mt-3 pl-6 pr-3">
        {loadingGraph ? (
          <div className=" h-8 w-8 mt-8 ml-8 bg-white opacity-50 z-5">
            <div className="flex">
              <ProgressSpinner />
            </div>
          </div>
        ) : isNoData ? (
          <div className="text-center no-data-div">No Data Available</div>
        ) : (
          <Chart type="line" className="h-15rem" data={chartData} options={chartOptions} />
        )}
      </div>
    </div>
  );
}

export default OrgTransactionsChart;
