// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-round-top-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.border-round-top-left-12 {
  border-top-left-radius: 12px;
}

.border-round-top-right-12 {
  border-top-right-radius: 12px;
}

.task-container {
  border-bottom: 1px solid #e6e3e3 !important;
}

.card-box {
  z-index: 1;
}
.task-container:hover {
  background-color: #f4f5f6 !important;
}
.selectedTask {
  background-color: #eaf2fd !important;
  border-left: 1px solid #357ade !important;
}
.taskTabListing {
  .p-chip {
  }
  .p-chip-icon {
    font-size: 0.75rem;
  }
  .p-chip-text {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
}

.tasks-list {
  height: 80vh;
  overflow-y: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/components/DocumentEngine/WorkspaceSection/WorkspaceStyle.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,UAAU;AACZ;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;EACpC,yCAAyC;AAC3C;AACA;EACE;EACA;EACA;IACE,kBAAkB;EACpB;EACA;IACE,oBAAoB;IACpB,uBAAuB;EACzB;AACF;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".border-round-top-12 {\n  border-top-left-radius: 12px;\n  border-top-right-radius: 12px;\n}\n\n.border-round-top-left-12 {\n  border-top-left-radius: 12px;\n}\n\n.border-round-top-right-12 {\n  border-top-right-radius: 12px;\n}\n\n.task-container {\n  border-bottom: 1px solid #e6e3e3 !important;\n}\n\n.card-box {\n  z-index: 1;\n}\n.task-container:hover {\n  background-color: #f4f5f6 !important;\n}\n.selectedTask {\n  background-color: #eaf2fd !important;\n  border-left: 1px solid #357ade !important;\n}\n.taskTabListing {\n  .p-chip {\n  }\n  .p-chip-icon {\n    font-size: 0.75rem;\n  }\n  .p-chip-text {\n    margin-top: 0.125rem;\n    margin-bottom: 0.125rem;\n  }\n}\n\n.tasks-list {\n  height: 80vh;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
