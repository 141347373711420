import React, { useState, Fragment, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton"; // Import Skeleton component
import { Paginator } from "primereact/paginator";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { OverlayPanel } from "primereact/overlaypanel";
import { Calendar } from "primereact/calendar";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import "../DocumentEngine/TemplateSection/newTemplate.css";
import "./index.css";
import { Tag } from "primereact/tag";
import LocalDrive from "assets/SpaceIcons/LocalDrive.svg";
import FilterIcon from "assets/SpaceIcons/FilterIcon";
import ConnectionInfoPanel from "./ConnectionInfoPanel";
import { Dialog } from "primereact/dialog";
import { useNavigate, useParams } from "react-router-dom";
import useSpaces from "hooks/useSpace";
import { formatDateWithTime } from "utils/helper";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";

/**
 * Main component for displaying and managing customer data source connections.
 * Handles the display of source data in a table format with filtering, pagination, and actions.
 *
 * @returns {JSX.Element} A complete view of customer data sources with table and filters
 */
const CustomerInsights = ({ spaceId, sourceData, setSourceData }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const { getSourcesBySpaceId } = useSpaces();
  const [activeIndex, setActiveIndex] = useState(0);
  const toast = useRef(null);
  const [selectedSources, setSelectedSources] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedSpaces, setSelectedSpaces] = useState(null);
  const [visible, setVisible] = useState(false);

  const op = useRef(null);
  const menu = useRef(null);

  const navigate = useNavigate();

  const fetchSourceData = async (filterParams = {}) => {
    try {
      setLoading(true);
      const params = {
        page: page + 1,
        limit: limit,
        space_id: spaceId,
      };

      // Create filter object
      const filters = {};

      // Add date range filter
      if (filterParams.dateRange) {
        if (Array.isArray(filterParams.dateRange) && filterParams.dateRange[0]) {
          filters.createdAtStartDate = moment(filterParams.dateRange[0]).format("YYYY-MM-DD");
          if (filterParams.dateRange[1]) {
            filters.createdAtEndDate = moment(filterParams.dateRange[1]).format("YYYY-MM-DD");
          }
        }
      }

      // Add status filter
      if (filterParams.status) {
        filters.status = filterParams.status;
      }

      // Only add filter param if there are actual filters
      if (Object.keys(filters).length > 0) {
        params.filter = JSON.stringify(filters);
      }

      const response = await getSourcesBySpaceId(params);
      setSourceData(response);
      setTableData(response?.items || []);
    } catch (error) {
      // console.error("Error fetching source data:", error);
      if (toast?.current) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch source data",
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFilterApply = (filters) => {
    fetchSourceData(filters);
    op.current.hide();
  };

  const actionTemplate = (rowData) => {
    const items = [
      {
        label: <>Edit</>,
        disabled: true,
        command: () => {},
        icon: "pi pi-pen-to-square",
      },
      //divider
      {
        separator: true,
      },
      {
        label: "Delete",
        disabled: true,
        command: () => {},
        icon: "pi pi-trash",
        className: "p-delete-menuitem",
      },
    ];

    const moreMenu = (
      <div className="card flex justify-content-center">
        <TieredMenu
          model={items}
          popup
          ref={menu}
          breakpoint="767px"
          className="max-w-10rem text-sm"
        />
        <Button
          type="button"
          icon={"pi pi-ellipsis-v"}
          onClick={(e) => {
            menu.current.toggle(e);
            setTimeout(() => {
              menu?.current
                ?.getElement()
                ?.children?.[0]?.children?.[0]?.classList?.remove("p-focus");
            }, 100);
            setSelectedSpaces(rowData);
          }}
          className="p-button-rounded p-button-text p-button-secondary w-2rem h-2rem"
        />
      </div>
    );

    return <div className="text-right inline-flex">{moreMenu}</div>;
  };

  /**
   * Renders the status indicator icon based on the current status.
   *
   * @param {string} status - The current status ('Active', 'Error', or 'Syncing')
   * @returns {JSX.Element} An icon representing the status
   */
  const renderStatusIcon = (status) => {
    switch (status) {
      case "Active":
        return (
          <i className="pi pi-check-circle" style={{ cursor: "pointer", fontSize: "12px" }}></i>
        );
        break;
      case "Error":
        return (
          <i
            className="pi pi-exclamation-circle text-red-500"
            style={{ cursor: "pointer", fontSize: "12px" }}
          ></i>
        );
        break;
      case "Syncing":
      case "syncing":
        return (
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="6.3999" cy="6" r="4.5" stroke="#1581ED" />
            <circle cx="6.3999" cy="6" r="2" fill="#2080F7" />
          </svg>
        );
    }
  };

  const header = (
    <div className="table-header">
      <span className="table-title">Customer Insights</span>
    </div>
  );

  useEffect(() => {
    if (spaceId) {
      fetchSourceData(filters);
    }
  }, [spaceId, page, limit]);

  // Handle the redirect back with connection_id
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const connectionId = urlParams.get("connection_id");

    if (connectionId) {
      // Remove the connection_id from URL
      const newUrl = window.location.pathname + window.location.hash;
      window.history.replaceState({}, "", newUrl);

      // Show success message
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Successfully connected to OneDrive",
      });
    }
  }, []);

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog className="w-5" />

      <div
        className="customer-insights-header"
        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
      >
        <Button
          icon="pi pi-trash"
          label="Delete"
          className="text-sm text-[#495057] ml-2 bg-white border-0"
          disabled={true}
        />
        <div>
          <Button
            onClick={(e) => {
              op.current.toggle(e);
            }}
            text
            icon={<FilterIcon />}
            pt={{
              label: {
                className: "text-sm font-semibold",
              },
            }}
            label="Filter"
          />
          <Filters
            filters={filters}
            setFilters={setFilters}
            op={op}
            handleFilterApply={handleFilterApply}
          />

          <Button
            icon="pi pi-plus"
            label="Connect Source"
            className="text-sm text-blue-500 bg-white ml-2"
            // onClick={() => navigate('/spaces/file-data/new-space-setup')}
            onClick={() => navigate(`/spaces/file-data/${spaceId}/new-space-setup`)}
          />
        </div>
      </div>
      <div className="card mt-3 customerInsightsTable" style={{ height: "50vh" }}>
        <DataTable
          value={
            loading
              ? Array.from({ length: rowsPerPage }, (_, index) => ({ id: index }))
              : Array.isArray(tableData)
                ? tableData
                : []
          } // Show skeleton rows when loading
          paginator
          first={(currentPage - 1) * rowsPerPage || 0}
          rows={rowsPerPage}
          totalRecords={loading ? 0 : Array.isArray(tableData) ? tableData.length : 0}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          rowsPerPageOptions={[5, 10, 20, 30]}
          onPage={(event) => {
            setCurrentPage(event.page + 1);
            setRowsPerPage(event.rows);
          }}
          emptyMessage="No Sources found"
          className="w-full"
          scrollable
          scrollHeight="flex"
          selection={selectedSources}
          onSelectionChange={(e) => setSelectedSources(e.value)}
          dataKey="id"
          selectionMode={"checkbox"}
        >
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>

          <Column
            field="sourceType"
            header="Source"
            sortable
            style={{ width: "20%" }}
            body={(rowData) =>
              loading ? (
                <Skeleton width="100%" height="2rem" />
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <img
                      loading="lazy"
                      src={rowData?.connector?.icon_url || LocalDrive}
                      style={{
                        objectFit: "contain",
                        flexShrink: 0,
                        alignSelf: "stretch",
                        marginTop: "auto",
                        marginBottom: "auto",
                        width: "2rem",
                        borderRadius: "0.375rem",
                        aspectRatio: "1 / 1",
                      }}
                    />
                    <span className="text-label" style={{ marginLeft: "2px" }}>
                      {rowData.sourceType}
                    </span>
                  </div>
                  <span style={{ fontSize: "10px" }}>{rowData.name}</span>
                </div>
              )
            }
          ></Column>

          <Column
            field="sync_Type"
            header="Sync Type"
            style={{ width: "15%" }}
            body={(rowData) =>
              loading ? (
                <Skeleton width="100%" height="2rem" />
              ) : (
                <span className="text-label">{rowData?.configuration?.sync_type}</span>
              )
            }
          ></Column>

          <Column
            field="connectionInfo"
            header="Connection Info"
            body={(rowData) =>
              loading ? (
                <Skeleton width="100%" height="2rem" />
              ) : (
                <>
                  {rowData?.configuration?.sync_frequency ? (
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setVisible(true);
                      }}
                      style={{ color: "#3B82F6" }}
                    >
                      Click to View
                    </a>
                  ) : (
                    <span>-</span>
                  )}
                  <Dialog
                    visible={visible}
                    onHide={() => setVisible(false)}
                    modal
                    closeOnEscape
                    dismissableMask
                    className="w-[630px] connection-info-dialog"
                    breakpoints={{ "960px": "75vw", "640px": "100vw" }}
                    showHeader={false}
                    maskClassName="bg-black-alpha-20"
                    keepInViewport={true}
                    pt={{
                      root: { style: { borderRadius: "16px" } },
                      content: { style: { borderRadius: "16px" } },
                    }}
                  >
                    <ConnectionInfoPanel onHide={() => setVisible(false)} />
                  </Dialog>
                </>
              )
            }
            style={{ width: "25%" }}
          />

          <Column
            field="status"
            header="Status"
            sortable
            style={{ width: "15%" }}
            body={(rowData) =>
              loading ? (
                <Skeleton width="100%" height="2rem" />
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Tag
                    style={{
                      backgroundColor: "#F5F5F5",
                      display: "flex",
                      alignSelf: "baseline",
                      color: "#191A1C",
                      borderRadius: "15px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingLeft: "7px",
                        paddingRight: "7px",
                      }}
                    >
                      {renderStatusIcon(rowData.status)}
                      <span className="text-label" style={{ fontSize: "11px", marginLeft: "3px" }}>
                        {rowData.status}
                      </span>
                    </div>
                  </Tag>
                  <span style={{ fontSize: "10px", marginTop: "5px" }}>{rowData.lastSync}</span>
                </div>
              )
            }
          ></Column>

          <Column
            field="sync_frequency"
            header="Sync Frequency"
            style={{ width: "15%" }}
            body={(rowData) =>
              loading ? (
                <Skeleton width="100%" height="2rem" />
              ) : (
                <span className="text-label">{rowData?.sync_frequency || "-"}</span>
              )
            }
          ></Column>

          <Column
            field="connected_on"
            header="Connected On"
            sortable
            style={{ width: "20%" }}
            body={(rowData) =>
              loading ? (
                <Skeleton width="100%" height="2rem" />
              ) : (
                <span className="text-label">
                  {rowData?.created_at ? formatDateWithTime(rowData.created_at, true) : "-"}
                </span>
              )
            }
          ></Column>

          <Column
            body={(rowData) => actionTemplate(rowData)}
            header="Actions"
            style={{ textAlign: "center" }}
          />
        </DataTable>
      </div>
    </Fragment>
  );
};

/**
 * Filter component that provides filtering options for the data table.
 *
 * @param {Object} props - Component properties
 * @param {Object} props.filters - Current filter state
 * @param {Function} props.setFilters - Function to update filter state
 * @param {React.RefObject} props.op - Reference to the overlay panel
 * @param {Function} props.handleFilterApply - Function to apply selected filters
 * @returns {JSX.Element} Filter panel with various filtering options
 */
const Filters = ({ filters, setFilters, op, handleFilterApply }) => {
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Error", value: "error" },
    { label: "Syncing", value: "syncing" },
  ];

  return (
    <OverlayPanel
      ref={op}
      className="w-19rem filterPopup"
      pt={{ content: { className: "px-4 py-3" } }}
    >
      <div className="flex flex-column gap-4">
        <div className="flex justify-content-between gap-2 align-items-center">
          <p className="text-base font-semibold p-0 m-0">Filter Space</p>
          <span className="text-sm font-semibold cursor-pointer" onClick={() => op.current.hide()}>
            <i className="pi pi-times" />
          </span>
        </div>

        <div className="flex justify-content-center flex-column">
          <label htmlFor="dateRange" className="block mb-2 text-label">
            Connected On
          </label>
          <Calendar
            id="dateRange"
            name="dateRange"
            value={filters.dateRange}
            onChange={onInputChange}
            showIcon
            selectionMode="range"
            placeholder="dd/mm/yyyy - dd/mm/yyyy"
            maxDate={new Date()}
          />
        </div>

        <div className="flex justify-content-center flex-column">
          <label htmlFor="status" className="block mb-2 text-label">
            Status
          </label>
          <Dropdown
            id="status"
            name="status"
            value={filters.status}
            options={statusOptions}
            onChange={onInputChange}
            placeholder="Select Status"
            className="w-full"
          />
        </div>

        <div className="flex justify-content-between gap-2 align-items-center">
          <Button
            label="Reset"
            severity="danger"
            text
            onClick={() => {
              setFilters({});
              handleFilterApply({});
            }}
          />
          <Button
            label="Apply"
            className="text-sm"
            onClick={() => {
              handleFilterApply(filters);
            }}
          />
        </div>
      </div>
    </OverlayPanel>
  );
};

/**
 * Handles the preview action for a row.
 *
 * @param {Object} rowData - The data for the row being previewed
 */
const handlePreviewClick = (rowData) => {};

export default CustomerInsights;
