import React from "react";

const BookLogo = ({ selectedItem }) => {
  return selectedItem === "templates" ? (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5 16.7397C22.5 17.7097 21.71 18.5997 20.74 18.7197L20.43 18.7597C18.79 18.9797 16.48 19.6597 14.62 20.4397C13.97 20.7097 13.25 20.2197 13.25 19.5097V5.59969C13.25 5.22969 13.46 4.88969 13.79 4.70969C15.62 3.71969 18.39 2.83969 20.27 2.67969H20.33C21.53 2.67969 22.5 3.64969 22.5 4.84969V16.7397Z"
        fill="#1D67D5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.72828 2.67969H4.65828C3.45828 2.67969 2.48828 3.64969 2.48828 4.84969V16.7397C2.48828 17.7097 3.27828 18.5997 4.24828 18.7197L4.55828 18.7597C6.19828 18.9797 8.50828 19.6597 10.3683 20.4397C11.0183 20.7097 11.7383 20.2197 11.7383 19.5097V5.59969C11.7383 5.21969 11.5383 4.88969 11.2083 4.70969C9.37828 3.71969 6.60828 2.83969 4.72828 2.67969Z"
        fill="#1D67D5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.74828 7.73969C8.15828 7.73969 8.49828 8.07969 8.49828 8.48969C8.49828 8.90969 8.15828 9.23969 7.74828 9.23969H5.49828C5.08828 9.23969 4.74828 8.90969 4.74828 8.48969C4.74828 8.07969 5.08828 7.73969 5.49828 7.73969H7.74828Z"
        fill="#1D67D5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.49828 12.2397C5.08828 12.2397 4.74828 11.9097 4.74828 11.4897C4.74828 11.0797 5.08828 10.7397 5.49828 10.7397H8.49828C8.90828 10.7397 9.24828 11.0797 9.24828 11.4897C9.24828 11.9097 8.90828 12.2397 8.49828 12.2397H5.49828Z"
        fill="#1D67D5"
      />
    </svg>
  ) : (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5 4.66994C22.5 3.46994 21.52 2.57994 20.33 2.67994H20.27C18.17 2.85994 14.98 3.92994 13.2 5.04994L13.03 5.15994C12.74 5.33994 12.26 5.33994 11.97 5.15994L11.72 5.00994C9.94 3.89994 6.76 2.83994 4.66 2.66994C3.47 2.56994 2.5 3.46994 2.5 4.65994V16.7399C2.5 17.6999 3.28 18.5999 4.24 18.7199L4.53 18.7599C6.7 19.0499 10.05 20.1499 11.97 21.1999L12.01 21.2199C12.28 21.3699 12.71 21.3699 12.97 21.2199C14.89 20.1599 18.25 19.0499 20.43 18.7599L20.76 18.7199C21.72 18.5999 22.5 17.6999 22.5 16.7399V4.66994Z"
        stroke="#BAAFC9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 5.48999V20.49"
        stroke="#BAAFC9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.25 8.48999H6"
        stroke="#BAAFC9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9 11.49H6"
        stroke="#BAAFC9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default BookLogo;
