import React from "react";
import CreditsHistory from "components/Credits/CreditsHistory";

const CreditsHistoryList = () => {
  return (
    <div className={`surface-0 `}>
      <CreditsHistory />
    </div>
  );
};

export default CreditsHistoryList;
