import React, { useEffect, useRef } from "react";
import { Avatar } from "primereact/avatar";
import { TabView, TabPanel } from "primereact/tabview";
import { Chip } from "primereact/chip";
import { Button } from "primereact/button";
import BasicInfoTab from "./BasicInfoTab";
import GetUsersList from "hooks/getUsers";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";

const UserProfile = () => {
  const { id } = useParams();
  const { getUserDetails, data } = GetUsersList();
  const toast = useRef(null);
  const getStatusColor = (status) => {
    return status ? "green" : "red";
  };

  useEffect(() => {
    if (id) {
      getUserDetails(id)
        .catch((err) => {
          console.log("detail", err);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: err?.response?.data?.message,
            life: 3000,
          });
        })
        .finally(() => {});
    }
  }, []);

  const statusTemplate = (
    <div className="flex align-items-center">
      <span
        className="mr-2"
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          backgroundColor: getStatusColor(data?.results?.is_active),
          display: "inline-block",
        }}
      ></span>
      {data?.results?.is_active ? "Active" : "Inactive"}
    </div>
  );
  const avatarLabel = data?.results?.email ? data.results.email.charAt(0).toUpperCase() : "";

  return (
    <div className="mt-2">
      <Toast ref={toast} position="top-right" />
      <div className="flex justify-content-between align-items-center mb-4">
        <div className="flex align-items-center">
          <Avatar label={avatarLabel} size="xlarge" shape="circle" />
          <div className="flex-col ml-3">
            <p className="m-0 text-2xl font-bold">{data?.results?.email}</p>
            <p className="m-0 text-sm mt-2">Credit Balance: {data?.results?.credit_balance}</p>
            <Chip label={statusTemplate} className="text-xs py-1 mt-2" />
            <Button rounded text icon="pi pi-pencil" className="text-blue-500" />
          </div>
        </div>
      </div>
      <TabView className="text-sm">
        <TabPanel header="Basic Info" className="text-sm">
          <BasicInfoTab userInfo={data?.results} />
        </TabPanel>
        <TabPanel header="Credit Info"></TabPanel>
        <TabPanel header="Access to Data"></TabPanel>
        <TabPanel header="Access to Agent"></TabPanel>
        <TabPanel header="Pulse Notification Info"></TabPanel>
      </TabView>
    </div>
  );
};

export default UserProfile;
