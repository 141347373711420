import useApi from "services/api";
import { API_URL } from "../constants";

const useUser = () => {
  const { post, patch } = useApi();

  const createUser = async (user) => {
    const data = await post(`${API_URL}/intelliapp/user`, user);
    return data;
  };

  const allocateCredits = async (user_id, allocation) => {
    const data = await patch(`${API_URL}/user/${user_id}/allocate_credits`, allocation);
    return data;
  };

  const deleteRecurringAllocation = async (user_id) => {
    const data = await patch(`${API_URL}/user/${user_id}/delete-recurring-allocation`);
    return data;
  };

  return { createUser, allocateCredits, deleteRecurringAllocation };
};

export default useUser;
