import useApi from "services/api";
import { API_URL } from "../constants";

const useTemplateList = () => {
  const { get, del } = useApi();

  const getMyTemplates = async (shared) => {
    const data = await get(`${API_URL}/template/?shared=${shared}&detailed=true&page_number=1`);
    return data;
  };

  const getMyTemplatesIds = async () => {
    const data = await get(`${API_URL}/template/?detailed=false&page_number=1`);
    return data;
  };

  const deleteTemplate = async (id) => {
    const data = await del(`${API_URL}/template/${id}`);
    return data;
  };

  return { getMyTemplates, deleteTemplate, getMyTemplatesIds };
};

export default useTemplateList;
