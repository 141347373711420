import React from "react";
import CreditSummaryPanel from "components/Credits/CreditSummaryPanel";

const CreditSummaryList = () => {
  return (
    <div className={`surface-0 `}>
      <CreditSummaryPanel />
    </div>
  );
};

export default CreditSummaryList;
