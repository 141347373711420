import "./workspace.css";
import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import StatusTab from "components/DocumentEngine/WorkspaceSection/StatusTab";
import TaskTab from "components/DocumentEngine/WorkspaceSection/TaskTab";
import FileUploadIcon from "../../../assets/WorkSpaceIcons/FileUploadIcon.svg";
import FileIcon from "../../../assets/WorkSpaceIcons/FileIcon.svg";
import GoogleDrive from "../../../assets/WorkSpaceIcons/GoogleDrive.svg";
import OneDrive from "../../../assets/WorkSpaceIcons/OneDrive.svg";
import DialogUploaderComponent from "components/DocumentEngine/WorkspaceSection/DialogUploaderComponent";
import useTemplateList from "hooks/useTemplateList";
import { useUploadedFiles } from "context/upladedFilesContext";
import PostTaskCreationData from "hooks/generateTask";
import useMediaQuerry from "../../../hooks/useMediaQuerry";
import useTask from "hooks/useTask";
import { MdInfo } from "react-icons/md";
import UploadDocumentsForSynthesization from "hooks/uploadDocuments";
import { v4 as uuid } from "uuid";
import { deleteCarbonFiles, getCarbonOauthUrl, getFilesFromCarbon } from "lib/carbon";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import useTaskApplyFilters from "hooks/useTaskFilters";

const inputDocuments = [
  { name: "Upload from device", code: "fileUpload", icon: FileIcon },
  { name: "Upload from Google Drive", code: "googleDrive", icon: GoogleDrive },
  { name: "Upload from One Drive", code: "oneDrive", icon: OneDrive },
];

const Workspace = () => {
  const [visible, setVisible] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedOutput, setSelectedOutput] = useState("");
  const [isShowDocContainer, setIsShowDocContainer] = useState(false);
  const [isShowTaskListContainer, setIsShowTaskListContainer] = useState(true);
  const [isShowTaskDescription, setIsShowTaskDescription] = useState(false);
  const [selectFormatOptions, setSelectedForamtOptions] = useState([]);
  const [selectedInput, setSelectedInput] = useState("");
  const [dateofIncidence, setDateofIncidence] = useState(null);
  const [detailsOfIncidence, setDetailsOfIncidence] = useState("");
  const { getMyTemplatesIds, data, error, loading } = useTemplateList();
  const { isMobile, isLandScape } = useMediaQuerry();
  const [selectedTask, setSelectedTask] = useState({});
  const [initialFilters, setInitialFilters] = useState(false);
  const [task, setTask] = useState(null);
  const [taskLoading, setTaskLoading] = useState(false);
  const { getTask, provideError } = useTask();
  const [taskCount, setTaskCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [outputFormats, setOutputFormats] = useState([]);
  const { getOutputTypes } = useTask();

  const {
    postTaskData,
    data: generateTaskSuccess,
    error: generateTaskError,
    loading: generateTaskLoading,
  } = PostTaskCreationData();
  const toast = useRef(null);
  const { uploadedFiles, setUploadedFiles } = useUploadedFiles();
  const { terminateJob, loading: terminateProcessLoading } = PostTaskCreationData();
  const { getCarbonToken } = UploadDocumentsForSynthesization();

  const { applyFilters } = useTaskApplyFilters();

  const onCancel = () => {
    setSelectedInput("");
  };

  useEffect(() => {
    if (selectedTask?.id && selectedTask !== null) {
      setIsShowTaskListContainer(false);
      setIsShowTaskDescription(true);
    }
  }, [selectedTask]);
  const [carbonAccessToken, setCarbonAccessToken] = useState(null);
  const [jobUUID, setJobUUID] = useState(null);
  useEffect(() => {
    (async () => {
      const accessToken = (await tokenFetcher()).access_token;
      setCarbonAccessToken(accessToken);
      setJobUUID(uuid());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = await getOutputTypes();
        setOutputFormats(data?.results?.data);
      } catch (error) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error fetching Output Types",
          life: 3000,
        });
      }
    })();
  }, []);

  const openUpload = async (type) => {
    try {
      const { data } = await getCarbonOauthUrl(carbonAccessToken, jobUUID, type);
      const width = 800;
      const height = 600;
      const left = (window.screen.width - width) / 2;
      const top = (window.screen.height - height) / 2;

      const uploaderWindow = window.open(
        data.oauth_url,
        "_blank",
        `width=${width},height=${height},left=${left},top=${top},toolbar=0,menubar=0,scrollbars=0,resizable=0,status=0`,
      );
      let toastShown = false;
      if (uploaderWindow) {
        const interval = setInterval(async () => {
          const response = await getFilesFromCarbon(carbonAccessToken, jobUUID);
          console.log(response?.data?.results);
          if (response?.data?.results?.length > 0) {
            const uploadedFiles = response?.data?.results?.map((e) => {
              return {
                file_id: e.id,
                file_data: {
                  file_name: e.name,
                  size: e?.file_statistics?.file_size,
                },
              };
            });
            //filter only pdf and docx files
            const filteredFiles = uploadedFiles.filter((file) => {
              const fileName = file.file_data.file_name;
              return fileName.endsWith(".pdf") || fileName.endsWith(".docx");
            });
            uploaderWindow.close();
            if (filteredFiles.length === 0) {
              setSelectedInput("");
              setJobUUID(uuid());
            }
            if (filteredFiles.length !== response?.data?.results?.length && !toastShown) {
              toastShown = true;
              await deleteCarbonFiles(
                carbonAccessToken,
                response.data.results
                  .filter((file) => !file.name.endsWith(".pdf") && !file.name.endsWith(".docx"))
                  .map((file) => file.id),
              );
              toast.current.show({
                severity: "info",
                summary: "Info",
                detail: "Only PDF and DOCX files are allowed. Unsupported files are ignored.",
                life: 3000,
              });
            }
            setUploadedFiles(filteredFiles);
          }
          if (uploaderWindow.closed) {
            setTimeout(async () => {
              clearInterval(interval);
            }, 1000);
          }
        }, 2000);
      }
    } catch (e) {
      console.error(e);
      if (e.message === "Carbon access token is required") {
        setTimeout(() => {
          openUpload(type);
        }, 1000);
      }
    }
  };

  const templateNamesList = async () => {
    try {
      const data = await getMyTemplatesIds();
      if (data.success && data.results && data.results.Templates) {
        const templateNames = data.results.Templates.filter(
          (template) => template.status !== "in_progress",
        ).map((template) => ({
          id: template.id,
          templateName: template.templateName,
        }));
        const updatedTemplateNames = [
          { id: "custom", templateName: "Freeflow Text" },
          { id: "generic", templateName: "General Settlement Demand Template" },
          ...templateNames,
        ];
        setSelectedForamtOptions(updatedTemplateNames);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please try again later",
        life: 3000,
      });
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    if (updatedFiles.length === 0) {
      setSelectedInput("");
    }
    setUploadedFiles(updatedFiles);
  };

  useEffect(() => {
    templateNamesList();
  }, []);

  useEffect(() => {
    if (generateTaskSuccess?.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Task added successfully",
        life: 1000,
      });
      setUploadedFiles([]);
      setSelectedFormat("");
      setSelectedOutput("");
    }
    if (generateTaskError?.status === 400) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: generateTaskError?.message || "An error occurred during the upload.",
        life: 3000,
      });
    }
  }, [generateTaskSuccess, generateTaskError]);

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src={option.icon}
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "18px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return (
      <div className="flex align-items-center">
        <img
          alt={"Upload"}
          src={FileUploadIcon}
          className={`mr-2 flag flag-fileupload`}
          style={{ width: "18px" }}
        />
        <div>{props.placeholder}</div>
      </div>
    );
  };

  const generateTask = async () => {
    try {
      const formattedDate = dateofIncidence ? dateofIncidence.toISOString().split("T")[0] : null;
      const payLoad = {
        template_id: selectedFormat?.id === "custom" ? null : selectedFormat?.id?.toString(),
        output_format_id: selectedOutput ? selectedOutput : null,
        input_docs: uploadedFiles?.map((file) => file.file_id),
        upload_type: selectedInput.code || "fileUpload",
        date_of_incidence: formattedDate,
        details_of_incidence: detailsOfIncidence,
      };
      await postTaskData(payLoad);
      setJobUUID(uuid());
      setSelectedInput("");
      setDateofIncidence(null);
      setDetailsOfIncidence("");
      fetchTasks();
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error in generating task. Please try again",
        life: 3000,
      });
    }
  };

  const uploaderTemplate = (option) => {
    return (
      <div
        className="flex align-items-center w-full p-3"
        onClick={() => {
          onSelectUploadType(option.code);
        }}
      >
        <img
          alt={option.name}
          src={option.icon}
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  const [taskList, setTaskList] = useState([]);

  const [currentFilters, setCurrentFilters] = useState({
    current_page: pageNo,
    page_size: 10,
  });

  const fetchTasks = async () => {
    try {
      const data = await applyFilters(currentFilters);

      if (data.success && data.results) {
        setTaskCount(data.results.total_records);
        setTaskList((prevList) => {
          if (JSON.stringify(prevList) !== JSON.stringify(data.results.data)) {
            return data.results.data;
          }
          return prevList;
        });
        setSelectedTask((prevTask) => {
          if (!prevTask || !data.results.data.find((task) => task.id === prevTask.id)) {
            return data?.results?.data?.[0] || {};
          }
          return prevTask;
        });
        setInitialFilters(true);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please try again later",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [terminateProcessLoading, pageNo]);

  useEffect(() => {
    if (taskList.length !== 0) {
      const intervalId = setInterval(() => {
        if (!isPdfViewerOpen) {
          fetchTasks();
          if (task?.id) {
            (async () => {
              try {
                const data = await getTask(task.id);
                setTask(data?.results?.data);
              } catch (err) {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: "Error in fetching task details",
                  life: 3000,
                });
              }
            })();
          }
        }
      }, 10000);

      return () => clearInterval(intervalId);
    }
    return () => {};
  }, [taskList, isPdfViewerOpen, task?.id]);

  useEffect(() => {
    if (!selectedTask?.id) return;

    (async () => {
      try {
        setTaskLoading(true);
        const data = await getTask(selectedTask?.id);
        setTask(data?.results?.data);
      } catch (err) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Error in fetching task details",
          life: 3000,
        });
      } finally {
        setTaskLoading(false);
      }
    })();
  }, [selectedTask?.id, terminateProcessLoading]);

  const disableButton = !selectedFormat || !selectedOutput || uploadedFiles?.length === 0;

  const tokenFetcher = async () => {
    const res = await getCarbonToken();
    return {
      access_token: res?.results?.access_token,
    };
  };

  const handleFilterApply = async (filtersList) => {
    const filters = {
      ...filtersList,
      current_page: pageNo,
      page_size: 10,
    };
    setCurrentFilters(filters);

    const data = await applyFilters(filters);
    if (data?.success && data?.results?.data) {
      setTaskList(data.results.data);
      setTaskCount(data.results.total_records);
      setSelectedTask(data?.results?.data?.[0] || {});
    }
  };

  const handlePageChange = async (newPage) => {
    const updatedFilters = {
      ...currentFilters,
      current_page: newPage,
      page_size: 10,
    };
    setCurrentFilters(updatedFilters);
    setPageNo(newPage);

    try {
      const data = await applyFilters(updatedFilters);

      if (data.success && data.results) {
        setTaskCount(data.results.total_records);
        setTaskList(data.results.data);
        setSelectedTask(data?.results?.data?.[0] || {});
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please try again later",
        life: 3000,
      });
    }
  };

  const onSelectUploadType = (selected) => {
    if (selected === "googleDrive") {
      openUpload("googleDrive");
      setJobUUID(uuid());
    }
    if (selected === "oneDrive") {
      openUpload("oneDrive");
      setJobUUID(uuid());
    }
    if (selected === "fileUpload") {
      setVisible(true);
    }
    setFiles([]);
  };

  return (
    <div
      className={`grid text-800 workspaces ${!isMobile || (isShowDocContainer && isMobile) ? "px-4" : ""} `}
    >
      <Toast ref={toast} />
      {(!(isMobile || isLandScape) || isShowDocContainer) && (
        <div
          className={`col-12 lg:col-3 pr-2 pt-4 ${isMobile ? "h-full" : ""} ${!isShowDocContainer && isMobile ? "hidden " : ""} overflow-y-auto`}
          style={{ maxHeight: "calc(100vh - 60px)" }}
        >
          <section className="text-sm">
            <div
              className={`${isMobile || isLandScape ? "inline-flex w-full doc-header-mobile" : ""}`}
            >
              <h3 className="text-xl mt-0">Document Synthesization</h3>

              {(isMobile || isLandScape) && (
                <button
                  className="text-xl doc-close-btn"
                  onClick={() => setIsShowDocContainer(false)}
                >
                  X
                </button>
              )}
            </div>
            <div className="flex-auto py-2 w-full">
              <Tooltip target=".upload-target-icon" />
              <label htmlFor="integeronly" className="flex align-items-center mb-2">
                Input Documents&nbsp;
                <MdInfo
                  className="upload-target-icon text-gray-400 ml-2"
                  size={22}
                  data-pr-tooltip="Upload files directly from Computer, OneDrive or Google Drive."
                  data-pr-position="top"
                  style={{ cursor: "pointer" }}
                />
              </label>
              <Dropdown
                value={selectedInput}
                onChange={(e) => {
                  setSelectedInput(e.value);
                }}
                options={inputDocuments}
                pt={{
                  item: {
                    className: "w-full p-0",
                  },
                  itemLabel: {
                    className: "w-full p-0",
                  },
                }}
                itemTemplate={uploaderTemplate}
                valueTemplate={selectedCountryTemplate}
                optionLabel="name"
                placeholder="Upload"
                className="w-full text-xs text-color-gray"
                dropdownIcon="pi pi-chevron-down"
              />
              <div
                className={`flex-auto w-full ${uploadedFiles?.length > 0 && "my-2 max-h-10rem overflow-scroll pr-1"}`}
              >
                {uploadedFiles?.map((file, index) => {
                  const truncatedFileName = file?.file_data?.file_name;

                  const fileSize = (file?.file_data?.size / 1024 / 1024).toFixed(2);
                  return (
                    <div className="uploaded-file-item text-sm flex mt-2" key={index}>
                      <div className="file-details">
                        <i className="pi pi-file-pdf file-icon text-md"></i>
                        <div className="file-name">
                          <div className="text-sm text-ellipsis" title={file?.file_data?.file_name}>
                            {truncatedFileName}
                          </div>
                          <div className="text-sm file-size">
                            {isNaN(fileSize) ? "" : `${fileSize} MB`}
                          </div>
                        </div>
                        <button
                          className="remove-button-workspace"
                          onClick={() => handleRemoveFile(index)}
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex-auto py-2 w-full">
              <Tooltip target=".output-target-icon" />
              <label htmlFor="integeronly" className="flex align-items-center mb-2">
                Output Type&nbsp;
                <MdInfo
                  className="output-target-icon text-gray-400 ml-2"
                  size={22}
                  data-pr-tooltip="Select how you want your output."
                  data-pr-position="top"
                  style={{ cursor: "pointer" }}
                />
              </label>
              <Dropdown
                value={selectedOutput}
                onChange={(e) => setSelectedOutput(e.value)}
                options={outputFormats}
                optionValue="id"
                optionLabel="output_format"
                placeholder="Select Output Type"
                className="w-full"
                inputClassName="text-sm text-color-gray"
              />
            </div>
            <div className="flex-auto py-2 w-full">
              <Tooltip target=".format-target-icon" />
              <label htmlFor="integeronly" className="flex align-items-center mb-2">
                Select Format&nbsp;
                <MdInfo
                  className="format-target-icon text-gray-400 ml-2"
                  size={22}
                  data-pr-tooltip="Choose your preferred format."
                  data-pr-position="top"
                  style={{ cursor: "pointer" }}
                />
              </label>
              <Dropdown
                value={selectedFormat}
                onChange={(e) => setSelectedFormat(e.value)}
                options={selectFormatOptions}
                optionLabel="templateName"
                placeholder="Select Format"
                className="w-full"
                inputClassName="text-sm text-color-gray"
              />
            </div>
            <div className="flex-auto py-2 w-full">
              <label htmlFor="integeronly" className="flex align-items-center mb-2">
                Date of Incidence
              </label>
              <Calendar
                placeholder="Select Date"
                value={dateofIncidence}
                maxDate={new Date()}
                onChange={(e) => setDateofIncidence(e.value)}
                className="w-full dateofIncidence"
                showIcon
                inputClassName="text-sm text-color-gray"
              />
            </div>
            <div className="flex-auto py-2 w-full">
              <Tooltip target=".format-target-icon" />
              <label htmlFor="integeronly" className="flex align-items-center mb-2">
                Details of Incidence
              </label>
              <InputTextarea
                placeholder="Please provide a brief, 2-line summary highlighting the key incident"
                value={detailsOfIncidence}
                onChange={(e) => {
                  if (e.target.value.length <= 200) {
                    setDetailsOfIncidence(e.target.value);
                  }
                }}
                className="w-full text-sm text-color-gray"
                rows={3}
                autoResize={false}
                maxLength={200}
                style={{ resize: "none" }}
              />
              <div className="text-right text-sm text-gray-500 mt-1">
                {detailsOfIncidence.length}/200
              </div>
            </div>
            <Button
              label="Generate"
              icon="pi pi-sparkles"
              loading={generateTaskLoading}
              iconPos="right"
              disabled={disableButton}
              onClick={generateTask}
              className={`w-full mt-5 ${!disableButton ? "generateButton" : "disabledGenerate"}`}
            />
          </section>
        </div>
      )}
      {taskList.length > 0 && initialFilters ? (
        <div
          className={`col-12 md:col-5 h-screen lg:col-3 ${isShowDocContainer || (isMobile && !isShowTaskListContainer) ? "hidden" : ""} overflow-hidden`}
        >
          <TaskTab
            taskList={taskList}
            setTaskList={setTaskList}
            taskCount={taskCount}
            selectedTask={selectedTask}
            setSelectedTask={setSelectedTask}
            setTaskCount={setTaskCount}
            outputType={outputFormats}
            taskUrl={task?.preview_presigned_url}
            pageNo={pageNo}
            setPageNo={setPageNo}
            downloadUrl={task?.download_presigned_url}
            onPdfViewerStateChange={(isOpen) => setIsPdfViewerOpen(isOpen)}
            handlePageChange={handlePageChange}
            handleFilterApply={handleFilterApply}
          />
        </div>
      ) : (
        <div className={`col-12 md:col-5 h-screen lg:col-3 overflow-hidden`}>
          <TaskTab
            taskList={taskList}
            setTaskList={setTaskList}
            taskCount={taskCount}
            selectedTask={selectedTask}
            setSelectedTask={setSelectedTask}
            setTaskCount={setTaskCount}
            outputType={outputFormats}
            taskUrl={task?.preview_presigned_url}
            pageNo={pageNo}
            setPageNo={setPageNo}
            downloadUrl={task?.download_presigned_url}
            onPdfViewerStateChange={(isOpen) => setIsPdfViewerOpen(isOpen)}
            handlePageChange={handlePageChange}
            handleFilterApply={handleFilterApply}
          />
        </div>
      )}
      <div
        className={`surface-0 task-details-container col-12 md:col-12 lg:col-6 ${isShowDocContainer || (isMobile && isShowTaskListContainer) || (isMobile && !isShowTaskDescription) ? "hidden" : ""} overflow-y-auto pl-2`}
      >
        <StatusTab
          setTask={setTask}
          templates={selectFormatOptions}
          taskList={taskList}
          loading={taskLoading}
          taskId={taskList.length > 0 ? task?.id : null}
          setIsShowTaskListContainer={setIsShowTaskListContainer}
          setSelectedTask={setSelectedTask}
          task={task}
          terminateJob={terminateJob}
          onPdfViewerStateChange={(isOpen) => setIsPdfViewerOpen(isOpen)}
          outputFormat={outputFormats}
        />
      </div>
      <DialogUploaderComponent
        visible={visible}
        setVisible={setVisible}
        onCancel={onCancel}
        files={files}
        setFiles={setFiles}
      />
      {(isMobile || isLandScape) && !isShowDocContainer && (
        <div className="fixed doc-btn z-5">
          <Button
            icon="pi pi-plus"
            className="text-sm text-white bg-blue-500 h-full w-full "
            onClick={() => {
              setIsShowDocContainer(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Workspace;
