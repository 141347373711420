import { useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import { useParams } from "react-router-dom";
import { SkeletonRow } from "utils/helper";
import AddChecklist from "./AddChecklist";
import { Button } from "primereact/button";
import "./index.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import FactsChecklistReport from "./FactsChecklistReport";
import useFacts from "hooks/useFacts";

function Facts() {
    const toast = useRef(null);
    const { spaceId } = useParams();
    const { getFacts, data, loading, error } = useFacts();
    const [facts, setFacts] = useState([]);
    const [checklistStage, setChecklistStage] = useState(null);
    const [visible, setVisible] = useState(true);
    //     {
    //         id: 1,
    //         name: 'Doc Level',
    //         description: 'Checklist 1 description',
    //         status: 'active',
    //         categories: ['All Documents'],
    //         parameters: [{
    //             name: "Parameter 1",
    //             conditionEnabled: true,
    //             conditions: [{ operator: "is", value: "true" }, { operator: "is", value: "true", orAnd: "and" }],
    //             actionEnabled: true,
    //             actions: [{ type: "sendEmail", details: ["test@test.com", "test@test.com", "test@test.com"] }]
    //         }, {
    //             name: "Parameter 2",
    //             conditionEnabled: true,
    //             conditions: [{ operator: "is", value: "true" }, { operator: "is", value: "true", orAnd: "and" }],
    //             actionEnabled: true,
    //             actions: [{ type: "sendEmail", details: ["test@test.com", "test@test.com", "test@test.com"] }]
    //         }],
    //         default: false,
    //         defaultAction: {
    //             type: "",
    //             details: []
    //         },
    //         checklistType: 'spaceLevel',
    //         checklistReportType: true
    //     },
    //     {
    //         id: 2,
    //         name: 'Space Level',
    //         description: 'Checklist 1 description',
    //         status: 'active',
    //         categories: ['Legal Documents', 'Financial Documents', 'Other Documents'],
    //         parameters: [{
    //             name: "Parameter 1",
    //             conditionEnabled: true,
    //             conditions: [{ operator: "is", value: "true" }, { operator: "is", value: "true", orAnd: "and" }],
    //             actionEnabled: true,
    //             actions: [{ type: "sendEmail", details: ["test@test.com", "test@test.com", "test@test.com"] }]
    //         }, {
    //             name: "Parameter 2",
    //             conditionEnabled: true,
    //             conditions: [{ operator: "is", value: "true" }, { operator: "is", value: "true", orAnd: "and" }],
    //             actionEnabled: true,
    //             actions: [{ type: "sendEmail", details: ["test@test.com", "test@test.com", "test@test.com"] }]
    //         }],
    //         default: false,
    //         defaultAction: {
    //             type: "",
    //             details: []
    //         },
    //         checklistType: 'docLevel',
    //         checklistReportType: false
    //     },
    //     {
    //         id: 3,
    //         name: "No Category",
    //         description: "Checklist 2 description",
    //         status: "pending",
    //         parameters: [{
    //             name: "Parameter 1",
    //             conditionEnabled: true,
    //             conditions: [{ operator: "is", value: "true", orAnd: "and" }],
    //             actionEnabled: true,
    //             actions: [{ type: "sendEmail", details: ["test@test.com"] }]
    //         }],
    //         default: false,
    //         defaultAction: {
    //             type: "",
    //             details: []
    //         },
    //         checklistType: 'none',
    //         checklistReportType: false
    //     },
    // ]);
    const [checklist, setChecklist] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedChecklist, setSelectedChecklist] = useState(null);
    const [checklistReportVisible, setChecklistReportVisible] = useState(false);

    useEffect(() => {
        if (checklistStage === null) {
            setVisible(true);
        }
    }, [checklistStage]);

    const fetchChecklist = async () => {
        setIsLoading(true);
        const result = await getFacts(spaceId);
        setChecklist(result);
        setIsLoading(false);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }

    useEffect(() => {
        if (spaceId) {
            setIsLoading(true);
            fetchChecklist();
        }
    }, [spaceId]);

    useEffect(() => {
        const fetchFacts = async () => {
            // const facts = await getFacts(spaceId);
            // setFacts(facts);
            // setIsLoading(false);
            //TODO: Get facts from API
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
        if (spaceId) {
            setIsLoading(true);
            fetchFacts();
        } else {
            setIsLoading(false);
            toast.current.show({
                severity: "error",
                summary: "Error",
                detail: "Space ID is required",
            });
        }
    }, [spaceId]);

    const parametersTabs = () => {
        return selectedChecklist?.parameters?.map((parameter, i) => {
            return (
                <AccordionTab
                    key={parameter.id}
                    header={
                        <div className="flex flex-column">
                            <p className="text-sm font-light my-0 text-900">Parameter</p>
                            <h4 className="text-sm font-bold mt-2 mb-0 text-md text-900">{parameter.name}</h4>
                        </div>
                    }
                >
                    <div className="flex flex-column">
                        <div className="w-full flex align-items-center mt-2">
                            <Checkbox
                                inputId={`conditionalAction-${i}`}
                                name="conditional"
                                checked={parameter.conditions.length > 0}
                                disabled={true}
                            />
                            <label htmlFor={`conditionalAction-${i}`} className="ml-2 text-sm font-light">Conditional action</label>
                        </div>
                        {parameter.conditions.length > 0 && (
                            <div className="condition-container w-full">
                                <p className="text-sm font-light">If following condition is met</p>
                                {parameter.conditions.map((condition, condIndex) => (
                                    <div key={condIndex} className="flex gap-2 w-full mb-0 ml-2 align-items-center condition-row">
                                        <div className={`${condIndex === 0 ? 'w-2' : 'w-1'} relative`}>
                                            <div className="branch-line"></div>
                                        </div>
                                        {condIndex > 0 && (
                                            <div className="w-1 branch-container text-right">
                                                <label htmlFor="orAnd" className="font-light block mb-2 text-sm opacity-0">Or And</label>
                                                <p className="text-md font-light mt-0 uppercase">{condition.orAnd}</p>
                                            </div>
                                        )}
                                        <div className={`${condIndex === 0 && 'pl-2'} w-2`}>
                                            <label htmlFor="operator" className="font-light block mb-2 text-sm">Operator</label>
                                            <p className="text-md font-bold mt-1">{condition.operator}</p>
                                        </div>
                                        <div className="w-2">
                                            <label htmlFor="value" className="font-light block mb-2 text-sm">Set Value</label>
                                            <p className="text-md font-bold mt-1">{condition.value}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {parameter.trigger_actions.length > 0 && (
                            <div className="action-container">
                                <p className="text-sm font-light">Then Trigger Following Actions</p>
                                {parameter.trigger_actions.map((action, actionIndex) => (
                                    <div key={actionIndex} className="flex gap-2 w-full mb-2 align-items-center">
                                        <div className="w-2">
                                            <label htmlFor="selectType" className="font-light block mb-2 text-sm">Type</label>
                                            <p className="text-md font-semibold mt-1">{action.action_type}</p>
                                        </div>
                                        <div className="w-10">
                                            <label htmlFor="details" className="font-light block mb-2 text-sm">Details</label>
                                            <p className="text-md font-semibold mt-1">{action.details.value.join(", ")}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </AccordionTab>
            );
        });
    };

    const ViewChecklist = () => {
        return (
            <div className="flex flex-column justify-content-between">
                {checklist && checklist.length > 0 && checklist.map((item) => (
                    <>
                        <div onClick={() => setSelectedChecklist(item)} className={`flex align-items-center gap-2 text-gray-500 px-2 font-light hover:bg-gray-50 cursor-pointer border-round-lg border-1 border-transparent hover:border-gray-200 mb-1 transition-duration-300 ${selectedChecklist?.id === item.id && "bg-gray-50 border-gray-200"}`}>
                            <p className="text-sm font-medium">{item.name}</p>
                        </div>
                        <div className="border-top-1 border-gray-200 pt-1"></div>
                    </>
                ))}
                <Button
                    className="p-button-text text-blue-500 mt-2 font-medium text-sm flex align-items-center gap-2 py-3 bg-transparent border-none hover:bg-gray-50"
                    onClick={() => setChecklistStage('create')}
                >
                    Add/Edit Checklist
                </Button>
            </div >
        )
    }

    return (
        <div className="h-full facts-container">
            <Toast ref={toast} />
            {isLoading && (
                <div className="grid mt-3">
                    <div className="col-fixed border-right-1 border-gray-200" style={{ width: '20%' }}>
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </div>
                    <div className="col">
                        <SkeletonRow />
                        <SkeletonRow />
                        <SkeletonRow />
                    </div>
                </div>
            )}
            {checklist.length > 0 && (
                <div className="grid mt-3">
                    <div className="col-fixed border-right-1 border-gray-200" style={{ width: '20%' }}>
                        {isLoading ? (
                            <>
                                <SkeletonRow />
                                <SkeletonRow />
                                <SkeletonRow />
                            </>
                        ) : (
                            <ViewChecklist />
                        )}
                    </div>
                    <div className="col">
                        {selectedChecklist ? (
                            <div className="px-2">
                                <p className="font-semibold mt-0">Categories Applied on</p>
                                {selectedChecklist?.categories && selectedChecklist?.categories?.length > 0 ? (
                                    <div className="flex flex-wrap gap-2">
                                        {selectedChecklist?.categories?.map((category, index) => (
                                            <div key={index} className={`flex flex-row gap-2 pr-2 align-items-center py-2 ${index !== 0 ? "border-left-2 border-gray-200 px-3 pb-2" : "pr-3"}`}>
                                                <div className="flex align-items-center justify-content-center mr-2 bg-red-400 border-round-lg" style={{ width: '12px', height: '12px' }}>
                                                </div>
                                                <div>
                                                    <p className="font-semibold my-0 text-sm">{category}</p>
                                                    <span className="font-light text-xs">2 Documents</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="font-semibold">None</p>
                                )}
                                {selectedChecklist?.checklistType === 'docLevel' && <div className="border-1 border-gray-100 p-2 border-round-lg my-2 bg-gray-50 text-sm text-900 w-max">For viewing checklist report at each document level, go to case documents tab</div>}
                                {selectedChecklist?.checklistReportType && <p className="font-semibold mt-4 text-blue-500 cursor-pointer text-sm flex align-items-center gap-2" onClick={() => setChecklistReportVisible(true)}><i className="pi pi-eye"></i>View Checklist Report</p>}
                                <p className="font-semibold mt-5 text-md">Parameters</p>
                                <Accordion>{parametersTabs()}</Accordion>
                            </div>
                        ) : (
                            <div className="px-2 flex justify-content-center align-items-center h-full">
                                <p className="font-semibold mt-0">No checklist selected</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <AddChecklist {...{ checklist, setChecklist, checklistStage, setChecklistStage, visible, setVisible, toast, spaceId, fetchChecklist, setIsLoading }} />
            <FactsChecklistReport visible={checklistReportVisible} onHide={() => setChecklistReportVisible(false)} />
        </div>
    )
}

export default Facts;