import moment from "moment";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { MultiSelect } from "primereact/multiselect";
import { Sidebar } from "primereact/sidebar";
import React, { useState } from "react";

const BasicInfoTab = ({ userInfo }) => {
  const associatedGroups = [];
  const associatedGroupsArray = [];
  const [visible, setVisible] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const onBoardedOn = moment(userInfo?.created_at).format("DD/MM/YYYY");

  const associatedGroupsSidebar = (
    <Sidebar
      visible={visible}
      position="right"
      onHide={() => {
        setVisible(false);
      }}
      header="Associated Groups"
      className="bg-white w-full md:w-20rem lg:w-30rem "
    >
      <div className="flex">
        <MultiSelect
          value={selectedGroups}
          onChange={(e) => {
            setSelectedGroups(e.value);
          }}
          options={associatedGroupsArray}
          fixedPlaceholder={true}
          optionLabel="name"
          filter
          placeholder="Select Groups"
          maxSelectedLabels={3}
          className="w-full md:w-full text-sm"
        />
      </div>
    </Sidebar>
  );

  return (
    <>
      <div className="m-0 pb-2 text-lg font-bold">Basic Info</div>
      <div className="flex-col mt-3">
        <div className="flex items-center mb-2 gap-7">
          <span className="w-32">Onboarded on:</span>
          <span className="ml-4">{onBoardedOn}</span>
        </div>
        <div className="flex mt-6">
          <div className="flex gap-7">
            <div style={{ alignContent: "center" }}>Associated Group:</div>
            <div style={{ alignContent: "center" }}>
              {associatedGroups.map((group, idx) => (
                <Chip key={idx} label={group} className="mr-2 text-sm" />
              ))}
            </div>
            {associatedGroupsSidebar}
          </div>
          <Button
            rounded
            text
            icon="pi pi-pencil"
            className="text-blue-500"
            onClick={() => setVisible(true)}
          />
        </div>
      </div>
    </>
  );
};

export default BasicInfoTab;
