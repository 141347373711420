import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useEffect, useState, useRef } from "react";
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Checkbox } from 'primereact/checkbox';
import { useActiveAdUsers } from "context/ContextWrapper";
import "./index.css";
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { TieredMenu } from 'primereact/tieredmenu';
import { Chip } from 'primereact/chip';
import { InputNumber } from 'primereact/inputnumber';
import useFacts from "hooks/useFacts";
function AddChecklist({ checklist, setChecklist, checklistStage, setChecklistStage, visible, setVisible, toast, spaceId, fetchChecklist, setIsLoading }) {
    const { activeAdUsers } = useActiveAdUsers();
    const { createChecklist, removeChecklist, updateChecklist } = useFacts();
    const DefineChecklist = ({ checklist, setChecklist, toast }) => {
        const menu = useRef(null);
        const initialNewChecklist = {
            description: '',
            status: 'pending',
            parameters: [{
                name: "",
                conditionEnabled: false,
                conditions: [{ operator: "", value: "", statement: "" }],
                actionEnabled: false,
                trigger_actions: [{ action_type: "", details: { value: [] } }]
            }],
            default: false,
            default_actions: {
                action_type: "",
                details: { value: [] }
            },
            generateReport: 'manual',
            generateOn: null,
            generateDays: null,
            timezone: 'CST',
            template: false
        };
        const [visible, setVisible] = useState(true);
        const [selectedOption, setSelectedOption] = useState(null);
        const [searchTerm, setSearchTerm] = useState('');
        const [selectedChecklist, setSelectedChecklist] = useState(null);
        const [newChecklist, setNewChecklist] = useState(initialNewChecklist);
        const [showNewChecklistInput, setShowNewChecklistInput] = useState(false);
        const [deleteChecklist, setDeleteChecklist] = useState(null);
        const generateReportOptions = [
            { label: 'Generate on Manual trigger only', value: 'manual' },
            { label: 'Auto-generate at Time interval', value: 'autoInterval' },
            { label: 'Auto-generate at specific time', value: 'autoTime' },
            { label: 'Auto-generate every xx days', value: 'autoDays' },
            { label: 'Auto-generate when new file detected', value: 'autoFile' }
        ];

        const checklistOptions = [
            { label: 'Create New Checklist', value: 'create', command: () => handleChecklistOptionChange("create") },
            { label: 'Import Existing Template', value: 'import', command: () => handleChecklistOptionChange("import") }
        ];

        const operators = [
            { label: "Greater Than", value: "Greater than" },
            { label: "Less Than", value: "Less than" },
            { label: "Equals to", value: "Equals to" },
            { label: "Contains Phrases", value: "Contains Phrases" },
        ];

        useEffect(() => {
            if (selectedChecklist && deleteChecklist) {
                const timer = setTimeout(() => {
                    setDeleteChecklist(null);
                }, 3000);
                return () => clearTimeout(timer);
            }
        }, [deleteChecklist]);

        const handleChecklistOptionChange = (e) => {
            setSelectedOption(e);
            if (e === 'create') {
                setShowNewChecklistInput(true);
            } else {
                setShowNewChecklistInput(false);
            }
        };

        const handleCreateNewChecklist = async () => {
            if (newChecklist.name.trim()) {
                setIsLoading(true);
                setChecklistStage(null);
                const checklistItem = {
                    name: newChecklist.name,
                    description: newChecklist.description,
                    status: "pending",
                    space_id: spaceId
                };
                try {
                    const response = await createChecklist(checklistItem);
                    setChecklist([...checklist, checklistItem]);
                    setNewChecklist(initialNewChecklist);
                    setShowNewChecklistInput(false);
                    setSelectedOption(null);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Checklist created successfully', life: 3000 });
                    fetchChecklist();
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to create checklist', life: 3000 });
                } finally {
                    setIsLoading(false);
                }
            }
        };

        const handleCancel = () => {
            setShowNewChecklistInput(false);
            setSelectedOption(null);
        }

        const handleDeleteChecklist = async (spaceId, id) => {
            setIsLoading(true);
            setChecklistStage(null);
            setSelectedChecklist(null);
            setNewChecklist(initialNewChecklist);
            try {
                const response = await removeChecklist(spaceId, id);
                setChecklist(prev => prev.filter(item => item.id !== id));
                if (toast?.current) {
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Category deleted successfully', life: 3000 });
                }
            } catch (error) {
                if (toast?.current) {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete category', life: 3000 });
                }
            } finally {
                setIsLoading(false);
            }
        };

        const addParameter = () => {
            setNewChecklist({
                ...newChecklist,
                parameters: [...(newChecklist.parameters || []), {
                    name: "",
                    conditionEnabled: false,
                    conditions: [{ operator: "", value: "", statement: "" }],
                    actionEnabled: false,
                    trigger_actions: [{ action_type: "", details: { value: [] } }]
                }]
            });
        };

        const addCondition = (paramIndex) => {
            const updatedParameters = [...newChecklist.parameters];
            updatedParameters[paramIndex].conditions.push({ operator: "", value: "", statement: "" });
            setNewChecklist({ ...newChecklist, parameters: updatedParameters });
        };

        const addAction = (paramIndex) => {
            const updatedParameters = [...newChecklist.parameters];
            updatedParameters[paramIndex].trigger_actions.push({ action_type: "", details: { value: [] } });
            setNewChecklist({ ...newChecklist, parameters: updatedParameters });
        };

        const removeParameter = (paramIndex) => {
            const updatedParameters = newChecklist.parameters.filter((_, index) => index !== paramIndex);
            setNewChecklist({ ...newChecklist, parameters: updatedParameters });
        };

        const removeCondition = (paramIndex, condIndex) => {
            const updatedParameters = [...newChecklist.parameters];
            updatedParameters[paramIndex].conditions = updatedParameters[paramIndex].conditions.filter((_, index) => index !== condIndex);
            setNewChecklist({ ...newChecklist, parameters: updatedParameters });
        };

        const removeAction = (paramIndex, actionIndex) => {
            const updatedParameters = [...newChecklist.parameters];
            updatedParameters[paramIndex].trigger_actions = updatedParameters[paramIndex].trigger_actions.filter((_, index) => index !== actionIndex);
            setNewChecklist({ ...newChecklist, parameters: updatedParameters });
        };

        const updateParameter = (paramIndex, key, value) => {
            const updatedParameters = [...newChecklist.parameters];
            updatedParameters[paramIndex][key] = value;
            setNewChecklist({ ...newChecklist, parameters: updatedParameters });
        };

        const updateCondition = (paramIndex, condIndex, key, value) => {
            const updatedParameters = [...newChecklist.parameters];
            updatedParameters[paramIndex].conditions[condIndex][key] = value;
            setNewChecklist({ ...newChecklist, parameters: updatedParameters });
        };

        const updateAction = (paramIndex, actionIndex, key, value) => {
            const updatedParameters = [...newChecklist.parameters];
            if (updatedParameters[paramIndex] && updatedParameters[paramIndex].trigger_actions[actionIndex]) {
                updatedParameters[paramIndex].trigger_actions[actionIndex][key] = value;
                setNewChecklist({ ...newChecklist, parameters: updatedParameters });
            } else {
                console.error("Invalid parameter or action index");
            }
        };

        const handleUpdateChecklist = async (e) => {
            e.preventDefault();
            if (!selectedChecklist) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'No checklist selected for update', life: 3000 });
                return;
            }

            if (newChecklist?.parameters?.length === 0) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please add at least one parameter', life: 3000 });
                return;
            }

            if (newChecklist?.parameters?.conditionEnabled && newChecklist?.parameters?.conditions?.length === 0) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please add at least one condition', life: 3000 });
                return;
            }

            if (newChecklist?.parameters?.actionEnabled && newChecklist?.parameters?.trigger_actions?.length === 0) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please add at least one action', life: 3000 });
                return;
            }

            const filteredParameters = newChecklist?.parameters?.filter(param => param.name).map(param => {
                const paramData = { ...param };

                if (param.conditions?.some(cond => cond.operator)) {
                    paramData.conditions = param.conditions.map((cond, index) => {
                        if (index === 0) {
                            const { statement, ...rest } = cond;
                            return rest;
                        }
                        return cond;
                    });
                } else {
                    delete paramData.conditions;
                }

                if (param.trigger_actions?.some(action => action.action_type)) {
                    paramData.trigger_actions = param.trigger_actions.map(action => {
                        if (action.action_type === 'Raise Flag') {
                            const { details, ...rest } = action;
                            return { ...rest, details: {} };
                        }
                        return action;
                    });
                } else {
                    delete paramData.trigger_actions;
                }

                return paramData;
            });

            const data = {
                ...newChecklist,
                parameters: filteredParameters,
                space_id: spaceId,
                name: selectedChecklist.name
            };

            if (!newChecklist.default_actions.action_type) {
                delete data.default_actions;
            } else {
                if (newChecklist.default_actions.action_type === 'Raise Flag') {
                    const { details, ...rest } = newChecklist.default_actions;
                    data.default_actions = [{ ...rest, details: {} }];
                } else {
                    data.default_actions = [newChecklist.default_actions];
                }
            }

            try {
                const response = await updateChecklist(selectedChecklist.id, data);
                if (response && response.success) {
                    setChecklist(prev => prev.map(item => item.id === selectedChecklist.id ? { ...item, ...data } : item));
                    setChecklistStage(null);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: 'Checklist updated successfully', life: 3000 });
                    fetchChecklist();
                } else {
                    throw new Error('Update failed');
                }
            } catch (error) {
                console.error("Error updating checklist:", error);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update checklist', life: 3000 });
            } finally {
                setIsLoading(false);
            }
        };

        const footer = (
            <div className="flex align-items-center justify-content-between pt-3 border-top-1 border-gray-200 add-checklist-footer">
                <div className="flex inline-flex w-8">
                    <div className="flex inline-flex w-8 justify-content-start">
                        <p className="mr-3 w-6">Generate Checklist Report</p>
                        <Dropdown
                            value={newChecklist.generateReport}
                            options={generateReportOptions}
                            onChange={(e) => setNewChecklist({ ...newChecklist, generateReport: e.value })}
                            placeholder="Generate Checklist Report"
                            className="text-xs w-6"
                            appendTo="self"
                        />
                    </div>
                    <div className="flex inline-flex w-4">
                        {newChecklist.generateReport === 'autoTime' && (
                            <Dropdown
                                value={newChecklist.timezone}
                                onChange={(e) => setNewChecklist({ ...newChecklist, timezone: e.value })}
                                className="ml-4 w-6"
                                placeholder="CST"
                                options={["CST", "EST", "GMT", "UTC"]}
                                appendTo="self"
                            />
                        )}
                        {(newChecklist.generateReport === 'autoInterval' || newChecklist.generateReport === 'autoTime') && (
                            <Calendar
                                value={newChecklist.generateOn}
                                onChange={(e) => setNewChecklist({ ...newChecklist, generateOn: e.value })}
                                className="ml-4 w-6"
                                placeholder="hh:mm"
                                timeOnly
                            />
                        )}
                        {newChecklist.generateReport === 'autoDays' && (
                            <InputNumber
                                value={newChecklist.generateDays}
                                onChange={(e) => setNewChecklist({ ...newChecklist, generateDays: e.value })}
                                className="ml-4 w-6"
                                placeholder="days"
                            />
                        )}
                    </div>
                </div>
                <div className="flex inline-flex w-4 justify-content-end">
                    <Button onClick={() => {
                        setVisible(false);
                        setSelectedChecklist(null);
                        setNewChecklist(initialNewChecklist);
                        setChecklistStage(null);
                    }} label="Cancel" className="p-button-text hover:text-blue-500" />
                    <Button onClick={(e) => {
                        setChecklistStage(null);
                        setIsLoading(true);
                        handleUpdateChecklist(e);
                    }} label="Confirm" className="p-button-primary" />
                </div>
            </div>
        )

        return (
            <Dialog
                header={
                    <div className="flex flex-row">
                        <div className="w-3">
                            <span className="text-md font-semibold">Space Checklist</span>
                        </div>
                        <div className="w-9 ml-4 flex flex-column border-bottom-1 border-gray-200 pb-2">
                            <span className="text-md font-semibold">{selectedChecklist?.name}</span>
                            <span className="text-gray-500 text-xs font-normal mt-1">
                                {selectedChecklist?.description}
                            </span>
                        </div>
                    </div>
                }
                visible={visible}
                style={{ width: '80vw', height: '80vh' }}
                onHide={() => setVisible(false)}
                footer={footer}
            >
                <div className="flex h-full action-template-container">
                    <div className="w-3 border-right-1 border-gray-200 h-full pr-3 flex flex-column justify-content-between">
                        <div className="w-full checklist-container">
                            <IconField iconPosition="right" className="mb-3">
                                <InputIcon className="pi pi-search"> </InputIcon>
                                <InputText
                                    placeholder="Search Checklist"
                                    className="w-full"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)} />
                            </IconField>
                            <div className="checklist-container-scroll overflow-y-auto" style={{ height: 'calc(33vh)' }}>
                                {checklist.length > 0 && checklist.map((item) => (
                                    <div onClick={() => {
                                        setSelectedChecklist(item);
                                        const updatedItem = {
                                            ...item,
                                            parameters: item.parameters?.map(param => ({
                                                ...param,
                                                conditionEnabled: param.conditions?.length > 0,
                                                conditions: param.conditions?.length > 0 ? param.conditions : [{ statement: "", operator: "", value: "" }],
                                                trigger_actions: param.trigger_actions?.length > 0 ? param.trigger_actions : [{ action_type: "", details: { value: [] } }]
                                            })),
                                            defaultActionEnabled: item.default_actions?.length > 0,
                                            default_actions: {
                                                action_type: item?.default_actions?.length ? item.default_actions[0].action_type : "",
                                                details: {
                                                    value: item?.default_actions?.length ? item.default_actions[0].details.value : []
                                                }
                                            }
                                        };
                                        setNewChecklist(updatedItem);
                                    }}
                                        className={`px-2 py-0 flex align-items-center justify-content-between gap-2 cursor-pointer border-round-lg border-1 border-transparent hover:border-gray-200 mb-1 hover:shadow-2 transition-duration-300 hover:bg-blue-50 ${selectedChecklist?.name === item.name ? 'bg-blue-50 border-gray-200 shadow-2' : ''}`}>
                                        <div className="flex align-items-center gap-2">
                                            <i className={`pi ${item.status === 'active' ? 'text-green-500 pi-check' : 'text-yellow-500 pi-clock'}`}></i>
                                            <p className="text-sm font-light text-overflow-ellipsis overflow-hidden white-space-nowrap">{item.name}</p>
                                        </div>
                                        <div className="flex align-items-center gap-2">
                                            {/* <i className='pi pi-ellipsis-v text-gray-500 transition-opacity-300 w-4 text-right' style={{ display: selectedChecklist?.name === item.name && deleteChecklist === null ? 'block' : 'none' }} /> */}
                                            <i className='pi pi-trash text-gray-500 transition-opacity-300 w-4 text-right' style={{ display: selectedChecklist?.name === item.name && deleteChecklist === null ? 'block' : 'none' }} onClick={() => setDeleteChecklist(true)} />
                                            {deleteChecklist && selectedChecklist?.name === item.name && (
                                                <p className='text-xs font-semibold text-red-500 my-0 w-4 text-right' onClick={() => handleDeleteChecklist(spaceId, item.id)}>Confirm?</p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                                {showNewChecklistInput && (
                                    <div className="flex flex-column gap-2 py-0 mb-1">
                                        <InputText
                                            value={newChecklist.name}
                                            onChange={(e) => setNewChecklist({ ...newChecklist, name: e.target.value })}
                                            placeholder="Enter checklist name"
                                            className="w-full border-gray-200"
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    handleCreateNewChecklist();
                                                }
                                            }}
                                        />
                                        <p className="text-sm font-medium text-right mt-0"><span className="text-gray-500 mr-4 cursor-pointer" onClick={() => handleCancel()}>Cancel</span><span className="text-blue-500 cursor-pointer" onClick={() => handleCreateNewChecklist()}>Save</span></p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <TieredMenu model={checklistOptions} popup ref={menu} breakpoint="767px" />
                        <Button
                            className="mt-3 text-sm w-full border-gray-200 bg-transparent justify-content-center text-blue-500 font-semibold add-checklist-dropdown text-center"
                            onClick={(e) => menu.current.toggle(e)}
                            icon="pi pi-plus mr-2"
                            iconPos="left"
                        >
                            Add Checklist
                        </Button>
                    </div>
                    <div className="flex flex-column w-full px-3 w-9 overflow-auto">
                        {selectedChecklist ? (
                            <div className="flex flex-column">
                                <div className="flex align-items-center gap-2 justify-content-between">
                                    <span className="text-sm font-bold">Parameters</span>
                                    <span className="text-sm font-medium text-blue-500 cursor-pointer" onClick={addParameter}>+ Add New Parameter</span>
                                </div>
                                {newChecklist.parameters && newChecklist?.parameters?.length > 0 && newChecklist?.parameters?.map((parameter, paramIndex) => (
                                    <div key={paramIndex} className="parameter-container my-2 bg-gray-50 border-round-lg p-2 border-2 border-gray-100">
                                        <span className="text-sm font-light">Parameter</span>
                                        <InputText
                                            className="w-full my-2"
                                            placeholder="e.g. Age"
                                            value={parameter.name}
                                            onChange={(e) => updateParameter(paramIndex, "name", e.target.value)}
                                        />
                                        <div className="flex align-items-center my-2">
                                            <Checkbox
                                                inputId={`conditionalAction-${paramIndex}`}
                                                name="conditional"
                                                checked={parameter.conditionEnabled}
                                                onChange={(e) => updateParameter(paramIndex, "conditionEnabled", e.checked)}
                                            />
                                            <label htmlFor={`conditionalAction-${paramIndex}`} className="ml-2 text-sm font-light text-gray-500">Conditional action</label>
                                        </div>
                                        {parameter.conditionEnabled && (
                                            <div className="condition-container w-full">
                                                <p className="text-sm font-semibold">If following condition is met</p>
                                                {parameter?.conditions?.map((condition, condIndex) => (
                                                    <div key={condIndex} className="flex gap-2 w-full mb-0 ml-2 pt-2 align-items-center justify-content-end condition-row">
                                                        <div className={`${condIndex === 0 ? 'w-5' : 'w-2'} relative`}>
                                                            <div className="branch-line"></div>
                                                        </div>
                                                        {condIndex > 0 && (
                                                            <div className="w-3 branch-container text-right">
                                                                <label htmlFor="statement" className="font-light block mb-2 text-sm opacity-0">Or And</label>
                                                                <Dropdown
                                                                    options={[{ label: "AND", value: "and" }, { label: "OR", value: "or" }]}
                                                                    placeholder="AND"
                                                                    className="p-mr-2 branch-dropdown w-9"
                                                                    value={condition.statement}
                                                                    onChange={(e) => updateCondition(paramIndex, condIndex, "statement", e.value)}
                                                                />
                                                            </div>
                                                        )}
                                                        <div className={`w-3 ${condIndex === 0 && 'pl-1'}`}>
                                                            <label htmlFor="operator" className="font-light block mb-2 text-sm">Operator</label>
                                                            <Dropdown
                                                                value={condition.operator}
                                                                options={operators}
                                                                onChange={(e) => updateCondition(paramIndex, condIndex, "operator", e.value)}
                                                                className="p-mr-2 w-full condition-dropdown max-w-10rem"
                                                            />
                                                        </div>
                                                        <div className="w-3">
                                                            <label htmlFor="value" className="font-light block mb-2 text-sm">Set Value</label>
                                                            <InputText
                                                                value={condition.value}
                                                                onChange={(e) => updateCondition(paramIndex, condIndex, "value", e.target.value)}
                                                                placeholder="Set Value"
                                                                className="p-mr-2 w-full condition-dropdown"
                                                            />
                                                        </div>
                                                        <i className='pi pi-times text-gray-500 transition-opacity-300 w-1 text-right h-max cursor-pointer mr-4 mt-3' onClick={() => removeCondition(paramIndex, condIndex)} />
                                                    </div>
                                                ))}
                                                <div className="condition-row ml-2">
                                                    <Button label="Add Condition" iconPos="right" icon="pi pi-angle-down" className="p-button-text p-mb-3 text-sm text-blue-500" onClick={() => addCondition(paramIndex)} />
                                                </div>
                                            </div>
                                        )}
                                        {parameter.conditionEnabled && (
                                            <div className="action-container">
                                                <p className="text-sm font-light">Then Trigger Following Actions</p>
                                                {parameter?.trigger_actions?.map((action, actionIndex) => (
                                                    <div key={actionIndex} className="flex gap-2 w-full mb-2 ml-2">
                                                        <div className="w-6">
                                                            <label htmlFor="selectType" className="font-light block mb-2 text-sm">Select Type</label>
                                                            <Dropdown
                                                                options={[{ label: "Raise Flag", value: "Raise Flag" }, { label: "Pulse App Notification", value: "Pulse App Notification" }, { label: "Email", value: "Email" }, { label: "Submit as JSON", value: "Submit as JSON" }, { label: "Submit as CSV", value: "Submit as CSV" }, { label: "Trigger Workflow", value: "Trigger Workflow" }]}
                                                                value={action.action_type}
                                                                onChange={(e) => updateAction(paramIndex, actionIndex, "action_type", e.value)}
                                                                placeholder="Action Type"
                                                                className="p-mr-2 w-full"
                                                            />
                                                        </div>
                                                        <div className="w-6">
                                                            {(action.action_type === "Submit as JSON" || action.action_type === "Submit as CSV" || action.action_type === "Trigger Workflow") && (
                                                                <>
                                                                    <label htmlFor="details" className="font-light block mb-2 text-sm">Details</label>
                                                                    <InputText
                                                                        value={action.details.value}
                                                                        onChange={(e) => updateAction(paramIndex, actionIndex, "details", { value: e.target.value })}
                                                                        placeholder={action.action_type === "workflow" ? "Enter api" : "Enter an email or api"}
                                                                        className="p-mr-2 w-full"
                                                                    />
                                                                </>
                                                            )}
                                                            {(action.action_type === "Pulse App Notification" || action.action_type === "Email" || action.action_type === "") && (
                                                                <>
                                                                    <label htmlFor="details" className="font-light block mb-2 text-sm">Details</label>
                                                                    <MultiSelect
                                                                        options={activeAdUsers?.length ? activeAdUsers.map(user => ({
                                                                            label: user.displayName,
                                                                            value: user.userPrincipalName
                                                                        })) : [{ label: "Loading...", value: null }]}
                                                                        value={action.details.value}
                                                                        onChange={(e) => updateAction(paramIndex, actionIndex, "details", { value: e.value })}
                                                                        placeholder="Select Users"
                                                                        className="p-mr-2 w-full"
                                                                        fixedPlaceholder={true}
                                                                        showClear
                                                                        filter
                                                                    />
                                                                    {action.details?.value?.map((user) => (
                                                                        <Chip
                                                                            key={user}
                                                                            label={user}
                                                                            className="text-sm mt-2"
                                                                            removable
                                                                        />
                                                                    ))}
                                                                </>
                                                            )}
                                                        </div>
                                                        <i className='pi pi-times h-full text-gray-500 transition-opacity-300 w-1 text-right cursor-pointer mr-4 mt-5' onClick={() => removeAction(paramIndex, actionIndex)} />
                                                    </div>
                                                ))}
                                                <Button label="Add Action" iconPos="right" icon="pi pi-angle-down" className="p-button-text p-mb-3 text-sm text-blue-500" onClick={() => addAction(paramIndex)} />
                                            </div>
                                        )}
                                        <div className="flex align-items-center my-2 justify-content-end gap-2">
                                            <Button label="Cancel" className="p-button-text text-blue-500" onClick={() => removeParameter(paramIndex)} />
                                            <Button label="Add" className="p-button-primary py-2" outlined />
                                        </div>
                                    </div>
                                ))}
                                {newChecklist.parameters && newChecklist?.parameters?.length > 0 && (
                                    <div className="default-action-container my-2">
                                        <div className="flex align-items-center mb-3">
                                            <Checkbox
                                                inputId="default_actions"
                                                name="default_actions"
                                                checked={newChecklist.defaultActionEnabled}
                                                onChange={(e) => setNewChecklist({ ...newChecklist, defaultActionEnabled: e.checked })}
                                            />
                                            <div className="flex flex-column ml-2">
                                                <label htmlFor="default_actions" className="text-sm font-bold text-gray-500">Default Action</label>
                                                <span className="text-gray-500 text-xs font-normal">Following actions will be triggered whenever a new checklist report is generated.</span>
                                            </div>
                                        </div>
                                        {newChecklist.defaultActionEnabled && (
                                            <div className="flex flex-auto ml-2 gap-2 w-full">
                                                <div className="w-6">
                                                    <label htmlFor="selectType" className="font-light block mb-2 text-sm">Select Type</label>
                                                    <Dropdown
                                                        options={[{ label: "Raise Flag", value: "Raise Flag" }, { label: "Pulse App Notification", value: "Pulse App Notification" }, { label: "Email", value: "Email" }, { label: "Submit as JSON", value: "Submit as JSON" }, { label: "Submit as CSV", value: "Submit as CSV" }, { label: "Trigger Workflow", value: "Trigger Workflow" }]}
                                                        placeholder="Select"
                                                        className="w-full"
                                                        value={newChecklist.default_actions.action_type}
                                                        onChange={(e) => setNewChecklist({ ...newChecklist, default_actions: { ...newChecklist.default_actions, action_type: e.value, details: [] } })}
                                                    />
                                                </div>
                                                <div className="w-6">
                                                    {(newChecklist.default_actions.action_type === "Submit as JSON" || newChecklist.default_actions.action_type === "Submit as CSV" || newChecklist.default_actions.action_type === "Trigger Workflow") && (
                                                        <>
                                                            <label htmlFor="details" className="font-light block mb-2 text-sm">Details</label>
                                                            <InputText
                                                                value={newChecklist.default_actions.details.value}
                                                                onChange={(e) => setNewChecklist({ ...newChecklist, default_actions: { ...newChecklist.default_actions, details: { value: e.target.value } } })}
                                                                placeholder={newChecklist.default_actions.action_type === "workflow" ? "Enter api" : "Enter an email or api"}
                                                                className="p-mr-2 w-full"
                                                            />
                                                        </>
                                                    )}
                                                    {(newChecklist.default_actions.action_type === "Pulse App Notification" || newChecklist.default_actions.action_type === "Email" || newChecklist.default_actions.action_type === "") && (
                                                        <>
                                                            <label htmlFor="details" className="font-light block mb-2 text-sm">Details</label>
                                                            <MultiSelect
                                                                options={activeAdUsers?.length ? activeAdUsers.map(user => ({
                                                                    label: user.displayName,
                                                                    value: user.userPrincipalName
                                                                })) : [{ label: "Loading...", value: null }]}
                                                                value={newChecklist.default_actions.details.value}
                                                                onChange={(e) => setNewChecklist({ ...newChecklist, default_actions: { ...newChecklist.default_actions, details: { value: e.value } } })}
                                                                placeholder="Select Users"
                                                                className="p-mr-2 w-full"
                                                                fixedPlaceholder={true}
                                                                showClear
                                                                filter
                                                            />
                                                            {newChecklist.default_actions.details?.value?.map((user) => (
                                                                <Chip
                                                                    key={user}
                                                                    label={user}
                                                                    className="text-sm mt-2"
                                                                    removable
                                                                />
                                                            ))}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="text-center h-full flex flex-column justify-content-center align-items-center">
                                <h4 className="mb-1">No Checklist</h4>
                                <p>Start by adding a checklist</p>
                            </div>)}
                    </div>
                </div>
            </Dialog>
        );
    }

    const CheckListPanel = () => {
        return (
            <Dialog
                header={
                    <div className="flex flex-column">
                        <span className='text-md font-semibold'>Factsheet</span>
                        <span className='text-gray-500 text-sm font-normal mt-1'>Choose how you want to categorize your documents</span>
                    </div>
                }
                visible={visible}
                style={{ width: '50vw' }}
                onHide={() => {
                    setChecklistStage(null);
                }}
            >
                <div className='flex flex-col gap-2 justify-content-center my-5 border-round-lg px-2 w-9 mx-auto'>
                    <div
                        className='border-1 border-gray-200 border-round-md cursor-pointer p-4 transition-duration-300 hover:shadow-2 hover:border-blue-500'
                        onClick={() => {
                            // setChecklistStage('auto');
                        }}
                    >
                        <h4 className='text-md font-semibold'>Auto Fact Extraction</h4>
                        <p>Let AI automatically define the Factsheet based on the documents</p>
                    </div>
                    <div
                        className='border-1 border-gray-200 border-round-md cursor-pointer p-4 transition-duration-300 hover:shadow-2 hover:border-blue-500'
                        onClick={() => {
                            setChecklistStage('define');
                        }}
                    >
                        <h4 className='text-md font-semibold'>Define Your Own Checklist</h4>
                        <p>Define the checklist manually, and let the AI handle the rest.</p>
                    </div>
                </div>
            </Dialog>
        );
    };

    return (
        checklist.length === 0 && checklistStage === null ? (
            <div className="w-full h-full flex justify-content-center items-center">
                <div className="flex flex-column gap-2 align-items-center justify-content-center">
                    <p>No Checklist</p>
                    <Button
                        onClick={() => setChecklistStage('create')}
                        label="Create Checklist"
                        severity="info"
                        className="font-light"
                        icon="pi pi-list"
                        iconPos="right"
                        outlined
                    />
                </div>
            </div>
        ) : checklistStage === 'create' ? (
            <CheckListPanel />
        ) : checklistStage === 'define' ? (
            <DefineChecklist checklist={checklist} setChecklist={setChecklist} toast={toast} />
        ) : (
            <p></p>
        )
    );
}

export default AddChecklist;