import useApi from "services/api";
import { API_URL } from "../constants";

const GroupsList = () => {
  const { data, loading, error, post } = useApi();

  const createGroup = async (params) => {
    const data = await post(`${API_URL}/intelliapp/groups`, params);
    return data;
  };

  return {
    createGroup,
    data,
    loading,
    error,
  };
};

export default GroupsList;
