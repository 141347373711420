import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import React, { useRef, useState, useEffect } from "react";
import "./index.css";
import { MultiSelect } from "primereact/multiselect";
import { Chip } from "primereact/chip";
import useSpaces from "../../../hooks/useSpace";

const Checklist = ({ existingChecklistLayout, checklistOptions, selectedCategory, toast, spaceId, onSave, data, setAppliedCategories, checklistAllDocuments, setChecklistAllDocuments, updateChecklist, editMode, setEditMode }) => {
  const checklistRef = useRef(null);
  const [hasCalledLayout, setHasCalledLayout] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const newAppliedCategories = [...new Set(
      checklistAllDocuments.flatMap(checklistId => {
        const checklist = checklistOptions.find(opt => opt.id === checklistId);
        if (!checklist?.categories) return [];
        return checklist.categories.map(categoryId => {
          const category = data.find(cat => cat.id === categoryId);
          return category?.name;
        }).filter(Boolean);
      })
    )].map(name => `"${name}"`).join(", ");
    setAppliedCategories(newAppliedCategories);
  }, [checklistAllDocuments, checklistOptions, data]);

  return (
    <div className="flex flex-column justify-content-between h-full">
      {!editMode && (
        <>
          <div className="flex flex-column gap-4">
            <p className="p-0 m-0 text-md w-3">
              Checklist
            </p>
            {selectedCategory?.checklist_ids?.length > 0 && (
              <div className="flex gap-2 flex-wrap">
                {selectedCategory?.checklist_ids?.map((checklist, index) => (
                  <Chip key={index} label={checklistOptions.find((option) => option.id === checklist)?.name} className="text-xs font-light" />
                ))}
              </div>
            )}
          </div>
          <div className="flex justify-content-end align-items-baseline">
            <Button
              icon="pi pi-pencil"
              className="bg-transparent border-blue-500 text-blue-500 mb-2 border-round-lg hover:bg-blue-500 hover:text-white"
              onClick={() => {
                setEditMode(true);
              }}
            />
          </div>
        </>
      )}

      {editMode && (
        <>
          <div className="flex flex-col gap-2 mb-2">
            <label htmlFor="categoryChecklist" className="mt-2 w-3">
              Add Checklist
            </label>
            <div className="w-9">
              <MultiSelect
                value={checklistAllDocuments}
                onChange={(e) => setChecklistAllDocuments(e.value)}
                options={checklistOptions}
                optionLabel="name"
                optionValue="id"
                placeholder="Add Checklist"
                fixedPlaceholder={true}
                className="w-full"
                filter
              />
              <div className="w-full my-3">
                {checklistAllDocuments?.map((item) => (
                  <Chip
                    key={item}
                    label={checklistOptions.find((option) => option.id === item)?.name}
                    className={`text-sm mt-1 mr-2 ${checklistOptions.find((option) => option.id === item)?.categories?.length > 0 && "warning-chip"}`}
                    removable
                    onRemove={() => {
                      setChecklistAllDocuments((prev) => prev.filter((docId) => docId !== item));
                    }}
                  />
                ))}
              </div>
              {!hasCalledLayout && checklistAllDocuments?.some((item) => checklistOptions.find((option) => option.id === item)?.categories?.length > 0) && (
                existingChecklistLayout() || setHasCalledLayout(true)
              )}
            </div>
          </div>
          {/* <div className="flex justify-content-end mt-4 gap-3">
            <Button icon="pi pi-times" size="small" outlined className="border-0" onClick={() => {
              setEditMode(false);
              setChecklistAllDocuments(selectedCategory?.checklist_ids || []);
            }} />
            <Button icon="pi pi-check" size="small" outlined onClick={updateChecklist} loading={loading} />
          </div> */}
        </>
      )}
    </div>
  );
};

export default Checklist;
