import React, { useState, useEffect } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header/Header";
import SidebarComponent from "./Sidebar/SideBar";
import {
  DashboardNavigationMap,
  DashboardmenuItems,
  SettingsMenuItems,
} from "constants/SidebarConfig";
import { useNavigate } from "react-router-dom";
import useMediaQuerry from "../hooks/useMediaQuerry";
import "./MainLayout.css";
import { useKeycloak } from "@react-keycloak/web";
import { useActivePage } from "context/ContextWrapper";
import InnerSidebar from "./InnerSidebar";

function MainLayout() {
  const { keycloak } = useKeycloak();
  const { authenticated } = keycloak;
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile, isLandScape } = useMediaQuerry();
  const { activePage, setActivePage } = useActivePage();
  const [selectedSettingsItem, setSelectedSettingsItem] = useState("");
  const [selectedItem, setSelectedItem] = useState(() => {
    // Determine initial selected item based on current path
    const path = location.pathname;
    if (path.includes("/spaces")) return "space";
    if (path.includes("/pulse")) return "pulse";
    if (path.includes("/source")) return "source";
    if (path.includes("/agent")) return "agent";
    if (path.includes("/chat")) return "chat";
    if (path.includes("/settings")) return "settings";
    return "space"; // default
  });
  const innerSidebarContent = ["space", "pulse", "source"];

  const handleClick = (icon) => {
    setSelectedItem(icon);

    if (DashboardNavigationMap[icon]) {
      navigate(DashboardNavigationMap[icon]);
    }
  };

  useEffect(() => {
    // Only navigate if we're on the root path
    if (location.pathname === "/") {
      handleClick(selectedItem);
    }
  }, []);

  // Update selected item when location changes
  useEffect(() => {
    const path = location.pathname;
    if (path.includes("/spaces/")) setSelectedItem("space");
    else if (path.includes("/pulse/")) setSelectedItem("pulse");
    else if (path.includes("/source/")) setSelectedItem("source");
    else if (path.includes("/agent/")) setSelectedItem("agent");
    else if (path.includes("/chat/")) setSelectedItem("chat");
    else if (path.includes("/settings/")) setSelectedItem("settings");
  }, [location.pathname]);

  const menuItemsConfig = DashboardmenuItems.map((item) => ({
    ...item,
    icon: item.icon(selectedItem),
    command: () => {
      const lowerCaseLabel = item.label.toLowerCase();
      setSelectedItem(lowerCaseLabel);
      handleClick(lowerCaseLabel);
      setActivePage(lowerCaseLabel);
    },
  }));

  const settingsItemsConfig = SettingsMenuItems.map((item) => {
    const lowerCaseLabel = item.label.toLowerCase();
    return {
      ...item,
      icon: item.icon(selectedSettingsItem),
      command: () => {
        setSelectedItem("settings");
        setSelectedSettingsItem(lowerCaseLabel);
        navigate(item.path);
      },
    };
  });

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {authenticated ? (
        <div className="layout main">
          <div className="grid grid-nogutter contentAreaLayout">
            <div className="col-fixed max-w-5rem" style={{ width: "5rem" }}>
              <SidebarComponent
                menuItems={menuItemsConfig}
                selectedItem={selectedItem}
                settingsItemsConfig={settingsItemsConfig}
                selectedSettingsItem={selectedSettingsItem}
                setSelectedItem={setSelectedItem}
                setSelectedSettingsItem={setSelectedSettingsItem}
                view="dashboard"
              />
            </div>
            {innerSidebarContent.includes(activePage) && (
              <div className="col-fixed">
                <InnerSidebar menuOption={activePage} />
              </div>
            )}
            <div
              className={`main-content ${isMobile || isLandScape ? "" : "overflow-hidden"} h-screen col flex flex-column`}
            >
              <Header menuItems={menuItemsConfig} selectedItem={selectedItem} view="dashboard" />
              <div className="contentArea flex-grow-1 overflow-auto h-full">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-content-center h-screen align-items-center">
          <ProgressSpinner />
        </div>
      )}
    </div>
  );
}

export default MainLayout;
