import useApi from "services/api";
import { API_URL } from "../constants";

const useTaskApplyFilters = () => {
  const { get, data } = useApi();

  const applyFilters = async (body) => {
    const queryParams = new URLSearchParams();
    Object.entries(body).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryParams.append(key, value);
      }
    });

    const queryString = queryParams.toString();
    const url = `${API_URL}/summary_jobs${queryString ? `?${queryString}` : ""}`;
    const data = await get(url);
    return data;
  };

  return { applyFilters, data };
};

export default useTaskApplyFilters;
