import neioAgentImage from "../assets/Agents/neio-agent.png";
import documentSynthesisImage from "../assets/Agents/document-synthesization.png";

export const AgentsList = [
  {
    id: 1,
    name: "NeIO Agent",
    description:
      "The NeIO Agent AI streamlines recruitment, onboarding, and employee management through advanced machine learning and automation.",
    tags: ["test", "test2"],
    buttonText: "Chat with Me",
    buttonLink: "https://neio.ai/neiogpt/workspace/all",
    image: neioAgentImage,
  },
];

export const IntelliAppList = [
  {
    id: 1,
    name: "Document Synthesization",
    description:
      "Generate professional documents instantly with customizable templates using our AI-powered app. Save time and ensure consistency for all your document needs.",
    tags: [],
    buttonText: "Get Started",
    buttonLink: "/intelliapp/workspaces",
    image: documentSynthesisImage,
  },
];
