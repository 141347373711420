import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

export default function NotFound() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/intelliapp/workspaces');
    }, [navigate]);
    return <></>;
}
