import React, { useState, useRef, Fragment, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { IconField } from "primereact/iconfield";
import { Tooltip } from "primereact/tooltip";
import { InputIcon } from "primereact/inputicon";
import "./CreditSummary.css";
import useCreditSummary from "hooks/useCreditSummary";
import { Checkbox } from "primereact/checkbox";
import { FilterMatchMode } from "primereact/api";
import { useNavigate, useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/utils";
import LowCredits from "assets/CreditOverview/LowCredits.svg";
import NoCredit from "assets/CreditOverview/NoCredit.svg";
import { Paginator } from "primereact/paginator";
import ManageAllocationSidebar from "components/Dashboard/UserManagement/ManageAllocationSidebar";

export default function CreditSummaryPanel() {
  const toast = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const [expandedRows, setExpandedRows] = useState({});
  const { getCreditSummary, loading } = useCreditSummary();
  const [creditSummary, setCreditSummary] = useState(null);
  const [creditPagination, setCreditPagination] = useState(null);
  const [filters, setFilters] = useState({
    email: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      email: { value: "", matchMode: FilterMatchMode.CONTAINS },
      allocation_type: { value: "", matchMode: FilterMatchMode.EQUALS },
    },
  });
  const [manageCreditUserId, setManageCreditUserId] = useState(null);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _lazyState = { ...lazyState };

    _lazyState.filters.email.value = value;
    _lazyState.first = 0;
    _lazyState.page = 1;

    setLazyState(_lazyState);
    setGlobalFilterValue(value);
  };

  const creditsAllocated = (rowData) => {
    const { last_credits_allotted } = rowData;
    const allocated =
      last_credits_allotted === 0 ? last_credits_allotted : `+${last_credits_allotted}`;
    return allocated;
  };

  const fetchCreditSummary = async () => {
    const { page, rows, filters } = lazyState;
    const params = {
      page,
      page_size: rows,
      email: filters.email.value,
      allocation_type: filters.allocation_type.value,
    };
    const data = await getCreditSummary(params);
    if (data.results.data) {
      setCreditSummary(data.results.data);
      setCreditPagination(data.results.pagination);
    }
  };

  useEffect(() => {
    fetchCreditSummary();
  }, [lazyState]);

  const selectAllocateTypeOptions = [
    { name: "One-time", code: "one_time" },
    { name: "Recurring", code: "recurring" },
    { name: "Unlimited", code: "unlimited" },
  ];

  const onAllocationTypeChange = (e) => {
    let _lazyState = { ...lazyState };
    _lazyState.filters.allocation_type.value = e.value.code;
    _lazyState.first = 0;
    _lazyState.page = 1;
    setLazyState(_lazyState);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between w-full">
        <div className="flex justify-content-start">
          <Button
            type="button"
            icon="pi pi-refresh"
            // label={`${!isMobile ? "Filter" : ""}`}
            className={"p-button-filter mr-3 px-4 border-1 text-sm text-blue-500"}
            outlined
            iconPos="right"
            onClick={() => {
              // Navigate away
              navigate("/credits", { replace: true });

              // Then navigate back
              setTimeout(() => {
                const targetPath = location.pathname + "#";
                console.log("Navigating back to:", targetPath);
                navigate(targetPath, { replace: true });
              }, 0);
              // const timestamp = new Date().getTime();
              // navigate(`${location.pathname}?t=${timestamp}`, { replace: true });
            }}
          />
        </div>

        <div className="flex justify-content-end gap-2">
          <Dropdown
            options={selectAllocateTypeOptions}
            optionLabel="name"
            name="outputFormat"
            placeholder="Allocation Type"
            className="w-6"
            value={{
              name:
                lazyState?.filters?.allocation_type.value === "one_time"
                  ? "One-time"
                  : capitalizeFirstLetter(lazyState?.filters?.allocation_type.value),
              code: lazyState?.filters?.allocation_type.value,
            }}
            onChange={onAllocationTypeChange}
          />
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search text-sm" />
            <InputText
              value={globalFilterValue}
              className="text-sm h-2"
              onChange={onGlobalFilterChange}
              placeholder="Search user Email"
            />
          </IconField>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const handleExpandClick = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const handlePageChange = (event) => {
    const { rows: currRows, page, first: currFirst } = event;
    console.log(currRows, page, currFirst);

    let _lazyState = { ...lazyState };
    _lazyState.first = currFirst;
    _lazyState.rows = currRows;
    _lazyState.page = parseInt(page) + 1;
    setLazyState(_lazyState);
  };

  const actionCredit = (rowData) => {
    const moreMenu = (
      <Button
        label="Manage"
        className="text-blue-500"
        rounded
        text
        aria-label="Filter"
        onClick={() => {
          setManageCreditUserId(rowData.id);
        }}
      />
    );

    return <div className="text-right text-xs">{moreMenu}</div>;
  };

  const createdAtCredit = (rowData) => {
    const { last_credits_allotted_at } = rowData;
    const timestamp = moment(last_credits_allotted_at).format("DD/MM/YYYY");

    const credit = <span className="text-xs text-color-secondary mt-1">{timestamp}</span>;
    return credit;
  };

  const creditCheckStatus = (rowData) => {
    const { low_credit_alert, allocation_type } = rowData;
    return (
      <Checkbox
        onChange={() => {}}
        checked={allocation_type !== "unlimited" && low_credit_alert}
        disabled={allocation_type === "unlimited"}
      ></Checkbox>
    );
  };

  const formatAllocationType = (rowData) => {
    const { allocation_type } = rowData;
    return allocation_type === "one_time" ? "One Time" : capitalizeFirstLetter(allocation_type);
  };

  const creditBalance = (rowData) => {
    const { credit_balance, allocation_type } = rowData;
    return (
      <div className="inline-flex">
        {allocation_type !== "unlimited" && <div className="mr-1">{credit_balance}</div>}
        {allocation_type !== "unlimited" ? (
          credit_balance === 0 ? (
            <span className="inline-flex items-center justify-center w-6 h-6 ">
              <img
                src={NoCredit}
                alt="empty"
                data-pr-tooltip="Credit Exhausted"
                className="w-full h-full no-credit"
              />
              <Tooltip target=".no-credit" position="top" />
            </span>
          ) : credit_balance < 50 ? (
            <span className="inline-flex items-center justify-center w-6 h-6 ">
              <img
                src={LowCredits}
                data-pr-tooltip="Low Credits"
                alt="empty"
                className="w-full h-full low-credit"
                data-pr-tooltip="Low Credits"
              />
              <Tooltip target=".low-credit" position="top" />
            </span>
          ) : (
            ""
          )
        ) : (
          "Unlimited"
        )}
      </div>
    );
  };

  return (
    <Fragment>
      {!!manageCreditUserId && (
        <ManageAllocationSidebar
          isVisible={!!manageCreditUserId}
          setVisible={setManageCreditUserId}
          onHide={(shouldRefreshData) => {
            setManageCreditUserId(null);
            if (shouldRefreshData) {
              fetchCreditSummary();
            }
          }}
          userId={manageCreditUserId}
          toast={toast}
        />
      )}
      <Toast ref={toast} />
      <div className="card my-5 creditSummaryTable" style={{ height: "70vh" }}>
        <div className="text-2xl font-medium mb-3">User Credit Summary</div>
        <DataTable
          value={creditSummary}
          rowsPerPageOptions={[5, 10, 20]}
          rows={10}
          loading={loading}
          tableStyle={{ minWidth: "50rem" }}
          filters={filters}
          header={header}
          emptyMessage="No credit found"
          onFilter={(e) => setFilters(e.filters)}
          className="w-full"
          scrollable
          scrollHeight="flex"
          dataKey="id"
          selectionMode={"checkbox"}
        >
          {/* <Column field="id" selectionMode="multiple" style={{ width: "5%" }}></Column> */}
          <Column field="email" header="User Email" style={{ width: "8%" }}></Column>
          <Column
            field="credit_balance"
            header="Credit Balance"
            // sortable
            style={{ width: "14%" }}
            body={creditBalance}
          ></Column>
          <Column
            field="low_credit_alert"
            header="Low Credit Alert"
            style={{ width: "14%" }}
            body={creditCheckStatus}
          ></Column>
          <Column
            field="last_credits_allotted"
            header="Last Allocated Credits"
            body={creditsAllocated}
            style={{ width: "16%" }}
          ></Column>
          <Column
            field="last_credits_allotted_at"
            header="Last Credit Allocation Date"
            style={{ width: "18%" }}
            body={createdAtCredit}
          ></Column>
          <Column
            field="allocation_type"
            header="Allocation Type"
            style={{ width: "13%" }}
            body={formatAllocationType}
          ></Column>
          <Column
            field="action"
            header="Action"
            style={{ width: "10%" }}
            body={(rowData) => actionCredit(rowData)}
          ></Column>
        </DataTable>
        <Paginator
          first={lazyState.first}
          rows={creditPagination?.page_size}
          template={{
            layout:
              "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
          }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          totalRecords={creditPagination?.total}
          rowsPerPageOptions={[10, 20, 30]}
          onPageChange={handlePageChange}
        ></Paginator>
      </div>
    </Fragment>
  );
}
