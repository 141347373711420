import React, { useState, createContext, useContext } from "react";
import { UploadProgressProvider } from "./uploaderContext";
import { UploadedFilesProvider } from "./upladedFilesContext";
import UserInfoProvider from "./UserInfoContext";
import { UserStatusProvider } from "./userStatusContext";

const SharedStateContext = createContext();

const AppContextProvider = ({ children }) => {
  const [sharedState, setSharedState] = useState("");
  const [refresh, setRefresh] = useState(false);

  return (
    <SharedStateContext.Provider value={{ sharedState, setSharedState, refresh, setRefresh }}>
      <UploadProgressProvider>
        <UserInfoProvider>
          <UserStatusProvider>
            <UploadedFilesProvider>{children}</UploadedFilesProvider>
          </UserStatusProvider>
        </UserInfoProvider>
      </UploadProgressProvider>
    </SharedStateContext.Provider>
  );
};

export const useSharedState = () => useContext(SharedStateContext);

export const useRefresh = () => {
  const { refresh, setRefresh } = useSharedState();
  return { refresh, setRefresh };
};

export default AppContextProvider;
