import "./Pdf.css";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import useMediaQuerry from "../../../hooks/useMediaQuerry";
import CustomPDFRenderer from "./CustomPDFRenderer";
import React, { useEffect } from "react";

const PDFViewerComponent = ({ fileUrl, fileName, onClose }) => {
  const docs = [{ uri: fileUrl }];
  const fileNameArr = fileName.split(".");
  const fileType = fileNameArr[fileNameArr.length - 1];
  const { isMobile } = useMediaQuerry();

  useEffect(() => {
    return () => {
      // Cleanup code here
      onClose?.();
    };
  }, [onClose]);

  if (!fileUrl) {
    return null;
  }

  return (
    <div className="pdf-overlay">
      <div className={`${isMobile ? "content-mobile" : "content-desktop"} pdf-overlay-content`}>
        <button className="pdf-overlay-close" onClick={onClose}>
          X
        </button>
        {fileType === "docx" || fileType === "doc" ? (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={docs}
            style={{ height: "98%", marginTop: "12px" }}
          />
        ) : (
          <DocViewer
            documents={docs}
            pluginRenderers={[CustomPDFRenderer, ...DocViewerRenderers]}
            style={{ height: "98%", marginTop: "12px" }}
          />
          // <iframe title="PDF Viewer" src={fileUrl} className="w-full h-full  " />
        )}
      </div>
    </div>
  );
};

export default React.memo(PDFViewerComponent);
