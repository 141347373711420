import React, { useState } from 'react'
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import './Disclaimer.css';

function Disclaimer() {
    const [open, setOpen] = useState(true);
    const items = [
        'SettlementDemand.ai is currently in beta release and accessible only to registered users. Interested users, please fill out the form at settlementdemand.ai/contact. Early access is available exclusively for organizations with Microsoft SSO.',
        'You can easily generate Settlement Demands in our standard format.If you prefer to use a custom template, simply upload your templates in the Template module, and we’ll process them to make them available for your use.',
        'For optimal quality, we recommend uploading documents with a maximum of 350 pages per task.Uploads beyond this limit may result in drafts of reduced quality & accuracy.',
        'The outputs are AI-generated. We advise you to manually review all content for factual accuracy, as we are still in the testing phase. Your feedback is invaluable to us — please share it at settlementdemand.ai/contact.',
    ];
    return (
        <Dialog
            className="login-disclaimer"
            visible={open}
            onHide={() => setOpen(false)}
            style={{ width: '75vw' }}
            showHeader={false}
            contentClassName="p-0 welcome-container border-round-lg"
        >
            <div className="pl-2 pr-4 py-2 flex justify-content-center align-items-center">
                <div className="w-full md:w-3/4 mx-auto">
                    <div
                        className="flex flex-column gap-2 h-full"
                        style={{ transition: "opacity 0.3s ease-in-out", opacity: 1 }}
                    >
                        <div className="flex justify-content-center">
                            <h1 className="text-2xl text-center">Note</h1>
                        </div>
                        <div className="flex text-gray-500 font-light text-sm">
                            <ol className='mb-0'>
                                {items.map((item, index) => (
                                    <li key={index} className="mb-3">{item}</li>
                                ))}
                            </ol>
                        </div>
                        <div className="flex justify-content-center text-gray-500 text-sm font-light">
                            <p>Thank you for participating in our beta launch and helping us improve!</p>
                        </div>
                    </div>
                    <div className="flex justify-content-center mt-3 gap-5 xs:mb-1 sm:mb-2 xl:mb-4">
                        <Button
                            pt={{
                                label: {
                                    className: "font-medium",
                                },
                            }}
                            label="Understood"
                            className="bg-primary text-white w-5 xl:w-4"
                            onClick={() => setOpen(false)}
                        />
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default Disclaimer