// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container-desktop {
    margin-top: 1rem;
    bottom: 8px;
        background-color: white;
        width: 18rem;
}

.pagination-container-mobile {
    margin-top: 1rem;
    bottom: 5rem;
    background-color: white;
}

.page-info {
    margin: 0 0.5rem;
    font-size: 0.9rem;
    color: #6c757d;
}

.p-button.p-button-text {
    color: #6c757d;
}

.p-button.p-button-text:enabled:hover {
    background: rgba(108, 117, 125, 0.04);
    color: #6c757d;
}

.p-button.p-button-text:enabled:active {
    background: rgba(108, 117, 125, 0.16);
    color: #6c757d;
}

.p-button.p-button-text:disabled {
    color: #ced4da;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;QACP,uBAAuB;QACvB,YAAY;AACpB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qCAAqC;IACrC,cAAc;AAClB;;AAEA;IACI,qCAAqC;IACrC,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".pagination-container-desktop {\n    margin-top: 1rem;\n    bottom: 8px;\n        background-color: white;\n        width: 18rem;\n}\n\n.pagination-container-mobile {\n    margin-top: 1rem;\n    bottom: 5rem;\n    background-color: white;\n}\n\n.page-info {\n    margin: 0 0.5rem;\n    font-size: 0.9rem;\n    color: #6c757d;\n}\n\n.p-button.p-button-text {\n    color: #6c757d;\n}\n\n.p-button.p-button-text:enabled:hover {\n    background: rgba(108, 117, 125, 0.04);\n    color: #6c757d;\n}\n\n.p-button.p-button-text:enabled:active {\n    background: rgba(108, 117, 125, 0.16);\n    color: #6c757d;\n}\n\n.p-button.p-button-text:disabled {\n    color: #ced4da;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
