import useApi from "services/api";
import useUploaderApi from "services/uploaderApi";
import { API_URL } from "../constants";
import moment from "moment";

/**
 * Custom hook for managing space-related API operations.
 * Provides functions to fetch and delete spaces.
 *
 * @returns {Object} An object containing functions to interact with spaces API
 */
const useSpaces = () => {
  const { patch, post, get, del, put } = useApi();
  const { put: uploaderPut } = useUploaderApi();

  /**
   * Fetches spaces from the server with optional pagination, search, sorting, and filtering.
   *
   * @param {number} page - The page number to fetch
   * @param {number} limit - The number of items per page
   * @param {string} search - Search query to filter spaces by name
   * @param {string} sortBy - Field to sort the results by
   * @param {string} sortOrder - Order of sorting (asc/desc)
   * @param {Object} filters - Additional filters to apply
   * @returns {Promise<Object>} A promise that resolves to the fetched spaces data
   */
  const getSpaces = async (page, limit, search, sortBy, sortOrder, filters) => {
    let url = `${API_URL}/spaces`;
    const params = { page, limit };

    if (search) {
      params.filter = { name: search };
    }

    if (filters?.created_at?.length) {
      //
      const fromDate = moment(filters.created_at[0]).startOf("day").utc().toISOString();
      const toDate = moment(filters.created_at[1] || filters.created_at[0])
        .endOf("day")
        .utc()
        .toISOString();

      params.filter = { ...(params.filter || {}), created_at: [fromDate, toDate] };
    }

    if (filters?.created_by && filters.created_by?.length > 0) {
      params.filter = { ...(params.filter || {}), created_by: filters.created_by.join(",") };
    }

    if (sortBy && sortOrder) {
      params.sortBy = sortBy;
      params.sortOrder = sortOrder;
    }

    if (params.filter) {
      params.filter = JSON.stringify(params.filter);
    }

    const queryString = Object.entries(params)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join("&");

    const data = await get(`${url}?${queryString}`);
    return data;
  };

  /**
   * Deletes a space by its ID.
   *
   * @param {string} spaceIds - The IDs of the spaces to delete
   * @returns {Promise<Object>} A promise that resolves to the response of the delete operation
   */
  const deleteSpace = async (spaceIds) => {
    const data = await del(`${API_URL}/spaces`, spaceIds, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    return data;
  };

  /**
   * Creates a new space.
   *
   * @param {Object} data - The space data
   * @param {string} data.name - The name of the space
   * @param {string} data.description - The description of the space
   * @returns {Promise<Object>} A promise that resolves to the response of the create operation
   */
  const createSpace = async ({ name, description }) => {
    const url = `${API_URL}/spaces`;
    const queryParams = new URLSearchParams({
      name,
      description
    }).toString();

    const data = await post(`${url}?${queryParams}`, '', {
      headers: {
        'accept': 'application/json'
      }
    });
    return data;
  };

  const getConnectors = async () => {
    const data = await get(`${API_URL}/connection-setups/connectors`);
    return data;
  };

  /**
   * Fetches a specific space by its ID.
   *
   * @param {string} id - The ID of the space to fetch
   * @returns {Promise<Object>} A promise that resolves to the space details
   */
  const getSpaceById = async (id) => {
    const data = await get(`${API_URL}/spaces/${id}`);
    return data?.results;
  };

  /**
   * Updates an existing space.
   *
   * @param {string} id - The ID of the space to update
   * @param {Object} data - The update data
   * @param {string} [data.name] - The new name of the space
   * @param {string} [data.description] - The new description of the space
   * @returns {Promise<Object>} A promise that resolves to the response of the update operation
   */
  const updateSpace = async (id, { name, description }) => {
    const url = `${API_URL}/spaces/${id}`;
    const queryParams = new URLSearchParams();

    if (name) queryParams.append('name', name);
    if (description) queryParams.append('description', description);

    const data = await patch(`${url}?${queryParams.toString()}`, '', {
      headers: {
        'accept': 'application/json'
      }
    });
    return data;
  };

  /**
   * Adds users to a space with specified access types.
   *
   * @param {string} spaceId - The ID of the space
   * @param {Array<Object>} users - Array of user objects with user_id and access_type
   * @returns {Promise<Object>} A promise that resolves to the response
   */
  const addUsersToSpace = async (spaceId, users) => {
    const url = `${API_URL}/spaces/${spaceId}/users`;
    const data = await post(url, users, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return data;
  };

  /**
   * Removes users from a space.
   *
   * @param {string} spaceId - The ID of the space
   * @param {Array<string>} userIds - Array of user IDs to remove
   * @returns {Promise<Object>} A promise that resolves to the response
   */
  const deleteUsersFromSpace = async (spaceId, userIds) => {
    const url = `${API_URL}/spaces/${spaceId}/users`;
    const data = await del(url, userIds, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return data;
  };

  /**
   * Updates users' access types in a space.
   *
   * @param {string} spaceId - The ID of the space
   * @param {Array<Object>} users - Array of user objects with user_id and access_type
   * @returns {Promise<Object>} A promise that resolves to the response
   */
  const updateUsersInSpace = async (spaceId, users) => {
    const url = `${API_URL}/spaces/${spaceId}/users`;
    const data = await put(url, users, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return data;
  };

  /**
   * Fetches information about the currently logged-in user.
   *
   * @returns {Promise<Object>} A promise that resolves to the user information
   */
  const getSelfInfo = async () => {
    const url = `${API_URL}/userinfo`;
    const data = await get(url, {
      headers: {
        'accept': 'application/json'
      }
    });
    return data?.results?.user_info?.user_details_db;
  };
  /**
   * Gets upload URL for a file
   * @param {string} fileName - Name of the file to upload
   * @param {string} spaceId - ID of the space to associate with the file
   * @returns {Promise<Object>} Promise that resolves with the upload URL details
   */
  const getFileUploadUrl = async (fileName, spaceId) => {
    const url = `${API_URL}/connection-setups/get-upload-url`;
    const queryParams = new URLSearchParams({
      file_name: fileName,
      space_id: spaceId
    }).toString();

    const data = await post(`${url}?${queryParams}`, '', {
      headers: {
        'accept': 'application/json'
      }
    });
    return data?.results;
  };

  /**
   * Uploads a file to the provided upload URL
   * @param {string} uploadUrl - URL to upload the file to
   * @param {File} file - The file to upload
   * @param {Function} onUploadProgress - Callback for upload progress
   * @returns {Promise} Promise that resolves when upload is complete
   */
  const uploadFileToUrl = async (uploadUrl, file, onUploadProgress) => {
    try {
      const headers = {
        // 'x-ms-blob-type': 'BlockBlob',
        'X-Ms-Blob-Type': 'BlockBlob',
        'Content-Type': 'application/pdf',
        'accept': 'application/json, text/plain, */*'
      };

      const fileData = await file.arrayBuffer();

      const response = await uploaderPut(
        uploadUrl,
        fileData,
        headers,
        onUploadProgress
      );
      return response;
    } catch (error) {
      console.error('File upload failed:', error);
      throw error;
    }
  };

  const setupConnection = async (uploadedFiles, spaceId, sourceName) => {
    try {
      const url = `${API_URL}/connection-setups/setup`;
      const response = await post(url, {
        organization: 'codvo',
        source_name: sourceName,
        space_id: spaceId,
        connector_slug: 'local-upload',
        uploaded_files: uploadedFiles
      }, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });

      return response;
    } catch (error) {
      console.error('Connection setup failed:', error);
      throw error;
    }
  };

  const getDataPanelCategories = async (id) => {
    const data = await get(`${API_URL}/spaces/${id}/categories`);
    return data;
  };

  const createDataPanelCategory = async (id, body) => {
    const data = await post(`${API_URL}/spaces/${id}/categories`, body);
    return data;
  };

  const updateDataPanelCategory = async (id, categoryId, body) => {
    const data = await patch(`${API_URL}/spaces/${id}/categories/${categoryId}`, body);
    return data;
  };

  const deleteDataPanelCategory = async (id, categoryId) => {
    const data = await del(`${API_URL}/spaces/${id}/categories/${categoryId}`);
    return data;
  };

  const getFilesFolders = async (spaceId, parentId = null) => {
    try {
      const url = `${API_URL}/data-tab`;
      const queryParams = new URLSearchParams({
        space_id: spaceId,
        ...(parentId && { parent_id: parentId })
      }).toString();

      const data = await get(`${url}?${queryParams}`, {
        headers: {
          'accept': 'application/json',
          'organization': 'codvo'
        }
      });

      // Transform the API response to match the expected documentData structure
      const transformedData = data.results.map(item => ({
        id: item.id,
        fileName: item.name.split('/').pop(),
        isFolder: item.stream_type === 'FOLDER',
        parentFolder: item.parent_id,
        source: item.source ? {
          type: item.source.name,
          email: item.created_by
        } : null,
        summary: item.summary,
        documentCategory: item?.space_categories ? item.space_categories : ['uncategorized'],
        status: {
          state: item.status.charAt(0).toUpperCase() + item.status.slice(1),
          date: new Date(item.updated_at || item.created_at).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          })
        },
        uri: item.config?.view_url
      }));

      return transformedData;
    } catch (error) {
      console.error('Failed to fetch files and folders:', error);
      throw error;
    }
  }

  const getSourcesBySpaceId = async (params) => {
    const queryParams = new URLSearchParams({
      space_id: params.space_id,
      page: params.page,
      limit: params.limit,
      sort_order: params.sort_order || 'asc'
    }).toString();

    const data = await get(`${API_URL}/connection-setups/sources?${queryParams}`, {
      headers: {
        'accept': 'application/json',
        'organization': 'codvo'
      }
    });
    return data;
  };

  const getSpaceCategoriesCount = async (spaceId) => {
    try {
      const response = await get(`${API_URL}/spaces/${spaceId}/categories/stream_count`);
      return response?.results;
    } catch (error) {
      throw error;
    }
  };

  const updateSpaceCategory = async (streamId, body) => {
    const data = await put(`${API_URL}/streams/${streamId}/categories`, body);
    console.log("data", data);
  };

  const deleteDocument = async (documentId) => {
    const data = await del(`${API_URL}/connection-setups/file/${documentId}`);
    return data;
  };

  const getCategoryChecklist = async (spaceId) => {
    const data = await get(`${API_URL}/spaces/checklist/applied_info/${spaceId}`);
    return data?.result;
  };

  const updateCategoryChecklist = async (spaceId, checklist) => {
    const data = await patch(`${API_URL}/spaces/checklist/apply/${spaceId}`, checklist);
    return data;
  };

  return {
    getSpaces,
    deleteSpace,
    createSpace,
    getConnectors,
    getSpaceById,
    updateSpace,
    addUsersToSpace,
    deleteUsersFromSpace,
    updateUsersInSpace,
    getSelfInfo,
    getFileUploadUrl,
    uploadFileToUrl,
    setupConnection,
    getDataPanelCategories,
    createDataPanelCategory,
    updateDataPanelCategory,
    deleteDataPanelCategory,
    getFilesFolders,
    getSourcesBySpaceId,
    getSpaceCategoriesCount,
    updateSpaceCategory,
    deleteDocument,
    getCategoryChecklist,
    updateCategoryChecklist
  };
};

export default useSpaces;