import useApi from "services/api";
import { API_URL } from "../constants";

const PostTaskCreationData = () => {
  const { data, loading, error, post, patch } = useApi();
  const postTaskData = async (body) => {
    const data = await post(`${API_URL}/summary_job`, body);
    return data;
  };

  const terminateJob = async (jobId) => {
    const data = await patch(`${API_URL}/summary_job/${jobId}/status?status=Terminated`);
    return data;
  };

  return { postTaskData, data, loading, error, terminateJob };
};

export default PostTaskCreationData;
