import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import "../TemplateSection/newTemplate.css";
import UploaderComponent from "components/UploaderComponent";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Chip } from "primereact/chip";
import GetUsersList from "hooks/getUsers";
import { MultiSelect } from "primereact/multiselect";
import PostTemplateList from "hooks/postTemplate";
import { Toast } from "primereact/toast";
import useMediaQuerry from "../../../hooks/useMediaQuerry";
import { useUploadProgress } from "context/uploaderContext";
import { useKeycloak } from "@react-keycloak/web";
import UploadSuccessDialog from "../TemplateSection/UploadSuccessDialog";

const NewTemplates = ({
  visible,
  setVisible,
  setFetchData,
  fetchData,
  selectedTemplate,
  setTemplateTitle,
  templateTitle,
  setSelectedTemplate,
}) => {
  const { uploadProgress, setUploadProgress } = useUploadProgress();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [removedUsers, setRemovedUsers] = useState([]);
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const [descriptionError, setDescriptionError] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const { getUsers, data } = GetUsersList();
  const { isMobile } = useMediaQuerry();
  const { keycloak } = useKeycloak();
  const userInfo = keycloak?.tokenParsed;
  const {
    postTemplate,
    data: submitTemplateData,
    loading: submitTemplateLoading,
    patchTemplate,
  } = PostTemplateList();

  const fileName = useMemo(() => {
    return files[0]?.name || "";
  }, [files]);

  const MAX_FILE_SIZE = 30 * 1024 * 1024;
  const toast = useRef(null);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    validateForm();
  }, [description, files]);

  useEffect(() => {
    if (selectedTemplate && data?.results?.users && visible) {
      setDescription(selectedTemplate.description || "");

      const userList = selectedTemplate.accessTo
        .map((userName) => {
          const user = data.results.users.find((user) => {
            const fullName = `${user.firstname} ${user.lastname}`;
            return fullName === userName;
          });
          return user ? { name: user.email, id: user.id } : null;
        })
        .filter(Boolean);

      setSelectedUsers(userList);

      const fileData = new File(
        [selectedTemplate.template_object_name],
        selectedTemplate.templateName,
        { type: selectedTemplate.content_type },
      );

      setFiles([fileData]);
    }
  }, [selectedTemplate, visible, data]);

  const validateForm = () => {
    const specialCharPattern = /[^a-zA-Z0-9\s]/g;
    const isValidDescription =
      description.length > 0 && description.length <= 100 && !specialCharPattern.test(description);
    const hasFile = files.length > 0;

    setDescriptionError(description.length > 0 && !isValidDescription);
    setIsUploadDisabled(!isValidDescription || !hasFile);
  };
  const onUploadProgress = (progressEvent) => {
    const progressPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadProgress((prev) => ({
      ...prev,
      [fileName]: progressPercentage,
    }));
  };

  const handleSubmitTemplate = async () => {
    try {
      setUploadProgress((prev) => ({ ...prev, [fileName]: 1 }));
      const selectedUsersList = selectedUsers?.map((user) => ({ id: user.id }));
      const removedUsersList = removedUsers?.map((user) => ({ id: user.id }));
      const formData = new FormData();
      formData.append(
        "params",
        JSON.stringify({
          description: description,
          users: selectedUsersList,
        }),
      );
      formData.append("file", files[0]);

      if (templateTitle === "New Template") {
        const response = await postTemplate(formData, onUploadProgress);
        setShowSuccessDialog(true);
        handleSideBarClose();

        // toast.current.show({
        //   severity: "success",
        //   summary: "Success",
        //   detail: response?.message,
        //   life: 1000,
        // });
        setFetchData(!fetchData);
      } else {
        const patchPayload = {
          description: description,
          users: {
            added: selectedUsersList,
            deleted: removedUsersList,
          },
        };
        const response = await patchTemplate(selectedTemplate.id, patchPayload);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response?.message,
          life: 1000,
        });
        setFetchData(!fetchData);
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 1000,
      });
    }
  };

  const users = data?.results?.users
    .map((user) => ({ name: user.email, id: user.id }))
    // Filter out the current user
    .filter((e) => e.name !== userInfo.email);

  const handleUserRemove = (userToRemove) => {
    setSelectedUsers(selectedUsers.filter((user) => user !== userToRemove));
    setRemovedUsers((prevUsers) => [...prevUsers, userToRemove]);
  };

  const handleSideBarClose = () => {
    setVisible(false);
    setFiles([]);
    setSelectedTemplate(null);
    setDescription("");
    setSelectedUsers([]);
    setUploadProgress({});
  };

  const handleOnToastClose = () => {
    if (submitTemplateData?.status === 200) {
      handleSideBarClose();
      setVisible(false);
      setUploadProgress({});
    }
  };

  return (
    <>
      <Toast ref={toast} position="top-right" onHide={handleOnToastClose} />
      <UploadSuccessDialog
        visible={showSuccessDialog}
        onHide={() => {
          setShowSuccessDialog(false);
        }}
      />
      <div className="flex grid mr-3 templateHeader flex-col h-full">
        <Sidebar
          visible={visible}
          position="right"
          onHide={() => {
            handleSideBarClose();
            setTemplateTitle("New Template");
            setVisible(false);
          }}
          header={templateTitle}
          className="bg-white w-full md:w-20rem lg:w-30rem "
        >
          <div>
            <UploaderComponent
              files={files}
              setFiles={setFiles}
              fileSize={MAX_FILE_SIZE}
              disableFileLimit={1}
              fileSizeValue={selectedTemplate?.file_size}
              acceptedFiles=".pdf,.doc,.docx"
              isCancelTrue={templateTitle === "Manage Template" ? false : true}
            />
          </div>
          <div className="flex flex-column gap-2 mt-5">
            <label htmlFor="description" className="text-sm">
              Template Description
            </label>
            <InputText
              className={`text-sm ${descriptionError ? "p-invalid" : ""}`}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              id="description"
              aria-describedby="description-help"
              placeholder="Add Template Description"
              maxLength={500}
            />
            {descriptionError && (
              <small className="p-error">
                Description must be within 100 characters and cannot contain special characters.
              </small>
            )}
          </div>
          <Divider />
          <div className="flex flex-column flex-grow">
            <label htmlFor="description" className="text-sm">
              <p>Add Users</p>
              <p className="text-xs">
                Others users can view and use the shared templates, but they won't be able to edit
                or delete it
              </p>
            </label>
            <div className="flex">
              <MultiSelect
                value={selectedUsers}
                onChange={(e) => {
                  setSelectedUsers(e.value);
                }}
                options={users}
                fixedPlaceholder={true}
                optionLabel="name"
                filter
                placeholder="Select user email"
                maxSelectedLabels={3}
                className="w-full md:w-full text-sm"
              />
            </div>
            <div style={{ marginTop: "10px", display: "flex", flexWrap: "wrap", gap: "8px" }}>
              {selectedUsers.map((user) => (
                <Chip
                  key={user.id}
                  label={user.name}
                  className="text-sm"
                  removable
                  onRemove={() => {
                    handleUserRemove(user);
                  }}
                />
              ))}
            </div>
          </div>

          <div
            className={`w-full flex justify-content-end gap-2  ${isMobile ? "sidebar-mobile" : "sidebar-desktop"}`}
          >
            <Button
              label="Cancel"
              onClick={handleSideBarClose}
              className="text-sm text-gray-500 bg-white border-0"
            />
            <Button
              label={templateTitle === "Manage Template" ? "Update" : "Upload"}
              className="text-sm bg-blue-500"
              onClick={handleSubmitTemplate}
              loading={submitTemplateLoading}
              disabled={isUploadDisabled}
            />
          </div>
        </Sidebar>
      </div>
    </>
  );
};

export default NewTemplates;
