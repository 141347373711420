import { ProgressBar } from "primereact/progressbar";
import React, { useMemo } from "react";
import PropTypes from "prop-types";

const TaskProgressBar = ({ task, style, className }) => {
  const progress = useMemo(() => {
    // File Upload to Azure Blob: +10% (Stage 1/5 completed)
    // Publish Message to Python Script via NATS: +5% (Stage 2/5 completed)
    // File Conversion to Markdown via Marker API: +30% (Stage 3/5 completed)
    // Data Science Ingestion and RAG Pipeline: +45% (Stage 4/5 completed)
    // Output File Upload to Azure Blob: +10% (Stage 5/5 completed)
    switch (task.progress) {
      case 0:
        return 0;
      case 1:
        return 10;
      case 2:
        return 15;
      case 3:
        return 45;
      case 4:
        return 90;
      case 5:
        return 100;
      default:
        return 0;
    }
  }, [task.progress]);

  return (
    <ProgressBar
      value={progress}
      showValue={false}
      style={style}
      className={className}
    ></ProgressBar>
  );
};

TaskProgressBar.propTypes = {
  task: PropTypes.object.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default TaskProgressBar;
