const NotAccessible = () => {
  return (
    <div className="p-2 w-full h-full surface-0 p-3 commonContainer">
      <div className="block-content">
        <div className={"surface-section px-4 py-8 md:px-6 lg:px-8"}>
          <div
            style={{
              background:
                "radial-gradient(50% 109138% at 50% 50%, rgb(255 43 43 / 27%) 0%, rgb(255 255 255 / 0%) 100%)",
            }}
            className="text-center"
          >
            <span className="bg-white text-red-500 font-bold text-2xl inline-block px-3 flipup">
              Access Denied
            </span>
          </div>
          <p className="text-700 text-3xl mt-5 text-center fadeinup">
            Oops! It seems like you don't have access to this app. Please contact your administrator for assistance.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotAccessible;
