import React, { useState, useEffect, memo, forwardRef, useImperativeHandle, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import autoCategorize from "../../../assets/Agents/Space/auto-categorize.svg";
import { ProgressSpinner } from "primereact/progressspinner";
import { Divider } from "primereact/divider";
import { ColorPicker } from "primereact/colorpicker";
import { Chip } from "primereact/chip";
import "./index.css";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Chips } from "primereact/chips";
import useSpaces from "../../../hooks/useSpace";
import Checklist from "./Checklist";
import { MultiSelect } from "primereact/multiselect";
import useFacts from "hooks/useFacts";

const initialCategory = { id: 0, name: "", color: "", description: "", keywords: [], checklist: [] };

const availableColors = [
    "#6c737a",
    "#FF6767",
    "#6786FF",
    "#0FB348",
    "#B367FF",
];

export const CategoryLayout = forwardRef(
    ({ data, isLoading, spaceId, categoryType, toast, onSave }, ref) => {
        const [selectedCategory, setSelectedCategory] = useState(null);
        const [loading, setLoading] = useState(false);
        const { updateDataPanelCategory, createDataPanelCategory, deleteDataPanelCategory, getCategoryChecklist, updateCategoryChecklist } =
            useSpaces();
        const { getFacts } = useFacts();
        const [viewMode, setViewMode] = useState("view");
        const [editedCategory, setEditedCategory] = useState(initialCategory);
        const [deleteCategory, setDeleteCategory] = useState(null);
        const [innerData, setInnerData] = useState(data);
        const [checklistOptions, setChecklistOptions] = useState([]);
        const [tempChecklist, setTempChecklist] = useState([]);
        const [appliedCategories, setAppliedCategories] = useState("");
        const [checklistAllDocuments, setChecklistAllDocuments] = useState(selectedCategory?.checklist_ids || []);
        const [editMode, setEditMode] = useState(false);

        useImperativeHandle(ref, () => ({
            handleSave,
        }));

        useEffect(() => {
            setEditedCategory((prev) => ({
                ...prev,
                checklist: tempChecklist,
            }));
        }, [tempChecklist]);

        useEffect(() => {
            const fetchChecklistOptions = async () => {
                const options = await getCategoryChecklist(spaceId);
                setChecklistOptions(options ?? []);
            };
            fetchChecklistOptions();
        }, [spaceId]);

        useEffect(() => {
            if (selectedCategory && deleteCategory) {
                const timer = setTimeout(() => {
                    setDeleteCategory(null);
                }, 3000);
                return () => clearTimeout(timer);
            }
        }, [deleteCategory]);

        useEffect(() => {
            setInnerData(data);
        }, [data]);

        const handleCategorySelect = (item) => {
            setLoading(true);
            setViewMode(item.id === "all" ? "all" : "view");
            setEditMode(false);
            setSelectedCategory(null);
            setDeleteCategory(null);
            const timer = setTimeout(() => {
                setChecklistAllDocuments(item?.checklist_ids || []);
                setTempChecklist(item?.checklist_ids || []);
                setSelectedCategory(item);
                setLoading(false);
            }, 1000);
            return () => clearTimeout(timer);
        };

        const handleInputChange = (field, value) => {
            setEditedCategory((prev) => ({
                ...prev,
                [field]: value,
            }));
        };

        const existingChecklistLayout = () => {
            return (
                <div className="flex flex-col gap-2 mb-4 p-2 border-round-md warning-dialog text-sm font-light">
                    <i className="pi pi-exclamation-triangle"></i>
                    {selectedCategory?.id === "all"
                        ? `Highlighted Checklist are already applied on the following Categories - ${appliedCategories}. Do you still want to apply these checklists on \"All Documents\"?`
                        : `Highlighted Checklist is already applied on \"All Documents\" level. Do you still want to apply it to \"${selectedCategory?.name || "New"}\" Category?`}
                </div>
            );
        };

        const handleColorSelect = (color) => {
            setEditedCategory((prev) => ({
                ...prev,
                color,
            }));
        };

        const updateChecklist = async () => {
            try {
                setLoading(true);
                await updateCategoryChecklist(spaceId, checklistAllDocuments);
                setEditMode(false);
                toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Checklist updated successfully",
                });
                onSave?.();
            } catch (error) {
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: "Error updating checklist",
                });
            } finally {
                setLoading(false);
            }
        };

        const handleSave = async () => {
            if (viewMode === "add" || viewMode === "edit" || editMode === true) {
                setLoading(true);
                if (selectedCategory?.id === "all") {
                    await updateChecklist();
                    setLoading(false);
                    return;
                }
                if (!editedCategory.name?.trim()) {
                    if (toast?.current) {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "Category name is required",
                            life: 3000,
                        });
                    }
                    setLoading(false);
                    return;
                }

                if (!editedCategory.description?.trim()) {
                    if (toast?.current) {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "Category description is required",
                            life: 3000,
                        });
                    }
                    setLoading(false);
                    return;
                }

                if (!editedCategory.color) {
                    if (toast?.current) {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "Please select a color",
                            life: 3000,
                        });
                    }
                    setLoading(false);
                    return;
                }

                if (!editedCategory.keywords?.length) {
                    if (toast?.current) {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "At least one keyword is required",
                            life: 3000,
                        });
                    }
                    setLoading(false);
                    return;
                }

                const body = {
                    name: editedCategory.name.trim(),
                    description: editedCategory.description.trim(),
                    keywords: editedCategory.keywords,
                    space_id: spaceId,
                    color: editedCategory.color,
                    checklist_ids: tempChecklist,
                };

                try {
                    if (viewMode === "edit") {
                        await updateDataPanelCategory(spaceId, editedCategory.id, body);
                        setInnerData((prev) =>
                            prev.map((item) => (item.id === editedCategory.id ? editedCategory : item)),
                        );
                        if (toast?.current) {
                            toast.current.show({
                                severity: "success",
                                summary: "Success",
                                detail: "Category updated successfully",
                                life: 3000,
                            });
                        }
                    } else {
                        const response = await createDataPanelCategory(spaceId, body);
                        const newCategory = { ...editedCategory, id: response.id };
                        setInnerData((prev) => [...prev, newCategory]);
                        setSelectedCategory(newCategory);
                        if (toast?.current) {
                            toast.current.show({
                                severity: "success",
                                summary: "Success",
                                detail: "Category created successfully",
                                life: 3000,
                            });
                        }
                    }
                    setViewMode("view");
                    onSave?.();
                } catch (error) {
                    if (toast?.current) {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: "Failed to save category",
                            life: 3000,
                        });
                    }
                } finally {
                    setLoading(false);
                }
            } else {
                onSave?.();
            }
        };

        const handleCancel = () => {
            setViewMode("view");
        };

        const handleDelete = async (id) => {
            setLoading(true);
            setSelectedCategory(null);
            try {
                await deleteDataPanelCategory(spaceId, id);
                setInnerData((prev) => prev.filter((item) => item.id !== id));
                if (toast?.current) {
                    toast.current.show({
                        severity: "success",
                        summary: "Success",
                        detail: "Category deleted successfully",
                        life: 3000,
                    });
                }
            } catch (error) {
                if (toast?.current) {
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: "Failed to delete category",
                        life: 3000,
                    });
                }
            } finally {
                setLoading(false);
            }
        };

        return isLoading ? (
            <div className="flex flex-column justify-content-center align-items-center h-full">
                <ProgressSpinner />
                <p>Loading Categories...</p>
            </div>
        ) : (
            <div className="flex gap-2 justify-content-between border-round-lg px-2 h-full">
                <div className="flex flex-column px-2 w-4 border-right-1 border-gray-200">
                    {innerData.length > 0 &&
                        innerData.map((item, index) => (
                            <div key={index}>
                                <div
                                    className={`cursor-pointer p-2 transition-duration-300 hover:shadow-2 border-round-md flex justify-content-between align-items-center w-full ${selectedCategory?.name === item.name ? "bg-gray-100 border-gray-100" : "hover:bg-gray-100 hover:border-gray-100"}`}
                                >
                                    <p
                                        className="text-sm my-2 w-8 overflow-hidden text-overflow-ellipsis whitespace-nowrap"
                                        onClick={() => handleCategorySelect(item)}
                                    >
                                        {item.name}
                                    </p>
                                    {item.id !== "all" && (
                                        <i
                                            className="pi pi-trash text-gray-500 transition-opacity-300 w-4 text-right"
                                            style={{
                                                display:
                                                    selectedCategory?.name === item.name && deleteCategory === null
                                                        ? "block"
                                                        : "none",
                                            }}
                                            onClick={() => setDeleteCategory(true)}
                                        />
                                    )}
                                    {deleteCategory && selectedCategory?.name === item.name && (
                                        <p
                                            className="text-xs font-semibold text-red-500 my-0 w-4 text-right"
                                            onClick={() => handleDelete(item.id)}
                                        >
                                            Confirm?
                                        </p>
                                    )}
                                </div>
                                <Divider className="m-0 p-0" />
                            </div>
                        ))}
                    {categoryType === "define" && (
                        <div
                            className="cursor-pointer p-2 transition-duration-300"
                            onClick={() => {
                                if (innerData.length >= 8) {
                                    if (toast?.current) {
                                        toast.current.show({
                                            severity: "error",
                                            summary: "Error",
                                            detail: "Max 8 categories can be defined",
                                            life: 3000,
                                        });
                                    }
                                    return;
                                }
                                setViewMode("add");
                                setSelectedCategory(null);
                                setEditedCategory(initialCategory);
                                setTempChecklist([]);
                            }}
                        >
                            <p className="text-sm my-2 font-semibold text-blue-500">Add Category</p>
                        </div>
                    )}
                </div>
                <div className="flex flex-column border-round-lg px-2 w-full">
                    {loading ? (
                        <div className="flex flex-column justify-content-center align-items-center h-full">
                            <ProgressSpinner />
                            <p>Loading category details...</p>
                        </div>
                    ) : selectedCategory && viewMode === "view" ? (
                        <div className="px-2 flex flex-column gap-2 w-full">
                            <div className="mb-4">
                                <p className="text-sm font-normal mt-0 mb-2">Category Name</p>
                                <p
                                    className="text-base font-semibold my-0 w-full overflow-wrap-break-word"
                                    style={{ wordBreak: "break-all" }}
                                >
                                    {selectedCategory.name}
                                </p>
                            </div>
                            <div className="mb-4">
                                <p className="text-sm font-normal mt-0 mb-2">Color</p>
                                <ColorPicker
                                    className="custom-colorpicker"
                                    value={selectedCategory.color}
                                    disabled
                                />
                            </div>
                            <div className="mb-4">
                                <p className="text-sm font-normal mt-0 mb-2">Description</p>
                                <p
                                    className="text-base font-semibold my-0 w-full overflow-wrap-break-word"
                                    style={{ wordBreak: "break-all" }}
                                >
                                    {selectedCategory.description}
                                </p>
                            </div>
                            <div className="mb-4">
                                <p className="text-sm font-normal mt-0 mb-2">Keywords</p>
                                {selectedCategory?.keywords?.length > 0 && (
                                    <div className="flex gap-2 flex-wrap">
                                        {selectedCategory?.keywords?.map((keyword, index) => (
                                            <Chip key={index} label={keyword} className="text-xs font-light" />
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="mb-4">
                                <p className="text-sm font-normal mt-0 mb-2">Checklist</p>
                                {selectedCategory?.checklist_ids?.length > 0 && (
                                    <div className="flex gap-2 flex-wrap">
                                        {selectedCategory?.checklist_ids?.map((checklist, index) => (
                                            <Chip key={index} label={checklistOptions.find((option) => option.id === checklist)?.name} className="text-xs font-light" />
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-content-end align-items-baseline">
                                <Button
                                    icon="pi pi-pencil"
                                    className="bg-transparent border-blue-500 text-blue-500 mb-2 border-round-lg hover:bg-blue-500 hover:text-white"
                                    onClick={() => {
                                        setViewMode("edit");
                                        setEditedCategory(selectedCategory);
                                    }}
                                />
                            </div>
                        </div>
                    ) : selectedCategory && (viewMode === "edit" || viewMode === "all") ? (
                        selectedCategory.id === "all" ? (
                            <Checklist {...{ existingChecklistLayout, selectedCategory, checklistOptions, toast, spaceId, onSave, data, setAppliedCategories, checklistAllDocuments, setChecklistAllDocuments, updateChecklist, editMode, setEditMode }} />
                        ) : (
                            <div className="px-2 text-md">
                                <div className="flex flex-col gap-2 align-items-center mb-4">
                                    <label htmlFor="categoryName" className="w-3">
                                        Category Name
                                    </label>
                                    <InputText
                                        id="categoryName"
                                        value={editedCategory.name}
                                        onChange={(e) => handleInputChange("name", e.target.value)}
                                        className="w-8"
                                        aria-describedby="categoryName-help"
                                    />
                                </div>
                                <div className="flex flex-col gap-2 align-items-center mb-4">
                                    <label htmlFor="color" className="w-3">
                                        Color
                                    </label>
                                    <div className="flex gap-2">
                                        {availableColors.map((color) => (
                                            <div
                                                key={color}
                                                className={`cursor-pointer p-2 border-1 border-round-md ${editedCategory.color === color ? "border-blue-500" : "border-gray-200"}`}
                                                onClick={() => handleColorSelect(color)}
                                            >
                                                <div
                                                    style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        backgroundColor: color,
                                                        borderRadius: "4px",
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 align-items-center mb-4">
                                    <label htmlFor="categoryDescription" className="w-3">
                                        Description
                                    </label>
                                    <InputTextarea
                                        id="categoryDescription"
                                        value={editedCategory.description}
                                        onChange={(e) => handleInputChange("description", e.target.value)}
                                        className="w-8"
                                        aria-describedby="categoryDescription-help"
                                        autoResize={false}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 align-items-center mb-4">
                                    <label htmlFor="categoryKeywords" className="w-3">
                                        Keywords
                                    </label>
                                    <Chips
                                        className="w-8"
                                        value={editedCategory.keywords}
                                        onChange={(e) => handleInputChange("keywords", e.value)}
                                    />
                                </div>
                                <div className="flex flex-col gap-2 align-items-center mb-2">
                                    <label htmlFor="categoryChecklist" className="w-3">
                                        Add Checklist
                                    </label>
                                    <MultiSelect
                                        className="w-8"
                                        value={tempChecklist}
                                        onChange={(e) => setTempChecklist(e.value)}
                                        placeholder="Add Checklist"
                                        fixedPlaceholder={true}
                                        filter
                                        options={checklistOptions}
                                        optionLabel="name"
                                        optionValue="id"
                                    />
                                </div>
                                <div className="flex flex-col gap-2 align-items-center mb-2">
                                    <label htmlFor="categoryChecklist" className="w-3"></label>
                                    <div className="w-8">
                                        {tempChecklist?.map((checklist) => (
                                            <Chip
                                                key={checklist}
                                                label={checklistOptions.find((option) => option.id === checklist)?.name}
                                                className={`text-sm mt-1 mr-2 ${checklistOptions.find((option) => option.id === checklist)?.applied_to_space && "warning-chip"}`}
                                                removable
                                                onRemove={() => {
                                                    setTempChecklist((prev) => prev.filter((docId) => docId !== checklist));
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                                {tempChecklist?.map((checklist) => checklistOptions.find((option) => option.id === checklist)?.applied_to_space).some(Boolean) && (
                                    <div className="flex flex-col gap-2 align-items-center mb-2">
                                        <label htmlFor="categoryChecklist" className="w-3"></label>
                                        <div className="w-9">
                                            {existingChecklistLayout()}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    ) : viewMode === "add" ? (
                        <div className="px-2 text-md">
                            <div className="flex flex-col gap-2 align-items-center mb-4">
                                <label htmlFor="categoryName" className="w-3">
                                    Category Name
                                </label>
                                <InputText
                                    id="categoryName"
                                    value={editedCategory.name}
                                    onChange={(e) => handleInputChange("name", e.target.value)}
                                    className="w-8"
                                    aria-describedby="categoryName-help"
                                />
                            </div>
                            <div className="flex flex-col gap-2 align-items-center mb-4">
                                <label htmlFor="color" className="w-3">
                                    Color
                                </label>
                                <div className="flex gap-2">
                                    <div
                                        className={`cursor-pointer p-2 border-1 border-round-md ${editedCategory.color === "#DFE7EF" ? "border-blue-500" : "border-gray-200"}`}
                                        onClick={() => handleColorSelect("#DFE7EF")}
                                    >
                                        <div
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                backgroundColor: "#DFE7EF",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={`cursor-pointer p-2 border-1 border-round-md ${editedCategory.color === "#FF6767" ? "border-blue-500" : "border-gray-200"}`}
                                        onClick={() => handleColorSelect("#FF6767")}
                                    >
                                        <div
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                backgroundColor: "#FF6767",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={`cursor-pointer p-2 border-1 border-round-md ${editedCategory.color === "#6786FF" ? "border-blue-500" : "border-gray-200"}`}
                                        onClick={() => handleColorSelect("#6786FF")}
                                    >
                                        <div
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                backgroundColor: "#6786FF",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={`cursor-pointer p-2 border-1 border-round-md ${editedCategory.color === "#0FB348" ? "border-blue-500" : "border-gray-200"}`}
                                        onClick={() => handleColorSelect("#0FB348")}
                                    >
                                        <div
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                backgroundColor: "#0FB348",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={`cursor-pointer p-2 border-1 border-round-md ${editedCategory.color === "#B367FF" ? "border-blue-500" : "border-gray-200"}`}
                                        onClick={() => handleColorSelect("#B367FF")}
                                    >
                                        <div
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                backgroundColor: "#B367FF",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={`cursor-pointer p-2 border-1 border-round-md ${editedCategory.color === "#67CAFF" ? "border-blue-500" : "border-gray-200"}`}
                                        onClick={() => handleColorSelect("#67CAFF")}
                                    >
                                        <div
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                backgroundColor: "#67CAFF",
                                                borderRadius: "4px",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 align-items-center mb-4">
                                <label htmlFor="categoryDescription" className="w-3">
                                    Description
                                </label>
                                <InputTextarea
                                    id="categoryDescription"
                                    value={editedCategory.description}
                                    onChange={(e) => handleInputChange("description", e.target.value)}
                                    className="w-8"
                                    aria-describedby="categoryDescription-help"
                                    autoResize={false}
                                />
                            </div>
                            <div className="flex flex-col gap-2 align-items-center mb-4">
                                <label htmlFor="categoryKeywords" className="w-3">
                                    Keywords
                                </label>
                                <Chips
                                    className="w-8"
                                    value={editedCategory.keywords}
                                    onChange={(e) => handleInputChange("keywords", e.value)}
                                />
                            </div>
                            <div className="flex flex-col gap-2 align-items-center mb-4">
                                <label htmlFor="categoryChecklist" className="w-3">
                                    Checklist
                                </label>
                                <MultiSelect
                                    className="w-8"
                                    value={tempChecklist}
                                    onChange={(e) => setTempChecklist(e.value)}
                                    placeholder="Add Checklist"
                                    fixedPlaceholder={true}
                                    filter
                                    options={checklistOptions}
                                    optionLabel="name"
                                    optionValue="id"
                                />
                            </div>
                            <div className="flex flex-col gap-2 align-items-center mb-2">
                                <label htmlFor="categoryChecklist" className="w-3"></label>
                                <div className="w-8">
                                    {tempChecklist?.map((checklist) => (
                                        <Chip
                                            key={checklist}
                                            label={checklistOptions.find((option) => option.id === checklist)?.name}
                                            className={`text-sm mt-1 mr-2 ${checklistOptions.find((option) => option.id === checklist)?.applied_to_space && "warning-chip"}`}
                                            removable
                                            onRemove={() => {
                                                setTempChecklist((prev) => prev.filter((docId) => docId !== checklist));
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                            {tempChecklist?.map((checklist) => checklistOptions.find((option) => option.id === checklist)?.applied_to_space).some(Boolean) && (
                                <div className="flex flex-col gap-2 align-items-center mb-2">
                                    <label htmlFor="categoryChecklist" className="w-3"></label>
                                    <div className="w-9">
                                        {existingChecklistLayout()}
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="m-auto">
                            <p className="text-gray-600">Select a category to view its details</p>
                        </div>
                    )}
                </div>
            </div>
        );
    },
);

const Category = memo(({ toast, visible, setVisible }) => {
    const path = window.location.pathname;
    const match = path.match(/\/spaces\/([^/]+)/);
    const [categoryStage, setCategoryStage] = useState(null);
    const [categoryType, setCategoryType] = useState(null);
    const { getSpaceCategoriesCount } = useSpaces();
    const { getFacts } = useFacts();
    const [spaceId, setSpaceId] = useState("");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const categoryLayoutRef = React.useRef();

    useEffect(() => {
        if (match && match[1]) {
            setSpaceId(match[1]);
        }
    }, []);

    const fetchData = async () => {
        if (!spaceId) return;
        setIsLoading(true);
        try {
            const response = await getSpaceCategoriesCount(spaceId);
            if (response) {
                const transformedData =
                    response.map((category) => ({
                        id: category.id,
                        name: category.name,
                        color: category.color,
                        description: category.description || "",
                        keywords: category.keywords || [],
                        total: category.no_of_files_attached,
                        checklist_ids: category.checklist_ids || [],
                    })) || [];
                setData([
                    ...transformedData.sort((a, b) =>
                        a.id === 'all' ? -1 : b.id === 'all' ? 1 : 0
                    ),
                ]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            if (toast?.current) {
                toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail: "Failed to fetch categories",
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (spaceId) {
            fetchData();
        }
    }, [spaceId]);

    useEffect(() => {
        if (!visible) {
            fetchData();
        }
    }, [visible]);

    const clear = () => {
        setCategoryStage(null);
        setCategoryType(null);
        setVisible(false);
    };

    const handleConfirm = async () => {
        if (categoryStage === "define") {
            try {
                const isValid = await categoryLayoutRef.current?.handleSave();
                if (isValid) {
                    clear();
                }
            } catch (error) {
                console.error("Error saving category:", error);
                if (toast?.current) {
                    toast.current.show({
                        severity: "error",
                        summary: "Error",
                        detail: "Failed to save category",
                    });
                }
            }
        } else {
            setCategoryStage("define");
        }
    };

    const footerContent = (
        <div className="border-top-1 pt-2 border-gray-200">
            <Button label="Cancel" onClick={clear} className="p-button-text border-blue-500" />
            <Button onClick={handleConfirm} label={categoryStage === "define" ? "Confirm" : "Next"} />
        </div>
    );

    const CategoryPanel = () => {
        return (
            <Dialog
                header={
                    <div className="flex flex-column">
                        <span className="text-md font-semibold">Define Category</span>
                        <span className="text-gray-500 text-sm font-normal mt-1">
                            Choose how you want to categorize your documents
                        </span>
                    </div>
                }
                visible={visible}
                style={{ width: "50vw" }}
                footer={footerContent}
                onHide={clear}
            >
                <div className="flex flex-col gap-2 justify-content-center my-5 border-round-lg px-2 w-9 mx-auto">
                    <div className="border-1 border-gray-200 border-round-md cursor-pointer p-4 transition-duration-300 hover:shadow-2 hover:border-blue-500">
                        <h4 className="text-md font-semibold">Auto Categorize</h4>
                        <p>Let AI automatically define the categories based on the documents</p>
                    </div>
                    <div
                        className="border-1 border-gray-200 border-round-md cursor-pointer p-4 transition-duration-300 hover:shadow-2 hover:border-blue-500"
                        onClick={() => {
                            setCategoryStage("define");
                            setCategoryType("define");
                        }}
                    >
                        <h4 className="text-md font-semibold">Define Your Own Categories</h4>
                        <p>Define the categories manually, and let the AI handle the rest.</p>
                    </div>
                </div>
            </Dialog>
        );
    };

    const AutoCategorizePanel = () => {
        return (
            <Dialog
                header={
                    <div className="flex align-items-center">
                        <Button
                            icon="pi pi-chevron-left"
                            onClick={clear}
                            className="p-button-text p-button-rounded text-gray-500 mr-1"
                        />
                        <span className="text-md font-semibold">Auto Categorize</span>
                    </div>
                }
                visible={visible}
                style={{ width: "80vw", height: "80vh" }}
                footer={footerContent}
                onHide={clear}
            >
                <div className="flex flex-column gap-2 justify-content-center my-5 border-round-lg px-2 w-9 mx-auto justify-content-center align-items-center">
                    <img src={autoCategorize} alt="Auto Categorize" className="w-3" />
                    <h4 className="text-base font-semibold mb-0">Reading your documents</h4>
                    <p className="mt-0 font-light text-sm">
                        The process may take some time depending on the document length and number of pages
                    </p>
                </div>
            </Dialog>
        );
    };

    const DefineYourOwnCategoriesPanel = () => {
        return (
            <Dialog
                header={
                    <div className="flex align-items-center">
                        <Button
                            icon="pi pi-chevron-left"
                            onClick={() => {
                                setCategoryStage(null);
                            }}
                            className="p-button-text p-button-rounded text-gray-500 mr-1"
                        />
                        <div className="flex flex-column">
                            <span className="text-md font-semibold">
                                {categoryType === "auto" ? "Auto Categorize" : "Define Category"}
                            </span>
                            {categoryType === "define" && (
                                <span className="text-gray-500 text-sm font-normal mt-1">
                                    Max 8 categories can be defined
                                </span>
                            )}
                        </div>
                    </div>
                }
                visible={visible}
                style={{ width: "80vw" }}
                footer={footerContent}
                className="h-full"
                onHide={() => {
                    clear();
                }}
            >
                <CategoryLayout
                    ref={categoryLayoutRef}
                    data={data}
                    isLoading={isLoading}
                    spaceId={spaceId}
                    categoryType={categoryType}
                    toast={toast}
                    onSave={() => {
                        if (spaceId) {
                            fetchData();
                        }
                        setVisible(false);
                    }}
                />
            </Dialog>
        );
    };

    switch (categoryStage) {
        case "auto":
            return <AutoCategorizePanel />;
        case "define":
            return <DefineYourOwnCategoriesPanel />;
        default:
            return <CategoryPanel />;
    }
});

CategoryLayout.displayName = "CategoryLayout";
Category.displayName = "Category";

export default memo(Category);
