// mediaQueries.js

import { useMediaQuery } from "react-responsive";

const useMediaQuerry = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isLandScape = useMediaQuery({ query: "(max-height: 500px) and (orientation: landscape)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isIPadPro = useMediaQuery({ query: "(max-width: 1024px)" });

  return {
    isDesktopOrLaptop,
    isBigScreen,
    isTablet,
    isPortrait,
    isRetina,
    isMobile,
    isLandScape,
    isIPadPro,
  };
};
export default useMediaQuerry;
