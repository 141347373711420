import React, { useState, useEffect, useRef, Fragment } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import "../../DocumentEngine/TemplateSection/newTemplate.css";
import { TieredMenu } from "primereact/tieredmenu";
import { Toast } from "primereact/toast";
import GetUsersList from "hooks/getUsers";
import { FilterMatchMode } from "primereact/api";

export default function GroupPanels() {
  const toast = useRef(null);
  const menu = useRef(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedGroups, setSelectedGroups] = useState(null);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { loading, getGroupDetails } = GetUsersList();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);

  const fetchUsers = async () => {
    try {
      const data = await getGroupDetails(sortField, sortOrder);
      if (data.success && data.results && data.results.data) {
        setData(data.results.data);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please try again later",
        life: 3000,
      });
    }
  };

  function handleOnSort(event) {
    const { sortField, sortOrder } = event;
    setSortField(sortField);
    const order = sortOrder === 1 ? "desc" : "asc";
    setSortOrder(order);
    getGroupDetails(sortField, sortOrder);
  }

  useEffect(() => {
    fetchUsers();
  }, [currentPage, rowsPerPage]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex items-center w-full">
        <div className="flex-1 items-center">
          <span className="text-lg font-bold">{data?.length || 0} Groups</span>
        </div>
        <div className="flex-2 flex justify-end">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search text-sm" />
            <InputText
              value={globalFilterValue}
              className="text-sm"
              onChange={onGlobalFilterChange}
              placeholder="Search Group Name"
            />
          </IconField>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const actionTemplate = (rowData) => {
    const items = [
      {
        label: "Edit",
        command: () => {},
      },
      {
        label: "Delete",
        command: () => {},
      },
    ];

    const moreMenu = (
      <div className="card flex justify-content-center">
        <TieredMenu
          model={items}
          popup
          ref={menu}
          breakpoint="767px"
          className="max-w-10rem text-sm"
        />
        <Button
          type="button"
          icon={"pi pi-ellipsis-v"}
          onClick={(e) => {
            menu.current.toggle(e);
            setTimeout(() => {
              menu?.current
                ?.getElement()
                ?.children?.[0]?.children?.[0]?.classList?.remove("p-focus");
            }, 100);
            setSelectedGroups(rowData);
          }}
          className="p-button-rounded p-button-text p-button-secondary w-2rem h-2rem"
        />
      </div>
    );

    return <div className="text-right inline-flex">{moreMenu}</div>;
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.page + 1);
    setRowsPerPage(e.rows);
  };

  const handleMouseEnter = (e, content) => {
    const rect = e.target.getBoundingClientRect();
    setTooltipPosition({
      x: rect.left,
      y: rect.top - 30,
    });
    setTooltipContent(content);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <Fragment>
      {showTooltip && (
        <div
          style={{
            position: "fixed",
            left: tooltipPosition.x,
            top: tooltipPosition.y,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            color: "white",
            padding: "4px 8px",
            borderRadius: "4px",
            zIndex: 1000,
            maxWidth: "300px",
            wordWrap: "break-word",
          }}
        >
          {tooltipContent}
        </div>
      )}
      <Toast ref={toast} />
      <div className="card my-5 templateTable" style={{ height: "70vh" }}>
        <DataTable
          value={data}
          rowsPerPageOptions={[5, 10, 20]}
          rows={rowsPerPage}
          paginator
          onFilter={(e) => setFilters(e.filters)}
          filters={filters}
          onPage={handlePageChange}
          first={(currentPage - 1) * rowsPerPage}
          loading={loading}
          inSort={false}
          tableStyle={{ minWidth: "50rem" }}
          header={header}
          sortField={sortField}
          sortOrder={sortOrder === "desc" ? 1 : -1}
          onSort={handleOnSort}
          emptyMessage="No users found"
          className="w-full"
          scrollable
          scrollHeight="flex"
          selection={selectedGroups}
          onSelectionChange={(e) => setSelectedGroups(e.value)}
          dataKey="id"
          selectionMode={"checkbox"}
        >
          <Column
            field="name"
            header="Group Name"
            body={(rowData) => (
              <div
                className="truncate"
                onMouseEnter={(e) => handleMouseEnter(e, rowData.name)}
                onMouseLeave={handleMouseLeave}
              >
                {rowData.name}
              </div>
            )}
            sortable
            style={{ width: "27.5%" }}
          ></Column>
          <Column
            field="description"
            header="Description"
            body={(rowData) => (
              <div
                className="truncate"
                onMouseEnter={(e) => handleMouseEnter(e, rowData.description)}
                onMouseLeave={handleMouseLeave}
              >
                {rowData.description}
              </div>
            )}
            style={{ width: "27.5%" }}
          ></Column>
          <Column
            field="user_count"
            header="Number of users"
            sortable
            style={{ width: "20%" }}
          ></Column>
          <Column
            field="created_at"
            header="Created date"
            style={{ width: "20%" }}
            sortable
          ></Column>
          <Column
            field="actions"
            header="Actions"
            style={{ width: "5%" }}
            className="w-full"
            body={(rowData) => actionTemplate(rowData)}
          ></Column>
        </DataTable>
      </div>
    </Fragment>
  );
}
