import useApi from "services/api";
import { API_URL } from "../constants";

const GetUserCredits = () => {
  const { get, data, loading, error } = useApi();

  const getCurrentUserCredits = async () => {
    const data = await get(`${API_URL}/current-user-credit-balance`);
    return data;
  };

  const getUserCreditHistory = async () => {
    const data = await get(`${API_URL}/current-user-credit-history?page=1&page_size=100`);
    return data;
  };

  return { getCurrentUserCredits, getUserCreditHistory, data, loading, error };
};

export default GetUserCredits;
