import { useState } from "react";
import { Button } from "primereact/button";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../Sidebar/sidebar.css";
import useMediaQuerry from "../../hooks/useMediaQuerry";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { Image } from "primereact/image";

const SidebarComponent = ({ menuItems, selectedItem, view }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { isMobile } = useMediaQuerry();

  return (
    <div
      className={`${isMobile ? "custom-sidebar-mobile" : "custom-sidebar"} ${view === "dashboard" ? "border-noround w-full" : ""}  ${visible ? "open" : "closed"}`}
    >
      <div
        className={` flex-col ${view === "dashboard" ? "mt-0" : isMobile ? "flex" : "grid mt-3"}`}
      >
        {view === "dashboard" && (
          <Image
            src={logo}
            className={`gap-3 text-xs mt-2 sidebar-button`}
            onClick={() => {
              navigate("/");
            }}
          />
        )}
        {menuItems.map((item, index) => (
          <Button
            key={index}
            label={item.label}
            icon={item.icon}
            className={`${selectedItem === item.label.toLowerCase() ? "text-blue-500 bg-white" : "text-white"} text-center gap-3 text-xs mt-2 sidebar-button`}
            onClick={item.command}
          />
        ))}
      </div>
    </div>
  );
};

export default SidebarComponent;
