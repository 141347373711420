// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-disclaimer {
    .p-dialog-content {
      padding: 0;
    }
    .welcome-container {
      background: linear-gradient(180deg, #eff4fd 0%, #ffffff 100%);
    }
  }

@media screen and (max-width: 767px) {
    .login-disclaimer {
      width: 80% !important;
    }
  }

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .login-disclaimer {
      width: 60% !important;
    }
  }

  @media screen and (min-width: 1024px) {
    .login-disclaimer {
      width: 60% !important;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Login/Disclaimer.css"],"names":[],"mappings":"AAAA;IACI;MACE,UAAU;IACZ;IACA;MACE,6DAA6D;IAC/D;EACF;;AAEF;IACI;MACE,qBAAqB;IACvB;EACF;;AAEF;IACI;MACE,qBAAqB;IACvB;EACF;;EAEA;IACE;MACE,qBAAqB;IACvB;EACF","sourcesContent":[".login-disclaimer {\n    .p-dialog-content {\n      padding: 0;\n    }\n    .welcome-container {\n      background: linear-gradient(180deg, #eff4fd 0%, #ffffff 100%);\n    }\n  }\n\n@media screen and (max-width: 767px) {\n    .login-disclaimer {\n      width: 80% !important;\n    }\n  }\n\n@media screen and (min-width: 768px) and (max-width: 1023px) {\n    .login-disclaimer {\n      width: 60% !important;\n    }\n  }\n\n  @media screen and (min-width: 1024px) {\n    .login-disclaimer {\n      width: 60% !important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
