import { Dialog } from "primereact/dialog";
import React, { memo, useRef, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import "./FactSheet.css";
import MediumConfidence from "../../../assets/SpaceIcons/confidence/MediumConfidence.svg";
import LowConfidence from "../../../assets/SpaceIcons/confidence/LowConfidence.svg";
import HighConfidence from "../../../assets/SpaceIcons/confidence/HighConfidence.svg";
import { Menu } from "primereact/menu";
import PropTypes from "prop-types";

const defaultData = [
  {
    title: "Employee Demographics",
    generatedOn: new Date(),
    facts: [
      {
        parameter: "Employee Age",
        confidence: "High",
        action: "Pulse Notification",
        extractedData: [
          {
            value: "37 Years",
            confidence: 1,
          },
          {
            value: "32 Years",
            confidence: 2,
          },
          {
            value: "35 Years",
            confidence: 3,
          },
        ],
      },
      {
        parameter: "Nationality",
        confidence: "High",
        action: "Pulse Notification",
        extractedData: [
          {
            value:
              "Candidate is residing in India from past 7 years, but having nationality of United Arab Emirates.",
            confidence: 1,
          },
        ],
      },

      {
        parameter: "Work Location",
        confidence: "Medium",
        action: "NA",
        extractedData: [
          {
            value: "Mumbai",
            confidence: 2,
          },
        ],
      },
      {
        parameter: "English Proficiency",
        confidence: "Low",
        action: "NA",
        extractedData: [
          {
            value: "Intermediate",
            confidence: 2,
          },
        ],
      },

      {
        parameter: "Disability Status",
        extractedData: [],
      },
    ],
  },
  {
    title: "Attendance and Leave Patterns",
    generatedOn: new Date(),
    facts: [
      {
        parameter: "Attendance Rate",
        confidence: "High",
        action: "NA",
      },
    ],
  },
];

const menuItems = [
  {
    label: "Export as CSV",
  },
  {
    label: "Export as PDF",
  },
];

const FactsChecklistReport = ({ visible, onHide }) => {
  const data = defaultData;
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <Dialog
      className="facts-checklist-report"
      visible={visible}
      onHide={onHide}
      header={
        <div className="flex flex-column gap-1">
          <p className="p-0 m-0 text-base font-bold">Factsheet</p>
          <p className="p-0 m-0 text-xs text-gray-500 font-normal">
            You&apos;re viewing consolidated checklist report at case level.
          </p>
        </div>
      }
      draggable={false}
      resizable={false}
      pt={{
        content: {
          className: isMenuOpen ? "overflow-hidden" : "",
        },
      }}
    >
      <Accordion activeIndex={0}>
        {data.map((item) => (
          <AccordionTab
            key={item.title}
            pt={{
              header: {
                className: "border-round-md p-3",
              },
              content: {
                className: "border-0 px-4 py-2 mb-2",
              },
            }}
            header={
              <span className="flex align-items-center justify-content-between gap-2 w-full ml-2">
                <div className="flex flex-column gap-2">
                  <p className="p-0 m-0 text-sm font-bold">{item.title}</p>
                  <p className="p-0 m-0 text-xs text-gray-500 font-normal">
                    Generated on : {moment(item.generatedOn).format("DD/MM/YYYY, HH:mm:ss")}
                  </p>
                </div>

                <div className="flex align-items-center gap-1">
                  <Button
                    className="text-sm action-button"
                    link
                    label="Export"
                    icon="pi pi-chevron-down"
                    iconPos="right"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      menuRef.current.toggle(e);
                    }}
                  />
                  <Menu
                    ref={menuRef}
                    model={menuItems}
                    popup
                    appendTo={document.body}
                    position="fixed"
                    onShow={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsMenuOpen(true);
                    }}
                    onHide={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setIsMenuOpen(false);
                    }}
                  />
                  <Button
                    className="text-sm action-button"
                    link
                    label="Run Again"
                    icon="pi pi-play"
                  />
                </div>
              </span>
            }
          >
            <DataTable value={item.facts}>
              <Column
                field="parameter"
                header="Parameter"
                style={{ width: "25%" }}
                body={(row) => {
                  return <p className="p-0 m-0 text-sm">{row.parameter}</p>;
                }}
              />
              <Column
                field="extractedData"
                header="Extracted Data"
                style={{ width: "35%" }}
                body={(row) => {
                  return (
                    <div className="flex gap-3">
                      {row?.confidence === "Low" && (
                        <span className="pi pi-exclamation-triangle text-red-600" />
                      )}
                      <div className="flex flex-column gap-2 facts-checklist-report-extracted-data">
                        {row?.extractedData?.length ? (
                          row.extractedData.map((data) => (
                            <div className="p-0 m-0 text-xs font-normal flex" key={data.value}>
                              <p className="p-0 m-0 flex-1">{data.value}</p>
                              <span className="confidence-number bg-gray-200 border-circle">
                                {data.confidence}
                              </span>
                            </div>
                          ))
                        ) : (
                          <div className="flex align-items-center gap-3">
                            <span className="pi pi-exclamation-triangle text-red-600" />
                            <p className="p-0 m-0 text-xs font-normal">Not found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }}
              />
              <Column
                field="confidence"
                header="Confidence"
                style={{ width: "10%" }}
                body={(row) => {
                  if (!row.confidence) return null;
                  return (
                    <div className="flex align-items-center gap-2">
                      <img
                        src={
                          row.confidence === "High"
                            ? HighConfidence
                            : row.confidence === "Medium"
                              ? MediumConfidence
                              : LowConfidence
                        }
                        alt="confidence"
                        width={20}
                        height={22}
                      />
                      <p
                        className={`p-0 m-0 text-xs font-normal ${row.confidence === "High" ? "text-green-600" : row.confidence === "Medium" ? "text-yellow-600" : "text-red-600"}`}
                      >
                        {row.confidence}
                      </p>
                    </div>
                  );
                }}
              />
              <Column
                field="action"
                header="Action"
                style={{ width: "20%" }}
                body={(row) => {
                  if (!row.action) return null;
                  if (row.action === "Pulse Notification") {
                    return (
                      <div className="flex align-items-center gap-2">
                        <span className="pi pi-bell text-blue-600" />
                        <p className="p-0 m-0 text-xs font-normal">{row.action}</p>
                      </div>
                    );
                  }
                  return <p className="p-0 m-0 text-xs font-normal">{row.action}</p>;
                }}
              />
            </DataTable>
          </AccordionTab>
        ))}
      </Accordion>
    </Dialog>
  );
};

FactsChecklistReport.propTypes = {
  visible: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default memo(FactsChecklistReport);
