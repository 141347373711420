import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { connect, StringCodec } from 'nats.ws';
import bellIcon from '../../assets/Header/BellIcon.svg';

const Notifications = ({ userInfo, authenticated }) => {
  const notificationPanel = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationUnreadCount, setNotificationUnreadCount] = useState(0);
  const natsConnection = useRef(null);
  const toast = useRef(null);

  const notificationTemplate = (notification, index, notificationsLength) => {
    return (
      <div className={`my-2 ${!notification.notificationRead ? 'bg-blue-50' : ''}`}>
        <div className="my-1 font-medium text-black">{notification.header}</div>
        <div className="my-2 mr-2 font-thin text-sm text-gray-500 leading-loose">
          {notification.body}
        </div>
        {notification.link && (
          <Button 
            severity="secondary" 
            outlined 
            className="my-1 text-base text-gray-500 border-gray-300 font-extralight" 
            onClick={() => window.open(notification.link, '_blank')}
          >
            Reupload
          </Button>
        )}
        {index !== notificationsLength - 1 && 
          <hr className="hr-popup-border border-gray-200 my-2 -mx-4" />
        }
      </div>
    );
  };

  const markAllNotificationsAsRead = () => {
    setNotifications(prev => 
      prev.map(notification => ({ ...notification, notificationRead: true }))
    );
  };

  // NATS connection and notifications logic
  useEffect(() => {
    const unreadCount = notifications.filter(n => !n.notificationRead).length;
    setNotificationUnreadCount(unreadCount);
  }, [notifications]);

  useEffect(() => {
    let isSubscribed = true;

    const connectToNats = async () => {
        try {
            if (!natsConnection.current) {
              natsConnection.current = await connect({
                servers: process.env.REACT_APP_NATS_WS_URL || "ws://localhost:4222",
                reconnect: true,
                maxReconnectAttempts: -1,
                reconnectTimeWait: 2000,
              });
    
              console.log('Connected to NATS server');
    
              natsConnection.current.closed().then(() => {
                console.log('NATS connection closed');
                natsConnection.current = null;
              });
            }
    
            const sc = StringCodec();
            const sub = natsConnection.current.subscribe(`notifications.${userInfo?.email}.>`, {
              durable: `notifications-${userInfo?.email}-${Date.now()}`,
              manualAck: false,
              ackWait: 20000,
              maxDeliver: 5,
              idleHeartbeat: 15000,
            });
            
            (async () => {
              for await (const msg of sub) {
                if (!isSubscribed) break;
                
                try {
                  const notification = JSON.parse(sc.decode(msg.data));
                  
                  // Add timestamp, ID, and read status
                  const notificationWithId = {
                    ...notification,
                    id: notification.id || Date.now(),
                    timestamp: new Date().toISOString(),
                    notificationRead: false // Default to unread
                  };
    
                  setNotifications(prev => {
                    const maxNotifications = 100;
                    return [notificationWithId, ...prev].slice(0, maxNotifications);
                  });
    
                  toast.current.show({
                    severity: "info",
                    summary: notification.header,
                    detail: notification.body,
                    life: 3000,
                  });
                } catch (error) {
                  console.error("Error processing notification:", error);
                }
              }
            })();
    
            return () => {
              isSubscribed = false;
              sub.unsubscribe();
            };
          } catch (error) {
            console.error("NATS connection error:", error);
            toast.current.show({
              severity: "error",
              summary: "Connection Error",
              detail: "Failed to connect to notification service. Retrying...",
              life: 3000,
            });
    
            setTimeout(connectToNats, 5000);
          }
    };

    if (authenticated && userInfo?.sub) {
      connectToNats();
    }

    return () => {
      isSubscribed = false;
      if (natsConnection.current) {
        natsConnection.current.close();
        natsConnection.current = null;
      }
    };
  }, [authenticated, userInfo?.sub]);

  return (
    <div>
      <Toast ref={toast} />
      <button
        onClick={(e) => notificationPanel.current.toggle(e)}
        style={{
          textDecoration: 'none',
          position: 'relative',
          display: 'inline-block',
          borderRadius: '2px',
        }}
        className="mr-3 p-link inline-flex justify-content-center align-items-center h-1rem w-3rem hover:bg-white-alpha-10 transition-all transition-duration-200 relative border-none"
      >
        <img src={bellIcon} alt="notifications" />
        {notificationUnreadCount > 0 && (
          <span
            style={{
              position: 'absolute',
              top: '-10px',
              right: '-1px',
              padding: '2px 5px',
              borderRadius: '50%',
              backgroundColor: 'red',
              color: 'white',
              fontSize: '10px',
            }}
          >
            {notificationUnreadCount > 99 ? '99+' : notificationUnreadCount}
          </span>
        )}
      </button>
      <OverlayPanel
        ref={notificationPanel}
        onShow={markAllNotificationsAsRead}
      >
        <div className="font-medium text-base text-black my-2">
          {notifications.length > 0 ? "Notifications" : "No Notifications"}
        </div>

        <div 
          className="flex flex-column gap-2" 
          style={{ 
            maxHeight: '22vw',
            overflowY: 'auto',
            overflowX: 'hidden',
            width: '28vw'
          }}
        >
          {notifications.map((notification, index) => (
            <div key={index}>
              {notificationTemplate(notification, index, notifications.length)}
            </div>
          ))}
        </div>
      </OverlayPanel>
    </div>
  );
};

Notifications.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string,
    sub: PropTypes.string
  }),
  authenticated: PropTypes.bool.isRequired,
  toast: PropTypes.object.isRequired
};

export default Notifications; 