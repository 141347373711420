import { useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import useMediaQuerry from "hooks/useMediaQuerry";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.3.136/pdf.worker.min.mjs`;

const CustomPDFRenderer = ({ mainState }) => {
  const { currentDocument } = mainState;
  const [numPages, setNumPages] = useState(null);
  const { isMobile } = useMediaQuerry();
  const [zoom, setZoom] = useState(isMobile ? 0.5 : 1.6);

  if (!currentDocument) return null;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.1); // Increase zoom level
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(0.1, prevZoom - 0.1)); // Decrease zoom level, prevent negative zoom
  };

  return (
    <div id="my-pdf-renderer">
      <Document
        file={currentDocument.uri}
        onLoadError={(error) => console.error("Error loading document:", error)}
        onSourceError={(error) => console.error("Error with document source:", error)}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={zoom} />
        ))}
      </Document>
      <div className="zoom-controls">
        <i onClick={handleZoomOut} className="pi pi-search-minus p-2 text-sm"></i>
        <i onClick={handleZoomIn} className="pi pi-search-plus  p-2  text-sm"></i>
      </div>
    </div>
  );
};

CustomPDFRenderer.fileTypes = ["pdf", "application/pdf"];
CustomPDFRenderer.weight = 1;

export default CustomPDFRenderer;
