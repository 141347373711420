import React, { useRef, useState } from "react";
import "./usermanagement.css";
import { SelectButton } from "primereact/selectbutton";
import useMediaQuerry from "../../../hooks/useMediaQuerry";
import DialogUploaderComponent from "components/DocumentEngine/WorkspaceSection/DialogUploaderComponent";
import UserPanels from "components/Dashboard/UserManagement/UsersPanels";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import AddNewUser from "components/Dashboard/UserManagement/AddNewUser";

const UserManagement = () => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("Users");
  const [currentTab, setCurrentTab] = useState("users");
  const [fetchData, setFetchData] = useState(false);
  const { isMobile } = useMediaQuerry();
  const toast = useRef(null);
  const [isAddNewUser, setIsAddNewUser] = useState(false);

  const justifyOptions = [
    { icon: "pi pi-align-left", value: "Users", alias: "users" },
    { icon: "pi pi-align-justify", value: "Groups", alias: "groups" },
  ];

  const items = [
    {
      label: "Add New User",
      icon: "pi pi-user",
      command: () => {
        setIsAddNewUser(true);
      },
    },
    {
      label: "Add New Group",
      icon: "pi pi-users",
    },
  ];

  const justifyUserManagement = (option) => {
    return option.value;
  };

  return (
    <div className={`surface-0`}>
      <div
        className={`flex ${!isMobile && "align-items-center"} flex-column lg:justify-content-between lg:flex-row`}
      >
        <Toast ref={toast} />
        <div className="templatesHeader">
          <div className="flex mt-5 w-full">
            <SelectButton
              value={value}
              onChange={(e) => {
                const selectedOption = justifyOptions.find((option) => option.value === e.value);
                setValue(e.value);
                setCurrentTab(selectedOption.alias);
              }}
              itemTemplate={justifyUserManagement}
              optionLabel="value"
              options={justifyOptions}
              allowEmpty={false}
              className={`${isMobile ? "ml-3" : ""}`}
            />
            {/* {isMobile && (
              <Button
                icon="pi pi-plus"
                className="text-sm text-white bg-blue-500 ml-2"
                onClick={() => {
                  setVisible(true);
                }}
              />
            )} */}
          </div>
          {currentTab === "myTemplate" && (
            <div className="flex align-items-center text-700 flex-wrap">
              <div
                className={`${isMobile ? "ml-4" : "mr-5"} flex align-items-center mt-3 text-sm inter-light`}
              >
                <span>Maximum of 10 files can be uploaded</span>
              </div>
            </div>
          )}
        </div>
        <DialogUploaderComponent />
        <div className="mt-3 lg:mt-0">
          {!isMobile && (
            <SplitButton label="Add New" icon="pi pi-plus" className="text-sm" model={items} />
          )}
        </div>
      </div>
      <AddNewUser toast={toast} isVisible={isAddNewUser} setIsVisible={setIsAddNewUser} />
      <UserPanels
        currentTab={currentTab}
        fetchData={fetchData}
        visible={visible}
        setVisible={setVisible}
        setFetchData={setFetchData}
      />
    </div>
  );
};

export default UserManagement;
