import { Accordion, AccordionTab } from "primereact/accordion";
import { Timeline } from "primereact/timeline";
import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Button } from "primereact/button";
import { secondsToHoursMinutesSeconds, secondsToHoursMinutesWithSeconds } from "utils/helper";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import RuleNotificationSidebar from "./ruleNotificationSidebar";
// import Feed from "models/feed";
import { usePulseApi } from "hooks/pulseConfig";
import { useLocation } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import NoAlerts from "../AlertTab/AlertTabList/icons/NoAlerts";
import useSpaces from "../../../../hooks/useSpace";

const NotificationConfigTab = ({ userData, allRuleData }) => {
  const [allNotificationListData, setAllNotificationListData] = useState([]);
  const [allRuleIdListData, setAllRuleIdListData] = useState([]);
  const [enableEditSidebar, setEnableEditSidebar] = useState(false);
  const [updateSidebarData, setUpdateSidebarData] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const location = useLocation();
  const { getRulesForNotification } = usePulseApi();
  const { getSpaces } = useSpaces();
  const [spaceList, setSpaceList] = useState([]);
  const [getNamespaceAsset, setNamespaceAsset] = useState({
    namespace_id: "",
    asset_id: "",
  });

  useEffect(() => {
    const fetchSpaces = async () => {
      const spaces = await getSpaces(1, 100, "", "", "", {});
      setSpaceList(spaces?.items);
    };
    if (spaceList?.length === 0) {
      fetchSpaces();
    }
  }, []);

  const Badge = ({ text, className = "" }) => {
    return (
      <span
        className={`inline-flex text-left bg-[#f1f1f1] text-sm mt-1 w-fit px-2 py-1 rounded-full text-xs md:text-sm ${className}`}
      >
        {text}
      </span>
    );
  };

  const mapUserIdsToKeyValuePairs = (alerts, users, knowledgeSpace) => {
    return alerts?.map((alert) => ({
      ...alert,
      // Convert old SPOC format to detail_levels if not present
      detail_levels:
        alert.detail_levels ||
        [
          {
            level: 1,
            users:
              alert.primary_spoc?.map((id) => {
                const user = users?.find((user) => user.value === id);
                return user ? { label: user.label, value: user.value } : { label: id, value: id };
              }) || [],
            escalation_time: 0,
          },
          alert.secondary_spoc?.users?.length > 0 && {
            level: 2,
            users:
              alert.secondary_spoc?.users?.map((id) => {
                const user = users?.find((user) => user.value === id);
                return user ? { label: user.label, value: user.value } : { label: id, value: id };
              }) || [],
            escalation_time: alert.secondary_spoc?.escalation_time || 0,
          },
          alert.tertiary_spoc?.users?.length > 0 && {
            level: 3,
            users:
              alert.tertiary_spoc?.users?.map((id) => {
                const user = users?.find((user) => user.value === id);
                return user ? { label: user.label, value: user.value } : { label: id, value: id };
              }) || [],
            escalation_time: alert.tertiary_spoc?.escalation_time || 0,
          },
        ].filter(Boolean),

      // Map knowledge space
      knowledge_space: alert.knowledge_space?.map((id) => {
        const knowledge = knowledgeSpace?.find((item) => item.value === id);
        return knowledge
          ? { label: knowledge.label, value: knowledge.value }
          : { label: id, value: id };
      }),
    }));
  };

  const fetchData = async () => {
    setLoading(true);
    let namespace_id = `${getNamespaceAsset?.namespace_id ||
      (location?.state?.sourceRowData?.namespace_id !== null
        ? location?.state?.sourceRowData?.namespace_id
        : location?.state?.sourceId)
      }`;
    let asset_id = `${getNamespaceAsset?.asset_id ||
      (location?.state?.sourceRowData?.asset_id !== null
        ? location?.state?.sourceRowData?.asset_id
        : location?.state?.sourceRowData?.id)
      }`;
    let ruleQuerySearch = "";
    const ruleData = await getRulesForNotification(asset_id, namespace_id, ruleQuerySearch);
    const transformedData = allRuleData?.map((item) => ({
      label: `Rule ID ${item.id}`,
      value: item.id,
    }));

    setAllRuleIdListData(transformedData);

    const updatedAlerts = mapUserIdsToKeyValuePairs(
      ruleData?.results?.data?.alert_definitions,
      userData?.userList,
      userData?.knowledgeSpace,
    );
    setAllNotificationListData(updatedAlerts);
    if (namespace_id && asset_id) {
      setNamespaceAsset({
        namespace_id: namespace_id,
        asset_id: asset_id,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSuccess = (success) => {
    if (success) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: success,
      });
      fetchData();
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Somthing Went Wrong Try After Some Time",
      });
    }
  };

  const handleEdit = (editItem) => {
    setUpdateSidebarData({
      ...editItem,
      detail_levels: editItem.alert_detail_levels,
    });
    setEnableEditSidebar(true);
  };

  const renderLevelContent = (level, item) => {

    const levelData = item.alert_detail_levels
      ?.filter((e) => e?.users?.length)
      ?.find((l) => l.level === level) || {
      level,
      users:
        level === 1
          ? item.primary_spoc
          : level === 2
            ? item.secondary_spoc?.users
            : item.tertiary_spoc?.users,
      escalation_time:
        level === 1
          ? 0
          : level === 2
            ? item.secondary_spoc?.escalation_time
            : item.tertiary_spoc?.escalation_time,
    };

    if (!levelData?.users?.length) return null;

    return {
      title: `Level ${level} ${level === 1 ? "Respondent" : "Escalation"}`,
      content: (
        <div className="flex flex-col gap-2">
          <span className="text-sm text-title_black font-bold mb-1">{`Level ${level} Respondents`}</span>

          {level !== 1 && (
            <>
              <p className="text-sm text-neutral-grey-500">L{level} Escalation Time</p>
              <div className="flex gap-2 flex-row flex-wrap">
                <span className="text-sm bg-neutral-grey-300 inline-block rounded-2xl">
                  <Badge
                    text={secondsToHoursMinutesWithSeconds(levelData.escalation_time)}
                    className="text-gray-600"
                  />
                </span>
              </div>
            </>
          )}

          <p className="text-sm text-title_black ">L{level} Respondents</p>
          <div className="flex gap-2 flex-row flex-wrap">
            {levelData.users?.map((user, idx) => (
              <span key={idx} className="text-sm bg-neutral-grey-300 inline-block rounded-2xl">
                <Badge
                  text={(userData?.userList || [])?.find((e) => e.value === user)?.label || "-"}
                  className="text-gray-600"
                />
              </span>
            ))}
          </div>
          <Divider
            style={{
              margin: "0.5rem 0", // my-2
              backgroundColor: "#d1d5db", // border-gray-300
            }}
          />
        </div>
      ),
    };
  };

  // Function to get the name from spaceList
  const getSpaceNameById = (id) => {
    const space = spaceList.find((space) => space.id === id);
    return space ? space.name : id;
  };

  return (
    <div className="overflow-y-auto">
      {enableEditSidebar && (
        <RuleNotificationSidebar
          visible={enableEditSidebar}
          setVisible={setEnableEditSidebar}
          onSuccess={handleSuccess}
          allUserData={userData}
          allRuleData={allRuleIdListData}
          updateSidebarData={updateSidebarData}
          setUpdateSidebarData={setUpdateSidebarData}
          getNamespaceAsset={getNamespaceAsset}
          spaceList={spaceList}
        />
      )}
      <div>
        {loading ? (
          <>
            <div className="mt-20 flex flex-col space-y-4 overflow-y-auto">
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
              <Skeleton className="h-4 w-full" />
            </div>
          </>
        ) : (
          <>
            {allNotificationListData?.length > 0 ? (
              <div div className="relative">
                <div className="mt-4">
                  <div className=" ml-auto flex justify-end">
                    <Button
                      style={{
                        width: "fit-content",
                        backgroundColor: "#1581ED",
                        fontWeight: "normal",
                        borderRadius: "6px",
                        padding: "0.5rem 0.75rem", // equivalent to px-3 py-2
                        color: "white",
                        marginLeft: "10%",
                        fontSize: "0.875rem", // equivalent to text-sm
                        marginTop: "10px",
                        height: "7vh",
                        fontWeight: "bold",
                        paddingTop: "10px",
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                      onClick={() => setEnableEditSidebar(true)}
                    >
                      Add New Configuration
                    </Button>
                  </div>
                </div>
                <Accordion className="mt-5 h-[25rem] mb-[2rem] overflow-y-auto ">
                  {allNotificationListData?.map((item, index) => (
                    <AccordionTab
                      key={index}
                      className="custom-tab"
                      header={
                        <div className="flex justify-between items-center w-full">
                          <span>{`Notification Configuration ID ${item.id}`}</span>
                          <Button
                            icon="pi pi-pencil"
                            className="p-button-text p-button-plain text-blue-500"
                            style={{ borderRadius: "7px", height: "22px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleEdit(item);
                            }}
                          />
                        </div>
                      }
                    >
                      {/* Responsive Grid Container for Left-Right section */}
                      <div className="grid grid-cols-1 md:grid-cols-[1fr_auto_1fr] items-stretch h-full">
                        {/* Left Side */}
                        <div className="flex flex-col gap-6 w-[45%]" style={{ marginLeft: "15px" }}>
                          <div className="flex flex-col text-sm mt-2 ml-3">
                            <span className=" font-normal mb-1">Notification Message</span>
                            <span className="w-fit text-sm bg-neutral-grey-300 rounded-2xl">
                              <Badge text={item.alert_message} className="text-gray-600" />
                            </span>
                          </div>

                          <div className="flex justify-start items-start">
                            <Timeline
                              value={[1, 2, 3]
                                .map((level) => renderLevelContent(level, item))
                                .filter(Boolean)}
                              content={(item) => item.content}
                              className="flex justify-start items-stretch left-aligned"
                              marker={() => {
                                const isOnlyL1 =
                                  item.alert_detail_levels?.filter((e) => e?.users?.length)
                                    ?.length === 1;

                                return (
                                  <span
                                    className={`w-2 h-2 border-2 rounded-full inline-block  ${isOnlyL1
                                      ? "bg-white border-white"
                                      : "bg-blue-500 border-blue-500"
                                      }`}
                                  ></span>
                                );
                              }}
                            />
                          </div>
                        </div>

                        <div className="w-[1px] bg-[#dee2e6] h-auto self-stretch"></div>

                        {/* Right Side */}
                        <div className="flex flex-col gap-2 ml-5 w-[50%]">
                          {/* Alert Rule ID */}
                          <div className="flex flex-col ml-2">
                            <span className="text-sm font-bold mb-1 mt-3">Associated Rule</span>
                            {item.alert_rules?.length > 0 ? (
                              item.alert_rules.map((rule, idx) => (
                                <span
                                  key={idx}
                                  className="text-sm bg-neutral-grey-300 inline-block px-1 py-1 rounded-2xl"
                                >
                                  <Badge text={`Rule ID ${rule?.id}`} className="text-gray-600" />
                                </span>
                              ))
                            ) : (
                              <span
                                className="text-sm text-neutral-grey-500"
                                style={{ marginTop: "0.6vh" }}
                              >
                                No Associated Rules Added
                              </span>
                            )}
                          </div>
                          <Divider
                            style={{
                              backgroundColor: "#d1d5db", // border-gray-300
                            }}
                          />

                          <div className="flex flex-col ml-2">
                            <span className="text-sm font-bold mb-1 mt-3">Duration</span>
                            {!item?.frequency_type ? (
                              <span className="text-sm text-neutral-grey-500">
                                No Duration Added
                              </span>
                            ) : (
                              <span className="text-sm text-neutral-grey-500">
                                {item?.frequency_type}
                              </span>
                            )}
                          </div>

                          <Divider
                            style={{
                              backgroundColor: "#d1d5db", // border-gray-300
                            }}
                          />

                          {/* Knowledge Space */}
                          <div className="flex flex-col ml-2" style={{ marginBottom: "10px" }}>
                            <span className="text-sm font-bold mb-1 mt-0">Attached Context</span>
                            <div className="flex gap-2 flex-row flex-wrap" style={{ marginTop: "0.6vh" }}>
                              {item.knowledge_space?.length > 0 ? (
                                item.knowledge_space.map((space, idx) => (
                                  <span
                                    key={idx}
                                    className="text-sm bg-neutral-grey-300 inline-block px-1 py-1 rounded-2xl"
                                  >
                                    <Badge text={getSpaceNameById(space.value)} className="text-gray-600" />
                                  </span>
                                ))
                              ) : (
                                <span className="text-sm text-neutral-grey-500">
                                  No Context Attached
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionTab>
                  ))}
                </Accordion>
              </div>
            ) : (
              <>
                {" "}
                <div className="flex flex-col w-full p-4 mt-20">
                  <div className="flex flex-col items-center justify-center mr-[20%] mt-10">
                    <div className="ml-[10%]">
                      <NoAlerts />
                    </div>
                    <span className="text-xl ml-[10%] font-semibold mt-[2vh]">
                      No Notification Configured
                    </span>
                    <Button
                      style={{
                        width: "fit-content",
                        backgroundColor: "#1581ED",
                        fontWeight: "normal",
                        borderRadius: "6px",
                        padding: "0.5rem 0.75rem",
                        color: "white",
                        marginLeft: "10%",
                        fontSize: "0.875rem",
                        marginTop: "2vh",
                      }}
                      onClick={() => setEnableEditSidebar(true)}
                    >
                      Add New Configuration
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <Toast ref={toast} position="top-right" />
    </div>
  );
};

export default NotificationConfigTab;
