import React, { createContext, useState, useContext } from "react";

const UploaderContext = createContext();

export const UploadProgressProvider = ({ children }) => {
  const [uploadProgress, setUploadProgress] = useState({});

  return (
    <UploaderContext.Provider value={{ uploadProgress, setUploadProgress }}>
      {children}
    </UploaderContext.Provider>
  );
};

export const useUploadProgress = () => useContext(UploaderContext);
