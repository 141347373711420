// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css or similar file */
.pdf-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Make sure it's above other content */
}

.pdf-view-container {
  height: 95%;
}
.pdf-overlay-content {
  background: white;
  padding: 40px;
  /* overflow: auto; */
  position: relative;
}

.content-desktop {
  width: 70%;
  height: 95%;
  border-radius: 8px;
}

.content-mobile {
  width: 90%;
  height: 95%;
  border-radius: 4px;
}

.pdf-overlay-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: black;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: inline-block;
  content: "\\00d7";
}

.zoom-controls {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/components/DocumentEngine/FileViewer/Pdf.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,uCAAuC;AACzC;;AAEA;EACE,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,aAAa;EACb,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,2BAA2B;EAC3B,0CAA0C;EAC1C,aAAa;EACb,kBAAkB;EAClB,wCAAwC;EACxC,WAAW;AACb","sourcesContent":["/* styles.css or similar file */\n.pdf-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.8);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n  /* Make sure it's above other content */\n}\n\n.pdf-view-container {\n  height: 95%;\n}\n.pdf-overlay-content {\n  background: white;\n  padding: 40px;\n  /* overflow: auto; */\n  position: relative;\n}\n\n.content-desktop {\n  width: 70%;\n  height: 95%;\n  border-radius: 8px;\n}\n\n.content-mobile {\n  width: 90%;\n  height: 95%;\n  border-radius: 4px;\n}\n\n.pdf-overlay-close {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  color: black;\n  border: none;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  display: inline-block;\n  content: \"\\00d7\";\n}\n\n.zoom-controls {\n  position: fixed;\n  bottom: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n  background-color: rgba(255, 255, 255, 0.8);\n  padding: 10px;\n  border-radius: 5px;\n  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n  z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
