import useApi from "services/api";
import { API_URL } from "../constants";

const useFacts = () => {
    const { data, loading, error, get, post, del, put } = useApi();

    const getFacts = async (spaceId) => {
        const data = await get(
            `${API_URL}/spaces/checklists/${spaceId}`,
        );
        return data?.result;
    };

    const createChecklist = async (checklist) => {
        const data = await post(
            `${API_URL}/spaces/checklist`,
            checklist
        );
        return data;
    };

    const removeChecklist = async (spaceId, checklistId) => {
        const data = await del(
            `${API_URL}/spaces/checklist/${spaceId}/${checklistId}`,
        );
        return data;
    };

    const updateChecklist = async (checklistId, checklist) => {
        const data = await put(
            `${API_URL}/spaces/checklist/${checklistId}`,
            checklist
        );
        return data;
    };
    return { getFacts, createChecklist, removeChecklist, updateChecklist, data, loading, error };
};

export default useFacts;