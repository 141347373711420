import React, { useState, createContext, useContext } from "react";
import { UploadProgressProvider } from "./uploaderContext";
import { UploadedFilesProvider } from "./upladedFilesContext";
import UserInfoProvider from "./UserInfoContext";
import { UserStatusProvider } from "./userStatusContext";
import GetUserCredits from "hooks/getUserCredits";

const SharedStateContext = createContext();

const AppContextProvider = ({ children }) => {
  const [sharedState, setSharedState] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [activePage, setActivePage] = useState("space");
  const [activeAdUsers, setActiveAdUsers] = useState([]);
  const [creditBalance, setCreditBalance] = useState({
    credit_balance: 0,
    total_credits: 0,
    blocked_credits: 0,
  });
  const [creditsLoading, setCreditsLoading] = useState(false);
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [isVerifying, setIsVerifying] = useState(true);

  return (
    <SharedStateContext.Provider
      value={{
        sharedState,
        setSharedState,
        refresh,
        setRefresh,
        activePage,
        setActivePage,
        activeAdUsers,
        setActiveAdUsers,
        creditBalance,
        setCreditBalance,
        creditsLoading,
        setCreditsLoading,
        stripeClientSecret,
        setStripeClientSecret,
        isVerifying,
        setIsVerifying,
      }}
    >
      <UploadProgressProvider>
        <UserInfoProvider>
          <UserStatusProvider>
            <UploadedFilesProvider>{children}</UploadedFilesProvider>
          </UserStatusProvider>
        </UserInfoProvider>
      </UploadProgressProvider>
    </SharedStateContext.Provider>
  );
};

export const useSharedState = () => useContext(SharedStateContext);

export const useRefresh = () => {
  const { refresh, setRefresh } = useSharedState();
  return { refresh, setRefresh };
};

export const useActivePage = () => {
  const { activePage, setActivePage } = useSharedState();
  return { activePage, setActivePage };
};


export const useActiveAdUsers = () => {
  const { activeAdUsers, setActiveAdUsers } = useSharedState();
  return { activeAdUsers, setActiveAdUsers };
};

export const useCreditBalance = () => {
  const { creditBalance, setCreditBalance, creditsLoading, setCreditsLoading } = useSharedState();
  const { getCurrentUserCredits } = GetUserCredits();

  const fetchCreditBalance = async () => {
    const data = await getCurrentUserCredits();
    setCreditBalance(data?.results?.data);
    return data?.results?.data;
  };

  return { creditBalance, setCreditBalance, creditsLoading, setCreditsLoading, fetchCreditBalance };
};

export const useStripeState = () => {
  const { stripeClientSecret, setStripeClientSecret } = useSharedState();
  return { stripeClientSecret, setStripeClientSecret };
};

export default AppContextProvider;
