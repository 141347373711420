import useApi from "services/api";
import useUploaderApi from "services/uploaderApi";
import { API_URL } from "../constants";

/**
 * Custom hook for managing space-related API operations.
 * Provides functions to fetch and delete spaces.
 *
 * @returns {Object} An object containing functions to interact with spaces API
 */
const useSpaceIntegration = () => {
  const { get } = useApi();

  const getOAuthUrl = async (spaceId, redirectUrl) => {
    const url = `${API_URL}/spaces/integration/get-oauth-url?space_id=${spaceId}&redirect_uri=${redirectUrl}`;
    const data = await get(url);
    return data;
  };

  return {
    getOAuthUrl,
  };
};

export default useSpaceIntegration;
