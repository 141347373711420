export const DateTimeFormatter = (dateString) => {
  const pad = (num) => {
    return num < 10 ? "0" + num : num;
  };
  const currentDate = dateString ? new Date(dateString) : new Date();
  const month = pad(currentDate.getMonth() + 1);
  const day = pad(currentDate.getDate());
  const year = currentDate.getFullYear();
  const hours = pad(currentDate.getHours());
  const minutes = pad(currentDate.getMinutes());
  return `${month}/${day}/${year} ${hours}:${minutes}` + dateString;
};

export function convertDateTime(input) {
  if (input instanceof Date) {
    if (isNaN(input.getTime())) {
      throw new Error("Invalid date object.");
    }
    // Extract hours and minutes from the input date
    const hours = input.getHours();
    const minutes = input.getMinutes();

    // Convert hours and minutes into seconds
    return hours * 60 * 60 + minutes * 60;
  } else if (typeof input === "number") {
    let hours = Math.floor(input / 3600);
    let minutes = Math.floor((input % 3600) / 60);
    let date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    return date;
  } else {
    throw new Error(
      "Invalid input type. Please provide a valid Date object or seconds."
    );
  }
}

// convert itme into seconds
export function convertDateTimeWithSeconds(input) {
  if (input instanceof Date) {
    if (isNaN(input.getTime())) {
      throw new Error("Invalid date object.");
    }
    // Extract hours and minutes from the input date
    const hours = input.getHours();
    const minutes = input.getMinutes();
    const seconds = input.getSeconds();

    // Convert hours and minutes into seconds
    return hours * 60 * 60 + minutes * 60 + seconds;
  } else if (typeof input === "number") {
    let hours = Math.floor(input / 3600); // Calculate hours
    let minutes = Math.floor((input % 3600) / 60); // Calculate minutes
    let seconds = input % 60; // Calculate remaining seconds
    let date = new Date(); // Create a new Date object
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds); // Set seconds
    return date;
  } else {
    throw new Error(
      "Invalid input type. Please provide a valid Date object or seconds."
    );
  }
}

// convert seconds into time HH:MM
export const secondsToHoursMinutesSeconds = (dateInSeconds) => {
  const getValue = convertDateTime(dateInSeconds);
  const hours = getValue.getHours();
  const minutes = getValue.getMinutes();
  const timeString = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
  return timeString;
};

// convert seconds into time HH:MM:SS
export const secondsToHoursMinutesWithSeconds = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export function toTitleCase(str) {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
}

export function convertToHyphen(inputString) {
  if (inputString?.length > 0) {
    return inputString.replace(/[^a-zA-Z0-9-_]/g, "_");
  }
  return "";
}

// Outputs: 2/03/2023, 09:15:00
export function formatDateWithTime(dateString, showTimeStamp = true) {
  const date = new Date(dateString);
  const options = showTimeStamp ? {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  } : {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };

  return date.toLocaleDateString("en-GB", options);
}

export const getFileIcon = (fileName) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();

  switch (extension) {
    case 'doc':
    case 'docx':
      return 'pi pi-file-word file-icon-word';
    case 'pdf':
      return 'pi pi-file-pdf file-icon-pdf';
    case 'csv':
    case 'xls':
    case 'xlsx':
      return 'pi pi-file-excel file-icon-excel';
    case 'txt':
    case 'json':
      return 'pi pi-file-o';
    default:
      return 'pi pi-file';
  }
};

export const SkeletonRow = () => {
  return (
    <div className="category-skeleton">
      <div className="category-item-skeleton">
        <div className="flex flex-column gap-2">
          <div className="flex align-items-center justify-between gap-2">
            <div className="flex align-items-center gap-2 overflow-hidden flex-1">
              <div className="skeleton-text" style={{ width: '120px' }}></div>
            </div>
            <div className="skeleton-icon"></div>
          </div>
          <div className="skeleton-text" style={{ width: '60px' }}></div>
        </div>
      </div>

      {[1].map((i) => (
        <div key={i} className="category-item-skeleton">
          <div className="flex flex-column gap-2">
            <div className="flex align-items-center justify-between gap-2">
              <div className="flex align-items-center gap-2 overflow-hidden flex-1">
                <div className="skeleton-color-circle"></div>
                <div className="skeleton-text" style={{ width: '100px' }}></div>
              </div>
              <div className="skeleton-icon"></div>
            </div>
            <div className="skeleton-text" style={{ width: '50px' }}></div>
          </div>
        </div>
      ))}
    </div>
  );
};
