import { createContext, useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { AUTH_TOKEN } from "constants";
import { useKeycloak } from "@react-keycloak/web";
const UserInfoContext = createContext();
// context for maintaining logged in user information
const UserInfoProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});
  // const { keycloak } = useKeycloak();
  // useEffect(() => {
  //   if (keycloak?.token && !userInfo.email) {
  //     const decoded = jwtDecode(keycloak?.token);

  //     setUserInfo({
  //       email: decoded.email,
  //       firstName: decoded.given_name,
  //       lastName: decoded.family_name,
  //     });
  //   }
  // }, [userInfo]);

  return <UserInfoContext.Provider value={{ userInfo }}>{children}</UserInfoContext.Provider>;
};

export const useUserInfo = () => useContext(UserInfoContext);

export default UserInfoProvider;
