import { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { NEIO_ORG } from "constants";

function useApi() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { keycloak } = useKeycloak();
  const { authenticated, token } = keycloak;
  const organization = useMemo(() => {
    const orgData = keycloak?.tokenParsed?.organization;
    if (!orgData) return null;
    const firstOrgId = Object.keys(orgData)[0];
    return orgData[firstOrgId]?.name;
  }, []);
  const abortControllerRef = useRef(null);


  useEffect(() => {
    if (authenticated) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      axios.defaults.headers.common["Organization"] = organization;
    }
  }, [authenticated, token]);

  const fetchData = async (
    url,
    method = "GET",
    body = null,
    contentType = { "Content-Type": "application/json" },
    onUploadProgress = null,
  ) => {
    abortControllerRef.current = new AbortController();

    try {
      setLoading(true);
      setError(null);

      const options = {
        method,
        body: body != null ? JSON.stringify(body) : null,
      };
      const response = await axios(url, {
        headers: contentType,
        method: method,
        data: body,
        onUploadProgress: onUploadProgress || null,
        signal: abortControllerRef.current.signal, // Use the AbortController's signal

      });

      const result = response.data;
      setData(result);
      return result;
    } catch (err) {
      if (err?.response?.status === 401) {
        // keycloak.logout();
      } else if (axios.isCancel(err)) {
        console.log('Request canceled:', err.message);
        setError('Request was cancelled');
        return { cancelled: true };
      }
      setError(err?.response?.data);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  const get = (url, body) => fetchData(url, "GET", body);
  const post = (url, body) => fetchData(url, "POST", body);
  const del = (url, body) => fetchData(url, "DELETE", body);
  const patch = (url, body) => fetchData(url, "PATCH", body);
  const upload = (url, body, contentType, onUploadProgress) =>
    fetchData(url, "POST", body, contentType, onUploadProgress);

  const cancelRequest = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  return { data, loading, error, get, post, del, patch, upload, cancelRequest };
}
export default useApi;
