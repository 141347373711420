import React, { useState } from "react";
import { Button } from "primereact/button";
import "./templates.css";
import TemplatePanels from "components/DocumentEngine/TemplateSection/TemplatePanels";
import { SelectButton } from "primereact/selectbutton";
import useMediaQuerry from "../../../hooks/useMediaQuerry";
import DialogUploaderComponent from "components/DocumentEngine/WorkspaceSection/DialogUploaderComponent";

const Templates = () => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("My Template");
  const [currentTab, setCurrentTab] = useState("myTemplate");
  const [fetchData, setFetchData] = useState(false);
  const { isMobile } = useMediaQuerry();
  const justifyOptions = [
    { icon: "pi pi-align-left", value: "My Template", alias: "myTemplate" },
    { icon: "pi pi-align-justify", value: "Shared Template", alias: "sharedTemplate" },
  ];

  const justifyTemplate = (option) => {
    return option.value;
  };

  return (
    <div className={`surface-0 ${!isMobile && "p-3"} `}>
      <div
        className={`flex ${!isMobile && "align-items-center"} flex-column lg:justify-content-between lg:flex-row`}
      >
        <div className="templatesHeader">
          <div class=" flex mt-5 w-full">
            <SelectButton
              value={value}
              onChange={(e) => {
                const selectedOption = justifyOptions.find((option) => option.value === e.value);
                setValue(e.value);
                setCurrentTab(selectedOption.alias);
              }}
              itemTemplate={justifyTemplate}
              optionLabel="value"
              options={justifyOptions}
              allowEmpty={false}
              className={`${isMobile ? "ml-3" : ""}`}
            />
            {isMobile && (
              <Button
                icon="pi pi-plus"
                className="text-sm text-white bg-blue-500 ml-2"
                // label="New Template"
                onClick={() => {
                  setVisible(true);
                }}
              />
            )}
          </div>
          {currentTab === "myTemplate" && (
            <div className="flex align-items-center text-700 flex-wrap">
              <div
                className={`${isMobile ? "ml-4" : "mr-5"} flex align-items-center mt-3 text-sm inter-light`}
              >
                <span>Maximum of 10 files can be uploaded</span>
              </div>
            </div>
          )}
        </div>
        <DialogUploaderComponent />
        <div className="mt-3 lg:mt-0">
          {!isMobile && (
            <Button
              icon="pi pi-plus"
              className="text-sm text-white bg-blue-500"
              label="New Template"
              onClick={() => {
                setVisible(true);
              }}
            />
          )}
        </div>
      </div>
      <TemplatePanels
        currentTab={currentTab}
        fetchData={fetchData}
        visible={visible}
        setVisible={setVisible}
        setFetchData={setFetchData}
      />
    </div>
  );
};

export default Templates;
