import React from "react";

const WorkspaceIcon = ({ selectedItem }) => {
  return selectedItem === "workspaces" || selectedItem === "agent" ? (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.01"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5 24L0.5 0L24.5 0L24.5 24L0.5 24Z"
        fill="#1D67D5"
      />
      <path
        d="M7.5 22.75C7.09 22.75 6.75 22.41 6.75 22C6.75 21.59 7.09 21.25 7.5 21.25H17.5C17.91 21.25 18.25 21.59 18.25 22C18.25 22.41 17.91 22.75 17.5 22.75H7.5Z"
        fill="#1D67D5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.5 2C4.29 2 2.5 3.79 2.5 6V15C2.5 17.21 4.29 19 6.5 19H18.5C20.71 19 22.5 17.21 22.5 15V6C22.5 3.79 20.71 2 18.5 2H6.5ZM11.5 8.32C11.5 8.87 11.05 9.32 10.5 9.32C9.95 9.32 9.5 8.87 9.5 8.32C9.5 7.77 9.94 7.32 10.49 7.32C11.05 7.32 11.5 7.77 11.5 8.32ZM8.35 8.32C8.35 8.87 7.9 9.32 7.35 9.32C6.8 9.32 6.35 8.87 6.35 8.32C6.35 7.77 6.78 7.32 7.34 7.32C7.9 7.32 8.35 7.77 8.35 8.32ZM7.26 14.86C6.84 14.86 6.5 14.52 6.5 14.11C6.5 13.7 6.83 13.36 7.24 13.36H17.77C18.18 13.36 18.52 13.7 18.52 14.11C18.52 14.52 18.18 14.86 17.76 14.86H7.26ZM14.08 9.07C13.67 9.07 13.33 8.73 13.33 8.32C13.33 7.91 13.67 7.57 14.08 7.57H17.76C18.17 7.57 18.51 7.91 18.51 8.32C18.51 8.73 18.18 9.07 17.76 9.07H14.08Z"
        fill="#1D67D5"
      />
    </svg>
  ) : (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.2302 2C17.8802 2 18.4602 2.02003 18.9802 2.09003C21.7502 2.40003 22.5002 3.70001 22.5002 7.26001V13.58C22.5002 17.14 21.7502 18.44 18.9802 18.75C18.4602 18.82 17.8902 18.84 17.2302 18.84H7.76022C7.11022 18.84 6.53022 18.82 6.01022 18.75C3.24022 18.44 2.49023 17.14 2.49023 13.58V7.26001C2.49023 3.70001 3.24022 2.40003 6.01022 2.09003C6.53022 2.02003 7.11022 2 7.76022 2H17.2302Z"
        stroke="#BAAFC9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0801 8.32007H17.7601"
        stroke="#BAAFC9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.24023 14.11H7.26022H17.7702"
        stroke="#BAAFC9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 22H17.5H7.5Z" fill="#BAAFC9" />
      <path
        d="M7.5 22H17.5"
        stroke="#BAAFC9"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.6947 8.30005H7.70368"
        stroke="#BAAFC9"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9945 8.30005H11.0035"
        stroke="#BAAFC9"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default WorkspaceIcon;
