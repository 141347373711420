import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
import { ProgressSpinner } from "primereact/progressspinner";
import { Outlet, useNavigate } from "react-router-dom";
import SidebarComponent from "./Sidebar/SideBar";
import useMediaQuerry from "../hooks/useMediaQuerry";
import "./IntelliappLayout.css";
import { IntelliAppmenuItems, IntelliAppNavigationMap } from "constants/SidebarConfig";
import Welcome from "pages/DocumentEngine/Welcome/Welcome";
import useApi from "services/api";
import { API_URL } from "constants";
import { useKeycloak } from "@react-keycloak/web";

export default function IntelliappLayout() {
  const { keycloak } = useKeycloak();
  const { authenticated } = keycloak;
  const getPathSelectedItem = () => {
    const path = window.location.pathname;
    const pathParts = path.split('/');
    const lastPart = pathParts[pathParts.length - 1];
    return Object.keys(IntelliAppNavigationMap).includes(lastPart) ? lastPart : "workspaces";
  };

  const [selectedItem, setSelectedItem] = useState(getPathSelectedItem());
  const [shouldShowWelcome, setShouldShowWelcome] = useState(false);
  const { isMobile, isLandScape } = useMediaQuerry();
  const navigate = useNavigate();
  const { get } = useApi();

  useEffect(() => {
    (async () => {
      const hasViewedWelcome = localStorage.getItem("hasViewedWelcome");
      const jobs = await get(`${API_URL}/summary_jobs`);

      if (!hasViewedWelcome && jobs?.results?.data?.length === 0) {
        setShouldShowWelcome(true);
      }
    })();
  }, []);

  const handleClick = (icon) => {
    setSelectedItem(icon);

    if (IntelliAppNavigationMap[icon]) {
      navigate(IntelliAppNavigationMap[icon]);
    }
  };

  const menuItemsConfig = IntelliAppmenuItems.map((item) => ({
    ...item,
    icon: item.icon(selectedItem),
    command: () => {
      const lowerCaseLabel = item.label.toLowerCase();
      setSelectedItem(lowerCaseLabel);
      handleClick(lowerCaseLabel);
    },
  }));

  const onHideWelcome = () => {
    setShouldShowWelcome(false);
    localStorage.setItem("hasViewedWelcome", "true");
  };

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {shouldShowWelcome && <Welcome visible={shouldShowWelcome} onHide={onHideWelcome} />}
      {authenticated ? (
        <div className="layout">
          <div style={{ flex: 1 }} className="flex flex-column contentAreaLayout h-screen">
            <Header menuItems={IntelliAppmenuItems} selectedItem={selectedItem} view="intelliapp" />
            <div
              className={`main-content ${isMobile || isLandScape ? "" : "overflow-hidden"} flex-1`}
            >
              <SidebarComponent menuItems={menuItemsConfig} selectedItem={selectedItem} />
              <div className={`contentArea ${isMobile ? "h-screen" : ""} `}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-content-center h-screen align-items-center">
          <ProgressSpinner />
        </div>
      )}
    </div>
  );
}
