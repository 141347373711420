// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credit-balance {
  .container {
    background: #fff8f3;
    border: 1px solid #feddc7;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 14px;
    padding-block: 10px;
    gap: 10px;
    font-weight: 475;
    margin-right: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Credits/CreditBalance.css"],"names":[],"mappings":"AAAA;EACE;IACE,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;IACpB,mBAAmB;IACnB,SAAS;IACT,gBAAgB;IAChB,kBAAkB;EACpB;AACF","sourcesContent":[".credit-balance {\n  .container {\n    background: #fff8f3;\n    border: 1px solid #feddc7;\n    border-radius: 6px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-inline: 14px;\n    padding-block: 10px;\n    gap: 10px;\n    font-weight: 475;\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
