import { useStripeState } from "context/ContextWrapper";
import { API_URL } from "../constants";

const useStripe = () => {
    const { setStripeClientSecret } = useStripeState();
    const getStripeClientSecretSignUp = async (body) => {
        const response = await fetch(`${API_URL}/checkout/signup`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();
        setStripeClientSecret({ fetchClientSecret: () => Promise.resolve(data.clientSecret) });
    };

    const getSessionStatusSignUp = async (sessionId) => {
        const response = await fetch(`${API_URL}/checkout/signup/session-status?session_id=${sessionId}`);
        const data = await response.json();
        return data;
    };

    return { getStripeClientSecretSignUp, getSessionStatusSignUp };
};

export default useStripe;