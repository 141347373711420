import React, { useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import coins from "../../assets/Header/coins.svg";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import GetUserCredits from "hooks/getUserCredits";
import { Toast } from "primereact/toast";
import moment from "moment";

const CreditSummarySidebar = ({ visible, setIsVisible, creditData }) => {
  const toast = useRef();
  const [search, setSearch] = useState("");
  const { getUserCreditHistory } = GetUserCredits();
  const [creditHistory, setCreditHistory] = useState([]);
  const [categorizedResults, setCategorizedResults] = useState({});

  const results = [
    {
      to_id: "02752ec8-949b-4c0a-974d-c1d2d74a25a2",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "9c78fd29-bee2-437d-809f-77a29a361917",
      credits: 100,
      created_at: "2024-10-11T14:41:01.506167",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "efdd47e6-5746-42e3-adfa-13ace767cf96",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "08c81a91-db17-4d36-bc57-919ceccc3b95",
      credits: 100,
      created_at: "2024-10-08T16:42:46.317708",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "51b78c4e-7cb6-49f0-b0c6-9e1a4cfe5ed4",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "258c1694-14fd-4c34-aaa3-39d6a006ee18",
      credits: 900,
      created_at: "2024-10-08T16:42:46.317708",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "3e0b7c66-cbd1-4831-a115-4d0487d5544f",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "e2d641bd-9cdf-4acc-aedc-c8d0448e4f25",
      credits: 800,
      created_at: "2024-10-08T16:48:00.060262",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "26372644-b6d1-4af2-8f29-2db6aab51bdf",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "a1e4c0f5-a3cf-40cd-b1ad-3b0c130cefa3",
      credits: 200,
      created_at: "2024-10-08T16:53:01.435359",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "8ebaf2b9-d0ea-41e3-97fa-3ed2c8f44140",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "abde6ff2-22f3-4d8e-ae58-f8ad3b544fbb",
      credits: 200,
      created_at: "2024-10-08T16:58:49.739232",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "50396ab6-dc2a-44ab-980d-b10dfb8a7b4a",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "0a4e2a0f-bf2b-4883-8e69-18ca5f95f872",
      credits: 100,
      created_at: "2024-10-08T17:56:30.270446",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "afbb36f3-57c3-49c8-b24a-d509c74eb8fd",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "7617ac83-36fe-4329-9432-95660d4ab408",
      credits: 100,
      created_at: "2024-10-08T19:54:44.969824",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "5df96813-0481-476b-8774-116c5126aa6b",
      credits: 100,
      created_at: "2024-10-08T19:54:44.969824",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "2a290ac9-78f1-4b1b-bb20-9dd2f869fe79",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "415bcc4f-643f-440f-8570-7207220683a8",
      credits: 100,
      created_at: "2024-10-08T20:11:29.702506",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "9c98dde6-5ac3-404a-b451-387c8b00f75b",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "90064387-8075-4cb7-b1dd-8115fb746808",
      credits: 100,
      created_at: "2024-10-08T20:24:35.917901",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "7d4640d9-9a18-4843-b6a0-e6b3480d881c",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "3dcd2b27-d7b9-4d70-8642-113c8067f7b7",
      credits: 100,
      created_at: "2024-10-08T20:24:35.917901",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "cb23100d-e403-46d3-a388-2b6d8c478d27",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "433959e3-bae5-4f85-a17c-c6635d511f42",
      credits: 100,
      created_at: "2024-10-08T21:22:21.622390",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "dde5f4b0-8269-407a-b0a5-dac88da9f023",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "f5775952-e0ef-4768-8cf8-8dacb201dfa4",
      credits: 100,
      created_at: "2024-10-08T21:22:21.622390",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "8faa1e13-4fd4-4f29-ab9b-81e3d316539c",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "e98fad1f-e8d7-4391-9da6-711d250652fb",
      credits: 100,
      created_at: "2024-10-08T21:22:21.622390",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "345ec98d-4c77-479d-8d33-66b0d50bac11",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "e0b07c66-d3f9-40f1-81db-18b4c2f6493a",
      credits: 100,
      created_at: "2024-10-09T10:37:24.967764",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "b571b657-d420-4803-8b8d-60e33c25f257",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "d2947327-87b2-40b9-9f47-671ecd3d77fb",
      credits: 100,
      created_at: "2024-10-09T10:41:05.622074",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "1203674f-8785-459a-831b-fa26c055cd62",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "0f9a1eb0-14c3-4b75-9b4e-2568bd7b2056",
      credits: 100,
      created_at: "2024-10-09T10:52:10.627491",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "fc860018-9197-46f5-85cd-341da7564090",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "458ece46-6d07-49f4-b63c-0be1e0424a96",
      credits: 100,
      created_at: "2024-10-09T10:53:33.663865",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "89647258-3f6a-46d6-a574-2469ab4c30d6",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "597b9618-811c-4673-9dfb-d249dbc6c84e",
      credits: 100,
      created_at: "2024-10-09T11:09:09.075982",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "6c048d24-c4b6-4d84-9d6c-65435b3eb57e",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "a6a955ff-fcb2-4340-bd12-78ec83d7e0ca",
      credits: 100,
      created_at: "2024-10-09T11:11:48.200405",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "680b5603-a2aa-49e9-ac57-303d776a9439",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "05b99c4f-a75e-4e39-8d27-a17961cce946",
      credits: 100,
      created_at: "2024-10-09T11:16:15.974862",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "3e0b7c66-cbd1-4831-a115-4d0487d5544f",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "2d8ee77f-d9da-4355-8aff-145fb95d4704",
      credits: 100,
      created_at: "2024-10-09T13:39:26.859534",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "3e0b7c66-cbd1-4831-a115-4d0487d5544f",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "52bcdaf0-411a-44f7-bc95-09d1f6a4f036",
      credits: 100,
      created_at: "2024-10-09T13:39:26.859534",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "3e0b7c66-cbd1-4831-a115-4d0487d5544f",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "1ee8174b-85ee-42cd-8f9f-3a7c05b98e73",
      credits: 100,
      created_at: "2024-10-09T13:39:26.859534",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "3e0b7c66-cbd1-4831-a115-4d0487d5544f",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "3b7f04e0-8437-4439-8eb5-4db5bb93c027",
      credits: 100,
      created_at: "2024-10-09T13:39:26.859534",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "c9d14182-0c81-475a-83e8-0a7825a972dc",
      credits: 100,
      created_at: "2024-10-09T13:57:51.467878",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "735af575-3d48-4766-9555-0fcd3561165e",
      to_identifier: "user",
      from_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      transaction_type: "rollback",
      app_id: null,
      from_identifier: "user",
      reference_id: "950b2311-c5cc-4a9f-9218-4302843f4c32",
      credits: 200,
      created_at: "2024-10-09T14:10:52.962615",
      organization_id: 1,
      description: "Credits consumed by user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "f540f660-8528-4dae-9615-91b0227b8f06",
      credits: 100,
      created_at: "2024-10-09T14:10:52.962615",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "96d37e20-50e5-4736-9330-3b34968ff2ae",
      credits: 100,
      created_at: "2024-10-09T16:14:26.034444",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "0bc73da2-ef4a-4fe5-b842-73ff77cf0ae5",
      credits: 100,
      created_at: "2024-10-10T07:22:08.825276",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "685de94a-9642-4e1b-a1d8-31d1f5d5d7c2",
      credits: 100,
      created_at: "2024-10-10T07:22:08.825276",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "86b44e2f-fba7-4c7d-b53e-543c1b5e7cdc",
      credits: 100,
      created_at: "2024-10-10T07:22:08.825276",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "735af575-3d48-4766-9555-0fcd3561165e",
      to_identifier: "user",
      from_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      transaction_type: "rollback",
      app_id: null,
      from_identifier: "user",
      reference_id: "33a95d36-4b6d-4d6b-8646-68e797ec131e",
      credits: 500,
      created_at: "2024-10-10T07:31:46.821837",
      organization_id: 1,
      description: "Credits consumed by user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "c71083a8-cf46-48c3-b945-2b128b46ae5c",
      credits: 100,
      created_at: "2024-10-10T07:31:46.821837",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "4e56c1f6-5f19-4ea3-a2c3-19b1aea9b8c4",
      credits: 100,
      created_at: "2024-10-10T07:31:46.821837",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "735af575-3d48-4766-9555-0fcd3561165e",
      to_identifier: "user",
      from_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      transaction_type: "rollback",
      app_id: null,
      from_identifier: "user",
      reference_id: "827eef52-80b5-4b62-ad0e-132a8361b6df",
      credits: 200,
      created_at: "2024-10-10T07:31:46.821837",
      organization_id: 1,
      description: "Credits consumed by user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "a4c253f7-fb00-4e06-9400-fcf25ca43d75",
      credits: 100,
      created_at: "2024-10-10T07:31:46.821837",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "46b1de2f-4969-4fb7-a18f-792b22db603c",
      credits: 100,
      created_at: "2024-10-10T08:20:18.059778",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "514e7cb9-7756-4693-b944-2e13923a4edb",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "84f212f0-602a-42fe-9089-ec7f419a1a48",
      credits: 100,
      created_at: "2024-10-10T08:24:01.655839",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "3e0b7c66-cbd1-4831-a115-4d0487d5544f",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "7c0f0dc2-0e3a-4177-b1de-46239e31cf53",
      credits: 100,
      created_at: "2024-10-10T13:19:03.073991",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "3e0b7c66-cbd1-4831-a115-4d0487d5544f",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "278b67ab-08ed-45e8-a74e-ea4159d8ca21",
      credits: 100,
      created_at: "2024-10-10T13:19:03.073991",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "3e0b7c66-cbd1-4831-a115-4d0487d5544f",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "b9722db0-6b1f-4cfb-8abe-85cb8a2ae6c0",
      credits: 100,
      created_at: "2024-10-10T13:19:03.073991",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "1203674f-8785-459a-831b-fa26c055cd62",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "57570a67-327a-438c-84d3-44c66a08a3cb",
      credits: 100,
      created_at: "2024-10-10T13:19:03.073991",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "1203674f-8785-459a-831b-fa26c055cd62",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "eac7549c-c7e0-4cf2-acb2-b31153f49652",
      credits: 100,
      created_at: "2024-10-10T13:19:03.073991",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "1203674f-8785-459a-831b-fa26c055cd62",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "36a505aa-1e2c-45e1-bf8e-a41871857584",
      credits: 100,
      created_at: "2024-10-10T13:19:03.073991",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "4fccedc8-79cc-4537-bb0e-ee07c207d30f",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "8ff19655-f63f-4ba7-9654-57168683c71a",
      credits: 100,
      created_at: "2024-10-10T14:33:01.977628",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "be94a5cc-3974-4531-adbe-8eed0f46e90e",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "c840cfc3-307e-4368-ae33-4f4b32429089",
      credits: 100,
      created_at: "2024-10-10T14:33:01.977628",
      organization_id: 1,
      description: "Credits allocated to user",
    },
    {
      to_id: "9af310af-8688-40cc-a7d2-ce60696a1a02",
      to_identifier: "user",
      from_id: "735af575-3d48-4766-9555-0fcd3561165e",
      transaction_type: "allocation",
      app_id: null,
      from_identifier: "user",
      reference_id: "b2265156-0275-4ddd-9a03-fb54d70ff757",
      credits: 100,
      created_at: "2024-10-10T14:34:44.367640",
      organization_id: 1,
      description: "Credits allocated to user",
    },
  ];

  const categorizeResults = () => {
    // if (creditHistory.length === 0) return;
    const sortedResults = [...results].sort((a, b) =>
      moment(b.created_at).diff(moment(a.created_at)),
    );
    const categories = {};
    sortedResults.forEach((item) => {
      const date = moment(item.created_at);
      const now = moment();
      let day;
      let formattedDate = date.format("MMMM Do, YYYY");

      if (date.isSame(now, "day")) {
        day = "Today";
      } else if (date.isSame(now.clone().subtract(1, "day"), "day")) {
        day = "Yesterday";
      } else {
        day = date.format("dddd");
      }

      const category = `${day}|${formattedDate}`;

      if (!categories[category]) {
        categories[category] = [];
      }
      categories[category].push(item);
    });
    Object.keys(categories).forEach((category) => {
      categories[category].sort((a, b) => moment(b.created_at).diff(moment(a.created_at)));
    });

    setCategorizedResults(categories);
  };

  useEffect(() => {
    categorizeResults();
  }, []);

  const renderTransactionItem = (item) => {
    const isAllocation = item.transaction_type === "allocation";
    return (
      <>
        <div
          key={item.reference_id}
          className="flex items-start mb-2"
          style={{ justifyContent: "space-between" }}
        >
          <div className="flex-col" style={{ display: "grid" }}>
            <span className="text-xs font-bold">{item.description}</span>
            <span className="text-xs text-gray-500">
              {moment(item.created_at).format("hh:mm A")}
            </span>
          </div>

          <span
            className={`flex text-xs font-bold items-center ${isAllocation ? "text-green-500" : ""}`}
          >
            {isAllocation ? "+" : "-"}
            <span>{item.credits} credits</span>
          </span>
        </div>
        <Divider className="mt-2" />
      </>
    );
  };

  const fetchCreditHistory = async () => {
    try {
      const data = await getUserCreditHistory();
      if (data.success && data.results) {
        setCreditHistory(data.results.data);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Couldn't fetch the credit balance, please try again later",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    fetchCreditHistory();
  }, [visible]);

  return (
    <>
      <Toast ref={toast} />
      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setIsVisible(false)}
        header={
          <div className="flex w-full gap-2 align-items-center">
            <span className="text-xl font-bold">Credit History</span>
            <span
              className="flex align-items-center p-2 rounded-lg border-2"
              style={{ backgroundColor: "#FFF8F3", borderColor: "#FEDDC7", borderRadius: "7px" }}
            >
              <img
                src={coins}
                alt="Credits"
                className="mr-2"
                style={{ width: "20px", height: "20px" }}
              />
              {`${creditData.credit_balance} credits`}
            </span>
          </div>
        }
        className="bg-white w-full md:w-20rem lg:w-30rem"
      >
        <Divider />
        <div className="p-input-icon-left w-full" style={{ position: "relative" }}>
          <i className="pi pi-search text-sm" style={{ left: "0.6rem", color: "#6c757d" }} />
          <InputText
            className="text-sm w-12 pl-5 text-sm bg-gray-100 border-none"
            style={{ paddingLeft: "3rem" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search"
            maxLength={500}
          />
        </div>
        <div className="mt-4">
          {Object.entries(categorizedResults).map(([category, items], index, array) => {
            const [day, date] = category.split("|");
            return (
              <React.Fragment key={category}>
                <div className="mb-4">
                  <div
                    className="text-lg font-semibold mb-2 flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <span className="text-sm">{day}</span>
                    <span className="text-xs text-gray-400">{date}</span>
                  </div>
                  {items.map(renderTransactionItem)}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Sidebar>
    </>
  );
};

export default CreditSummarySidebar;
