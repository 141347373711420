import { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import microsoftLogo from "../../assets/Login/microsoftLogo";
import googleLogo from "../../assets/Login/googleLogo";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate, useSearchParams } from "react-router-dom";
import Authentication from "hooks/authentication";
import { Toast } from "primereact/toast";
import { useUserStatusProvider } from "context/userStatusContext";
import Disclaimer from "components/Login/Disclaimer";
import { isSettlementDemand } from "constants";
import { Divider } from "primereact/divider";
import successImage from "../../assets/Login/thumbs-up.svg";
import settlementDemand from "../../assets/IntelliappLogo/settlementdemand.png";
import buttonImage from "../../assets/Login/bulletPoint.svg";
import { Dialog } from "primereact/dialog";
import { InputOtp } from "primereact/inputotp";
import { jwtDecode } from "jwt-decode";
import { setKeycloakSession } from "utils/utils";
import useStripe from "hooks/useStripe";
import { useSharedState } from "context/ContextWrapper";
import PlanSelection from "components/Login/PlanSelection";

const LoginScreen = ({ currentstate }) => {
  const toast = useRef(null);
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan");
  const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    organization: "",
    department: "",
    designation: "",
    plan: "",
    keycloak_id: "",
    sso: false,
  };
  const initialLoader = {
    signInGoogle: false,
    signInMicrosoft: false,
    signUpWithGoogle: false,
    signUpWithMicrosoft: false,
  };
  const [signUpForm, setSignUpForm] = useState(initialState);
  const {
    emailVerification,
    getOrganizations,
    signupWithEmail,
    initiateEmailVerification,
    getLoginToken,
    removeInvalidUser,
    checkOTP,
  } = Authentication();
  const { setIsVerifying } = useSharedState();
  const { setUserStatus } = useUserStatusProvider();
  const [currentState, setCurrentState] = useState(currentstate ? currentstate : "login");
  const [emailSignup, setEmailSignup] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [otpButtonLoading, setOtpButtonLoading] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);
  const [loader, setLoader] = useState(initialLoader);
  const [otpDialog, setOtpDialog] = useState(false);
  const [errors, setErrors] = useState({
    loginUser: null,
    signUpUser: null,
  });
  const [timer, setTimer] = useState(60);
  const [otpCode, setOtpCode] = useState("");
  const { getStripeClientSecretSignUp, getSessionStatusSignUp } = useStripe();

  useEffect(() => {
    if (otpDialog) {
      let interval;
      if (timer > 0) {
        interval = setInterval(() => {
          setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
      }
      return () => clearInterval(interval);
    }
  }, [timer, otpDialog]);

  useEffect(() => {
    if (plan === "enterprise") {
      setEmailSignup(true);
    }
  }, [plan]);

  useEffect(() => {
    const checkAuth = async () => {
      if (initialized && keycloak.authenticated) {
        setIsVerifying(true);
        const userEmail = keycloak.tokenParsed.email;
        const keycloakId = keycloak.tokenParsed.sub;
        const response = await emailVerification(userEmail);
        if (response?.exists === true && response?.status === "registered") {
          if (currentState === "login") {
            navigate("/");
          } else {
            sessionStorage.setItem(
              "redirectAfterLogout",
              JSON.stringify({
                path: "/login",
                state: "login",
                showLoginError: false,
                showSignUpError: false,
                existingUser: true,
                success: false,
              }),
            );
            await keycloak.logout({
              redirectUri: window.location.origin + "/login",
            });
          }
        } else {
          if (currentState === "signup") {
            const data = {
              ...signUpForm,
              allocation_type: "one_time",
              credits_to_be_allotted: plan === "team" ? 5 : 1,
              daily_limit: 0,
              frequency: 0,
              frequency_unit: "days",
              sso: true,
              email: userEmail,
              keycloak_id: keycloakId,
              first_name: keycloak.tokenParsed.given_name,
              last_name: keycloak.tokenParsed.family_name,
              plan: plan || "individual",
              otp: "123456",
            };
            sessionStorage.setItem("info", JSON.stringify(data));
            await getStripeClientSecretSignUp(
              {
                plan: plan || "individual",
                email: userEmail,
                redirect_url: window.location.origin + "/signup?plan=" + plan,
                full_name: keycloak.tokenParsed.given_name + " " + keycloak.tokenParsed.family_name,
              }
            );
          } else {
            sessionStorage.setItem(
              "redirectAfterLogout",
              JSON.stringify({
                path: "/signup",
                state: "signup",
                showLoginError: true,
                showSignUpError: false,
                existingUser: false,
                success: false,
              }),
            );
            await removeInvalidUser(keycloakId, userEmail);
            await keycloak.logout({
              redirectUri: window.location.origin + "/signup",
            });
          }
        }
        setIsVerifying(false);
      }
    };

    const redirectInfo = sessionStorage.getItem("redirectAfterLogout");
    if (redirectInfo) {
      const { path, state, showLoginError, showSignUpError, existingUser, success } =
        JSON.parse(redirectInfo);
      setCurrentState(state);
      navigate(path);
      if (showLoginError && toast.current) {
        setTimeout(() => {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: "No account found with this email. Please sign up.",
            life: 3000,
          });
        }, 500);
      }
      if (showSignUpError && toast.current) {
        setTimeout(() => {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: "Error while signing up. Please contact support",
            life: 3000,
          });
        }, 500);
      }
      if (existingUser && toast.current) {
        setTimeout(() => {
          toast.current?.show({
            severity: "error",
            summary: "Error",
            detail: "User already exists. Please login.",
            life: 3000,
          });
        }, 500);
      }
      if (success && toast.current) {
        setTimeout(() => {
          toast.current?.show({
            severity: "success",
            summary: "Success",
            detail: "Your account has been created successfully. Please login to continue.",
            life: 3000,
          });
        }, 500);
      }
      setTimeout(() => {
        sessionStorage.removeItem("redirectAfterLogout");
      }, 3500);
    } else {
      checkAuth();
    }
  }, [initialized, keycloak.authenticated, navigate, toast]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get("session_id");
    const storedSessionId = sessionStorage.getItem("lastSessionId");

    if (sessionId && (!storedSessionId || sessionId !== storedSessionId)) {
      setIsVerifying(true);
      sessionStorage.setItem("lastSessionId", sessionId);
      handleSessionId(sessionId);
    }
  }, [window.location.search]);

  const handlePostSsoSignUp = async (subscription_id) => {
    setIsVerifying(true);
    const data = JSON.parse(sessionStorage.getItem("info"));
    try {
      const response = await signupWithEmail({ ...data, subscription_id });
      if (response?.status === 200) {
        sessionStorage.setItem(
          "redirectAfterLogout",
          JSON.stringify({
            path: "/login",
            state: "login",
            showLoginError: false,
            showSignUpError: false,
            existingUser: false,
            success: true,
          }),
        );
        await keycloak.logout({
          redirectUri: window.location.origin + "/login",
        });
      } else {
        throw new Error("Signup failed");
      }
    } catch (err) {
      sessionStorage.setItem(
        "redirectAfterLogout",
        JSON.stringify({
          path: "/signup",
          state: "signup",
          showLoginError: false,
          showSignUpError: true,
          existingUser: false,
          success: false,
        }),
      );
      await removeInvalidUser(data?.keycloak_id, data?.email);
      await keycloak.logout({
        redirectUri: window.location.origin + "/signup",
      });
    }
  };

  const handleSessionId = async (sessionId) => {
    setIsVerifying(true);
    const response = await getSessionStatusSignUp(sessionId);
    const getSSOFlage = JSON.parse(sessionStorage.getItem("info"))?.sso;
    if (response?.status === "complete") {
      setIsVerifying(true);
      if (getSSOFlage) {
        handlePostSsoSignUp(response?.subscription_id);
      } else if (!getSSOFlage) {
        handlePostSignUp(response?.subscription_id);
      }
    } else if (response?.status === "open") {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Payment failed. Please try again.",
        life: 3000,
      });
    }
    setIsVerifying(false);
  };

  const userVerification = async (email) => {
    setSignupLoading(true);
    if (plan === "enterprise") {
      await sendOTP();
    } else {
      const response = await emailVerification(email);
      if (response?.status !== null) {
        setErrors((prev) => ({ ...prev, signUpUser: true }));
      } else {
        setErrors((prev) => ({ ...prev, signUpUser: null }));
        await sendOTP();
      }
    }
    setSignupLoading(false);
  };

  if (initialized && keycloak.authenticated) {
    return null; // Render nothing while redirecting
  }

  const login = () => {
    return (
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
        {/* {isSettlementDemand && (
          <Button text label="Back" icon="pi pi-angle-left" className="text-sm mb-4 pl-0" />
        )} */}
        <h1 className="text-2xl font-bold mb-3">Account Login</h1>
        <div>
          <p className="text-gray-600 mb-4 text-base">
            Welcome back! Please enter your email to log in.
          </p>

          <>
            <div className="mt-4 flex flex-col gap-2">
              <Button
                iconPos="right"
                className="w-full sso-button"
                icon={googleLogo}
                label="Sign in with Google"
                loading={loader.signInGoogle}
                onClick={() => {
                  setLoader({ ...loader, signInGoogle: true });
                  keycloak.login({ idpHint: "google" });
                }}
              />
              <Button
                iconPos="right"
                className="w-full sso-button"
                icon={microsoftLogo}
                label="Sign in with Microsoft"
                loading={loader.signInMicrosoft}
                onClick={() => {
                  setLoader({ ...loader, signInMicrosoft: true });
                  keycloak.login({
                    idpHint: "microsoft",
                    redirectUri: window.location.origin + "/login",
                    pkceMethod: false,
                  });
                }}
              />
            </div>
            <Divider align="center">
              <div className="inline-flex align-items-center text-gray-500 text-sm">
                <b>or</b>
              </div>
            </Divider>
          </>

          <div className="mb-4">
            <label htmlFor="email" className="block text-gray-700 mb-2">
              Email address
            </label>
            <InputText
              id="email"
              value={signUpForm.email}
              onChange={(e) => setSignUpForm({ ...signUpForm, email: e.target.value })}
              onClear={() => setErrors((prev) => ({ ...prev, loginUser: null }))}
              className="w-full p-inputtext p-component"
              placeholder="Enter Email"
              style={{ ":focus": { borderColor: "#3b82f6" } }}
            />
            {errors.loginUser === false && (
              <p className="text-red-500 text-sm">
                We couldn't find an account with that email. Double-check and try again
                <>
                  ,or{" "}
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={() => setCurrentState("signup")}
                  >
                    create a new account.
                  </span>
                </>
              </p>
            )}
          </div>
          <Button
            label="Next"
            className="w-full bg-blue-500 p-button"
            onClick={handleLogin}
            disabled={!signUpForm.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpForm.email)}
            loading={loginLoading}
          />

          <p className="text-gray-500 text-sm">
            Don't have an account?{" "}
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => {
                navigate("/signup");
                setCurrentState("signup");
              }}
            >
              Sign up
            </span>
          </p>
        </div>
      </div>
    );
  };

  const signUp = () => {
    return (
      <div className="w-full max-w-md bg-white p-8 rounded shadow-md overflow-y-auto">
        {currentState === "signup" && plan !== null && (
          <Button
            text
            label="Back"
            onClick={() => {
              navigate("/signup");
              setCurrentState("signup");
              setEmailSignup(false);
              setSignUpForm(initialState);
            }}
            icon="pi pi-angle-left"
            className="text-sm mb-4 pl-0"
          />
        )}
        <h1 className="text-2xl font-bold mb-3">
          {emailSignup && (plan === "team" || plan === "individual")
            ? "Signup with Email"
            : plan === "team"
              ? "Team Plan"
              : plan === "enterprise"
                ? "Enterprise Plan"
                : "Individual Plan"}
        </h1>
        <div>
          <p className="text-gray-600 mb-4 text-base">
            {emailSignup && (plan === "team" || plan === "individual")
              ? "Signup with your email"
              : plan === "enterprise"
                ? "Fillup the form so our sales team can get back to you"
                : "Choose an option to continue"}
          </p>
          {emailSignup ? (
            <div>
              <div className="flex flex-col gap-2 w-full mb-4">
                <div className="flex-col flex-1">
                  <label htmlFor="firstName" className="block text-gray-700 mb-2">
                    First Name
                  </label>
                  <InputText
                    id="firstName"
                    value={signUpForm.first_name}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[a-zA-Z\s]*$/.test(value)) {
                        setSignUpForm({ ...signUpForm, first_name: value });
                      }
                    }}
                    className="w-full p-inputtext p-component"
                    placeholder="Enter First Name"
                    style={{ ":focus": { borderColor: "#3b82f6" } }}
                  />
                </div>
                <div className="flex-col flex-1">
                  <label htmlFor="lastName" className="block text-gray-700 mb-2">
                    Last Name (Optional)
                  </label>
                  <InputText
                    id="lastName"
                    value={signUpForm.last_name}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^[a-zA-Z\s]*$/.test(value)) {
                        setSignUpForm({ ...signUpForm, last_name: value });
                      }
                    }}
                    className="w-full p-inputtext p-component"
                    placeholder="Enter Last Name"
                    style={{ ":focus": { borderColor: "#3b82f6" } }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 mb-2">
                  Email address
                </label>
                <InputText
                  id="email"
                  value={signUpForm.email}
                  onChange={(e) => setSignUpForm({ ...signUpForm, email: e.target.value })}
                  onClear={() => setErrors((prev) => ({ ...prev, signUpUser: false }))}
                  className="w-full p-inputtext p-component"
                  placeholder="Enter Email"
                  style={{ ":focus": { borderColor: "#3b82f6" } }}
                  invalid={errors.signUpUser}
                />
                {errors.signUpUser && (
                  <p className="text-red-500 text-sm mt-1 mb-0">User already exists!!</p>
                )}
              </div>
              {plan === "enterprise" && (
                <>
                  <div className="mb-4">
                    <label htmlFor="organizationName" className="block text-gray-700 mb-2">
                      Organization Name
                    </label>
                    <InputText
                      id="organizationName"
                      value={signUpForm.organization}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[a-zA-Z\s]*$/.test(value)) {
                          setSignUpForm({ ...signUpForm, organization: value });
                        }
                      }}
                      className="w-full p-inputtext p-component"
                      placeholder="Enter Organization"
                      style={{ ":focus": { borderColor: "#3b82f6" } }}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="department" className="block text-gray-700 mb-2">
                      Department
                    </label>
                    <InputText
                      id="department"
                      value={signUpForm.department}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[a-zA-Z\s]*$/.test(value)) {
                          setSignUpForm({ ...signUpForm, department: value });
                        }
                      }}
                      className="w-full p-inputtext p-component"
                      placeholder="Enter Department"
                      style={{ ":focus": { borderColor: "#3b82f6" } }}
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="designation" className="block text-gray-700 mb-2">
                      Designation
                    </label>
                    <InputText
                      id="designation"
                      value={signUpForm.designation}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^[a-zA-Z\s]*$/.test(value)) {
                          setSignUpForm({ ...signUpForm, designation: value });
                        }
                      }}
                      className="w-full p-inputtext p-component"
                      placeholder="Enter Designation"
                      style={{ ":focus": { borderColor: "#3b82f6" } }}
                    />
                  </div>
                </>
              )}
              <Button
                label="Signup"
                className="w-full border-blue-500 bg-transparent p-button text-blue-500 hover:bg-blue-500 hover:text-white hover:border-blue-500 transition-colors duration-200"
                loading={signupLoading}
                disabled={
                  !signUpForm.first_name ||
                  !signUpForm.email ||
                  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signUpForm.email) ||
                  (plan === "enterprise" &&
                    (!signUpForm.organization || !signUpForm.department || !signUpForm.designation))
                }
                onClick={() => userVerification(signUpForm.email)}
              />
            </div>
          ) : (
            <>
              <div className="mt-4 flex flex-col gap-2">
                <Button
                  iconPos="right"
                  className="w-full sso-button"
                  icon={googleLogo}
                  label="Sign up with Google"
                  loading={loader.signUpWithGoogle}
                  onClick={() => {
                    setLoader({ ...loader, signUpWithGoogle: true });
                    keycloak.login({ idpHint: "google" });
                  }}
                />
                <Button
                  iconPos="right"
                  className="w-full sso-button"
                  icon={microsoftLogo}
                  label="Sign up with Microsoft"
                  loading={loader.signUpWithMicrosoft}
                  onClick={() => {
                    setLoader({ ...loader, signUpWithMicrosoft: true });
                    keycloak.login({
                      idpHint: "microsoft",
                      redirectUri: window.location.origin + "/signup",
                    });
                  }}
                />
              </div>
              <Divider align="center">
                <div className="inline-flex align-items-center text-gray-500 text-sm">
                  <b>or</b>
                </div>
              </Divider>
              <Button
                label="Sign up with email"
                className="w-full border-blue-500 bg-transparent p-button text-blue-500"
                onClick={() => setEmailSignup(true)}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const rightPanel = (currentState) => {
    switch (currentState) {
      case "login":
        return login();
      case "signup":
        return signUp();
      case "success":
        return (
          <div className="w-full max-w-md bg-white p-8 rounded shadow-md flex flex-column justify-content-center">
            <img src={successImage} alt="success" className="w-1" />
            <h1 className="text-2xl font-bold mb-1">Thanks for your interest</h1>
            <div>
              <p className="text-gray-600 mb-4 text-base">
                We will get in touch with you shortly, please keep an eye on your inbox.
              </p>
            </div>
          </div>
        );
      default:
        return login();
    }
  };

  const fetchOrganizations = async (domain) => {
    try {
      const response = await getOrganizations(domain);
      if (response?.status === 200) {
        return response?.results !== false ? response?.results?.idp_alias : false;
      }
    } catch (error) {
      console.error("Failed to fetch organizations:", error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    setErrors((prev) => ({ ...prev, loginUser: null }));
    try {
      const response = await emailVerification(signUpForm.email);
      if (response?.status !== null) {
        const domain = signUpForm.email.split("@")[1];
        const idpAlias = await fetchOrganizations(domain);
        if (idpAlias) {
          keycloak.login({
            idpHint: idpAlias,
            loginHint: signUpForm.email,
          });
        } else {
          await sendOTP();
        }
        setUserStatus(response?.data?.status);
      } else {
        setErrors((prev) => ({ ...prev, loginUser: false }));
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err?.message,
        life: 3000,
      });
    } finally {
      setLoginLoading(false);
    }
  };

  const sendOTP = async () => {
    try {
      await initiateEmailVerification(signUpForm.email);
      setOtpDialog(true);
      setTimer(60);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to send OTP. Please try again.",
        life: 3000,
      });
    }
  };

  const handleOTPVerification = async () => {
    try {
      setOtpButtonLoading(true);
      if (currentState === "signup") {
        try {
          const response = await checkOTP(signUpForm.email, otpCode);
          if (response?.status === 200) {
            sessionStorage.setItem("info", JSON.stringify({ ...signUpForm, plan, otp: otpCode }));
            await getStripeClientSecretSignUp(
              {
                plan: plan,
                email: signUpForm.email,
                redirect_url: window.location.origin + "/signup?plan=" + plan,
                full_name: signUpForm.first_name + " " + signUpForm.last_name,
              }
            );
          } else {
            throw new Error(response?.data?.message);
          }
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.response?.data?.message || "Something went wrong. Please try again.",
            life: 3000,
          });
        }
      } else {
        const response = await getLoginToken(signUpForm.email, otpCode);
        if (response?.status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Successfully logged in",
            life: 3000,
          });
          setOtpDialog(false);
          setSignUpForm(initialState);
          setOtpCode("");

          const decoded = jwtDecode(response?.results?.access_token);
          const session = {
            token: response?.results?.access_token,
            refreshToken: response?.results?.refresh_token,
            idToken: response?.results?.id_token,
            tokenParsed: decoded,
            authenticated: true,
          };
          sessionStorage.setItem("keycloakSession", JSON.stringify(session));
          setKeycloakSession(response, decoded);
          navigate("/");
        } else {
          throw new Error("Invalid OTP");
        }
      }
    } catch (error) {
      let summary = "Error";
      let detail = "Something went wrong. Please try again.";

      if (error.response?.data?.message === "Invalid OTP") {
        summary = "Invalid OTP";
        detail = "Please enter correct verification code";
      } else if (error.response?.data?.message === "OTP expired") {
        summary = "OTP expired";
        detail = "Please request a new OTP";
      } else {
        summary = "Error";
        detail = error.response?.data?.message || "Something went wrong. Please try again.";
      }

      toast.current.show({
        severity: "error",
        summary: summary,
        detail: detail,
        life: 3000,
      });
    } finally {
      setOtpButtonLoading(false);
    }
  };

  const handlePostSignUp = async (subscription_id) => {
    const savedData = JSON.parse(sessionStorage.getItem("info"));
    const data = {
      ...savedData,
      allocation_type: "one_time",
      credits_to_be_allotted: savedData.plan === "team" ? 5 : 1,
      daily_limit: 0,
      frequency: 0,
      frequency_unit: "days",
      subscription_id: subscription_id,
    };
    const response = await signupWithEmail(data);
    if (response?.status === 200) {
      if (savedData.plan === "enterprise") {
        setCurrentState("success");
      } else if (savedData.plan === "team" || savedData.plan === "individual") {
        sessionStorage.setItem(
          "redirectAfterLogout",
          JSON.stringify({
            path: "/login",
            state: "login",
            showLoginError: false,
            showSignUpError: false,
            existingUser: false,
            success: true,
          }),
        );
        window.location.reload();
      }
      setOtpDialog(false);
      setSignUpForm(initialState);
      setOtpCode("");
    } else {
      navigate("/signup?plan=" + savedData.plan);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Invalid OTP! Please try again.",
        life: 3000,
      });
    }
    sessionStorage.removeItem("info");
    setIsVerifying(false);
  };

  const customInput = ({ events, props }) => {
    return (
      <>
        <input {...events} {...props} type="text" className="custom-otp-input-sample my-2" />
        {props.id === 2 && (
          <div className="px-3">
            <i className="pi pi-minus" />
          </div>
        )}
      </>
    );
  };

  const init = () => {
    if (currentState === "signup" && plan === null) {
      return <PlanSelection {...{ setSignUpForm, initialState }} />;
    }
    return (
      <div
        className={`flex flex-col lg:flex-row h-screen text-xs ${!isSettlementDemand ? "justify-content-center" : ""}`}
        id="login-container"
      >
        <Toast ref={toast} />
        {isSettlementDemand && <Disclaimer />}
        {isSettlementDemand && (
          <div className="lg:w-1/2 bg-blue-50 flex justify-center items-center overflow-hidden p-0 login-left-panel" />
        )}
        <div className="flex flex-col justify-center items-center lg:w-1/2 h-screen">
          {rightPanel(currentState)}
        </div>
        <Dialog
          header={false}
          visible={otpDialog}
          style={{ width: "35vw" }}
          closable={false}
          headerClassName="p-0"
          className="otp-dialog"
        >
          <div className="p-4">
            <h3 className="text-gray-900 my-2 text-2xl">Please Enter the code</h3>
            <p className="text-gray-500 text-xs line-height-3">
              We've sent a verification code on the email you entered. Please enter the verification
              code here.
            </p>
            <InputOtp
              value={otpCode}
              onChange={(e) => setOtpCode(e.value)}
              inputTemplate={customInput}
              length={6}
              style={{ gap: "0.50rem" }}
              integerOnly
              mask
            />
            <Button
              label="Verify"
              loading={otpButtonLoading}
              disabled={otpCode.length !== 6}
              onClick={() => handleOTPVerification()}
              className="w-full border-blue-500 bg-transparent p-button text-blue-500 hover:bg-blue-500 hover:text-white hover:border-blue-500 transition-colors duration-200 my-2"
            />
            {timer > 0 ? (
              <p className="text-sm mt-2 text-gray-500">
                Didn't receive the email? Please check your spam folder or wait {timer} seconds to
                Resend.
              </p>
            ) : (
              <div className="flex items-center gap-2 mt-2">
                <p className="text-sm text-gray-500">
                  Didn't receive the email?{" "}
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={() => {
                      sendOTP();
                      setOtpCode("");
                      setTimer(60);
                    }}
                  >
                    Resend
                  </span>
                </p>
              </div>
            )}
          </div>
        </Dialog>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      {init()}
    </div>
  );
};

export default LoginScreen;
