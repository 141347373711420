import React from "react";
import BookLogo from "assets/BookIcons/BookLogo";
import WorkspaceIcon from "assets/WorkSpaceIcons/WorkspaceIcon";
import UserManagementLogo from "assets/UserManagement/UserManagement";
import CreditManagementIcon from "assets/CreditOverview/CreditManagementIcon";

export const IntelliAppNavigationMap = {
  templates: `/intelliapp/templates`,
  workspaces: `/intelliapp/workspaces`,
};

export const DashboardNavigationMap = {
  workspaces: `/dashboard`,
  ["credits"]: `/credits`,
  agent: `/agent`,
  ["usermgt."]: `/usersmanagement`,
};

export const IntelliAppmenuItems = [
  {
    label: "Workspaces",
    icon: (selectedItem) => <WorkspaceIcon selectedItem={selectedItem} />,
  },
  {
    label: "Templates",
    icon: (selectedItem) => <BookLogo selectedItem={selectedItem} />,
  },
];

export const DashboardmenuItems = [
  {
    label: "Agent",
    icon: (selectedItem) => <WorkspaceIcon selectedItem={selectedItem} />,
  },
  {
    icon: (selectedItem) => <CreditManagementIcon selectedItem={selectedItem} />,
    label: "Credits",
  },
  {
    label: "UserMgt.",
    icon: (selectedItem) => <UserManagementLogo selectedItem={selectedItem} />,
  },
];
