import React, { useEffect, useRef, useState } from 'react'
import Authentication from 'hooks/authentication';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import settlementDemand from "../../assets/IntelliappLogo/settlementdemand.png";
import buttonImage from "../../assets/Login/bulletPoint.svg";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';

const PlanSelection = ({ setSignUpForm, initialState }) => {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const { getPlans } = Authentication();
    const navigate = useNavigate();
    useEffect(() => {
        const fetchPlans = async () => {
            setLoading(true);
            const plans = await getPlans("subscription");
            setPlans(plans.sort((a, b) => a.id - b.id));
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        };
        fetchPlans();
    }, []);

    //     {
    //         name: "Individual",
    //         price: "USD 99 /month",
    //         features: [
    //             "Single User",
    //             "1 Free Settlement Demand Credit*",
    //             "Pay for each additional Demand**",
    //         ],
    //         path: "individual",
    //     },
    //     {
    //         name: "Team",
    //         price: "USD 349 /month",
    //         features: [
    //             "Up to 5 Users",
    //             "5 Free Settlement Demand Credit*",
    //             "Pay for each additional Demand**",
    //             "Priority Support",
    //         ],
    //         path: "team",
    //     },
    //     {
    //         name: "Enterprise",
    //         price: "Custom Plan",
    //         features: [
    //             "5+ Users",
    //             "Custom Starting Credits*",
    //             "Pay for each additional Demand**",
    //             "Priority Suport",
    //             "Enterprise SSO Integration",
    //             "Account Manager",
    //         ],
    //         path: "enterprise",
    //     },
    // ];
    return (
        !loading ? (
            <div className="flex flex-column w-full max-w-md bg-white p-3 rounded shadow-md signup-plan h-screen" >
                <Toast ref={toast} />
                <img src={settlementDemand} alt="Settlement Demand" className="w-1" />
                <h1 className="text-2xl font-bold mb-3 text-center mt-5">Select a plan to start with</h1>
                <div className="flex px-5 flex-grow h-full gap-5">
                    {plans.map((plan, index) => (
                        <div className="flex flex-column gap-2 flex-1 border-round-lg p-4 bg-white plans cursor-pointer">
                            <p className="text-gray-700 text-sm mb-0">{plan.name}</p>
                            <h2 className="font-medium mt-2 mb-3">{!isNaN(parseInt(plan.price)) ? `USD ${plan.price} /month` : plan.price}</h2>
                            <ul className="list-none p-0 m-0 ">
                                {plan.features.map((feature, index) => (
                                    <li className="flex items-center gap-2 mb-3 text-gray-700">
                                        <img src={buttonImage} alt="" className="my-auto" />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            <Button
                                label="Get Started"
                                onClick={() => {
                                    navigate("/signup?plan=" + plan.slug);
                                    setSignUpForm(initialState);
                                }}
                                className="w-full bg-transparent border-700 text-700 mt-auto hover:bg-blue-500 hover:text-white hover:border-blue-500 transition-colors duration-200"
                            />
                        </div>
                    ))}
                </div>
                <p className="text-sm mt-4 px-5 text-left">
                    * 1 Settlement Demand Generation requires 1 Credit<br></br>
                    ** See Credits Pricing section to see the tiers for cost per credit.
                </p>
            </div >
        ) : (
            <div className="flex justify-content-center align-items-center h-screen">
                <ProgressSpinner />
            </div>
        )
    )
}

export default PlanSelection