import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import PropTypes from "prop-types";
import { ReactComponent as CategoryIcon } from "assets/SpaceIcons/AddCategory.svg";
import Category from "../Category";
import "./styles.css";

const ChatIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 9.58336C17.5029 10.6832 17.2459 11.7683 16.75 12.75C16.162 13.9265 15.2581 14.916 14.1395 15.6078C13.021 16.2995 11.7319 16.6662 10.4167 16.6667C9.31678 16.6696 8.23176 16.4126 7.25 15.9167L2.5 17.5L4.08333 12.75C3.58744 11.7683 3.33047 10.6832 3.33333 9.58336C3.33384 8.26815 3.70051 6.97907 4.39227 5.86048C5.08402 4.74189 6.07355 3.83797 7.25 3.25002C8.23176 2.75413 9.31678 2.49716 10.4167 2.50002H10.8333C12.5703 2.59585 14.2109 3.32899 15.4409 4.55907C16.671 5.78915 17.4042 7.42973 17.5 9.16669V9.58336Z"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CategorySkeleton = () => (
  <div className="category-skeleton">
    <div className="category-item-skeleton">
      <div className="flex flex-column gap-2">
        <div className="flex align-items-center justify-between gap-2">
          <div className="flex align-items-center gap-2 overflow-hidden flex-1">
            <div className="skeleton-text" style={{ width: "120px" }}></div>
          </div>
          <div className="skeleton-icon"></div>
        </div>
        <div className="skeleton-text" style={{ width: "60px" }}></div>
      </div>
    </div>

    {[1].map((i) => (
      <div key={i} className="category-item-skeleton">
        <div className="flex flex-column gap-2">
          <div className="flex align-items-center justify-between gap-2">
            <div className="flex align-items-center gap-2 overflow-hidden flex-1">
              <div className="skeleton-color-circle"></div>
              <div className="skeleton-text" style={{ width: "100px" }}></div>
            </div>
            <div className="skeleton-icon"></div>
          </div>
          <div className="skeleton-text" style={{ width: "50px" }}></div>
        </div>
      </div>
    ))}
  </div>
);

const CategoryList = ({
  selectedCategory,
  onCategorySelect,
  spaceId,
  categories = [],
  loading,
  onRefreshCategories,
  toast,
}) => {
  const [visible, setVisible] = useState(false);
  const addEditCategoriesButton = (
    <Button
      className="p-button-text text-blue-500 mt-2 font-medium text-sm flex align-items-center gap-2 py-3 px-4 bg-transparent border-none hover:bg-gray-50"
      onClick={() => setVisible(true)}
    >
      <CategoryIcon className="text-blue-500" />
      Add/Edit Categories
    </Button>
  );

  useEffect(() => {
    if (!visible) {
      onRefreshCategories();
    }
  }, [visible]);

  if (loading) {
    return (
      <div className="flex flex-column gap-2 p-0 mt-5">
        <CategorySkeleton />
        {addEditCategoriesButton}
      </div>
    );
  }

  return (
    <div className="flex flex-column gap-2 p-0 mt-5">
      <div className="all-documents">
        <div
          className={`cursor-pointer flex flex-column py-2 px-3 ease-in-out duration-200 hover:bg-gray-50 gap-2 ${selectedCategory === "all" ? "bg-gray-50 border-left-blue" : "border-bottom-gray"}`}
          onClick={() => onCategorySelect("all")}
        >
          <div className="flex flex-column gap-2">
            <div className="flex align-items-center justify-between gap-2">
              <div className="flex align-items-center gap-2 overflow-hidden flex-1 mt-1">
                <div
                  className="border-circle flex-shrink-0 my-auto"
                  style={{ backgroundColor: "#E5E7EB", width: "12px", height: "12px" }}
                />
                <span className="text-sm text-dark-500 overflow-hidden text-ellipsis whitespace-nowrap">
                  All Documents
                </span>
              </div>
            </div>
            <span className="mt-2 text-xs text-gray-500">
              {categories.find((cat) => cat.id === "all")?.total || 0} Files
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-column gap-2 max-h-14rem overflow-y-auto">
        {categories &&
          categories
            .filter((cat) => cat.id !== "all")
            .map((category) => (
              <div
                key={category.id}
                className={`cursor-pointer flex flex-column py-2 px-3 ease-in-out duration-200 hover:bg-gray-50 gap-2 ${selectedCategory === category.id ? "bg-gray-50 border-left-blue" : "border-bottom-gray"}`}
                onClick={() => onCategorySelect(category.id)}
              >
                <div className="flex flex-column gap-2">
                  <div className="flex align-items-center justify-between gap-2">
                    <div className="flex align-items-center gap-2 overflow-hidden flex-1">
                      <div
                        className="border-circle flex-shrink-0 my-auto"
                        style={{ backgroundColor: category.color, width: "12px", height: "12px" }}
                      />
                      <span className="text-sm text-dark-500 overflow-hidden text-ellipsis whitespace-nowrap">
                        {category.name}
                      </span>
                    </div>
                    <div className="flex align-items-center justify-center h-6 border-round-sm text-blue-500 p-1 cursor-pointer border-1 border-blue-500 ml-auto hover:bg-gray-50">
                      <ChatIcon />
                    </div>
                  </div>
                  <span className="text-xs text-gray-500">{category?.total} Files</span>
                </div>
              </div>
            ))}
      </div>

      {addEditCategoriesButton}

      <Category
        visible={visible}
        setVisible={setVisible}
        className="category-panel"
        spaceId={spaceId}
        categories={categories}
        onSave={() => {
          if (spaceId) {
            onRefreshCategories();
          }
        }}
        toast={toast}
      />
    </div>
  );
};

CategoryList.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  onCategorySelect: PropTypes.func.isRequired,
  spaceId: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onRefreshCategories: PropTypes.func.isRequired,
};

export default CategoryList;
