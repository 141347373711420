import useApi from "services/api";
import { API_URL } from "../constants";

const PostTemplateList = () => {
  const { data, loading, error, upload, patch } = useApi();
  const contentType = {
    "Content-Type": "multipart/form-data",
  };
  const postTemplate = async (body, onUploadProgress) => {
    console.log("body", body);
    const data = await upload(`${API_URL}/template/upload/`, body, contentType, onUploadProgress);
    return data;
  };

  const patchTemplate = async (id, body) => {
    const data = await patch(`${API_URL}/template/${id}`, body);
    return data;
  };

  return { postTemplate, data, loading, error, patchTemplate };
};

export default PostTemplateList;
