import React from "react";
import { Routes, Route } from "react-router-dom";
import NotAccessible from "./NotAccessible";
import Workspace from "pages/DocumentEngine/Workspaces/Workspace";
import Templates from "pages/DocumentEngine/Templates/Templates";
import IntelliappLayout from "layouts/IntelliappLayout";

const SourceRoutes = ({ applicationAccess }) => {
  if (applicationAccess?.includes("documentengine")) {
    return (
      <Routes>
        <Route exact path="/" element={<IntelliappLayout />}>
          <Route path="templates" element={<Templates />} />
          <Route path="workspaces" element={<Workspace />} />
        </Route>
      </Routes>
    );
  } else {
    return <NotAccessible />;
  }
};

export default SourceRoutes;
