import useApi from "services/api";
import { API_URL } from "../constants";

const Authentication = () => {
  const { get, patch, post, data, loading, error } = useApi();
  const emailVerification = async (email) => {
    const encodedEmail = encodeURIComponent(email);
    const data = await get(`${API_URL}/intelliapp/users/check-user-exists?email=${encodedEmail}`);
    return data;
  };

  const updateUserStatus = async (body) => {
    const data = await patch(`${API_URL}/intelliapp/user/update-keycloak-status`, body);
    return data;
  };

  const getOrganizations = async (domain) => {
    const data = await get(`${API_URL}/organizations/domain?domain=${domain}`);
    return data;
  };

  const signupWithEmail = async (body) => {
    const data = await post(`${API_URL}/intelliapp/signup`, body);
    return data;
  };

  const initiateEmailVerification = async (email) => {
    const data = await post(`${API_URL}/intelliapp/initiate-email-verification`, { email });
    return data;
  };

  const getLoginToken = async (email, otp) => {
    const data = await post(`${API_URL}/intelliapp/login`, { email, otp });
    return data;
  };

  const removeInvalidUser = async (keycloak_id, email) => {
    const data = await post(`${API_URL}/intelliapp/remove-user`, { keycloak_id, email });
    return data;
  };

  const getPlans = async (type) => {
    const data = await get(`${API_URL}/subscription-plans?type=${type}`);
    return data;
  };

  const checkOTP = async (email, otp) => {
    const data = await post(`${API_URL}/check/signup?email=${email}&otp=${otp}`);
    return data;
  };

  return { emailVerification, updateUserStatus, getOrganizations, signupWithEmail, initiateEmailVerification, getLoginToken, removeInvalidUser, getPlans, checkOTP, data, loading, error };
};

export default Authentication;
