// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main.layout {
    display: grid;
    background-color: #fff;
    background-image: none;
    .contentArea {
        padding: 0.75rem 1.25rem;
    }

    .custom-sidebar .sidebar-button {
        width: 100%;
        border-radius: 0px;
    }

    .header {
        border-bottom: 1px solid #e0e0e0;
        border-radius: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/layouts/MainLayout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB;QACI,wBAAwB;IAC5B;;IAEA;QACI,WAAW;QACX,kBAAkB;IACtB;;IAEA;QACI,gCAAgC;QAChC,kBAAkB;IACtB;AACJ","sourcesContent":[".main.layout {\n    display: grid;\n    background-color: #fff;\n    background-image: none;\n    .contentArea {\n        padding: 0.75rem 1.25rem;\n    }\n\n    .custom-sidebar .sidebar-button {\n        width: 100%;\n        border-radius: 0px;\n    }\n\n    .header {\n        border-bottom: 1px solid #e0e0e0;\n        border-radius: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
