import React from "react";
import CreditsIcon from "../../assets/CreditOverview/CreditsIcon.svg";
import "./CreditBalance.css";
import { OverlayPanel } from "primereact/overlaypanel";
import { Tooltip } from "primereact/tooltip";
import { useCreditBalance } from "context/ContextWrapper";
import { Skeleton } from "primereact/skeleton";

const CreditBalance = () => {
  const { creditBalance, creditsLoading, fetchCreditBalance } = useCreditBalance();

  const overlayPanel = React.useRef(null);

  if (creditsLoading) {
    return (
      <div className="credit-balance">
        <div className="container p-0">
          <Skeleton className="w-8rem h-10 p-3" />
        </div>
      </div>
    );
  }

  return (
    <>
      {!creditBalance?.active_subscription && (
        <div className="active-subscription mr-3 border-2 border-round-md p-2 border-yellow-600 bg-yellow-50">
          <i className="pi pi-exclamation-triangle text-xl text-yellow-600 font-semibold"></i>
        </div>
      )}
      <div
        className="credit-balance cursor-pointer"
        onClick={(e) => {
          overlayPanel.current.toggle(e);
          e.stopPropagation();
          fetchCreditBalance();
        }}
      >
        <div className="container">
          <img src={CreditsIcon} alt="credit" />
          <div className="flex justify-content-center gap-1 ">
            <p className="p-0 m-0 text-sm">{creditBalance?.credit_balance} credits - </p>
            <p className="p-0 m-0 text-sm p-button p-button-link underline">Buy Credits</p>
          </div>
        </div>
        <OverlayPanel
          ref={overlayPanel}
          showCloseIcon={false}
          dismissable={true}
          appendTo={document.body}
          pt={{
            content: {
              className: "px-3 py-3",
              style: { width: 250 },
            },
          }}
        >
          <div className="flex justify-content-between align-items-center">
            <p className="text-base font-semibold p-0 m-0">Credit Info</p>
            <span
              className="pi pi-times text-sm cursor-pointer"
              onClick={() => overlayPanel.current.hide()}
            ></span>
          </div>

          <div className="mt-2 flex flex-column gap-2">
            <div className="flex justify-content-between align-items-center">
              <p className="text-sm p-0 m-0 w-8">Available Credits:</p>
              <p className="text-sm p-0 m-0 w-4 font-semibold">{creditBalance?.credit_balance}</p>
            </div>
            <div className="flex justify-content-between align-items-center">
              <p className="text-sm p-0 m-0 w-8">Blocked Credits:</p>
              <p className="text-sm p-0 m-0 w-4 font-semibold flex align-items-center gap-1">
                {creditBalance?.blocked_credits}
                <span className="pi pi-info-circle text-xs" id="blocked-credits-info"></span>
                <Tooltip target="#blocked-credits-info" position="top">
                  <p className="text-sm p-0 m-0">
                    Blocked credits are held until your task is complete—consumed if successful or
                    refunded to your available credits if it fails.
                  </p>
                </Tooltip>
              </p>
            </div>

            <div className="flex justify-content-between align-items-center">
              <p className="text-sm p-0 m-0 w-8">Total Credits:</p>
              <p className="text-sm p-0 m-0 w-4 font-semibold">{creditBalance?.total_credits}</p>
            </div>
          </div>
        </OverlayPanel>
      </div>
    </>
  );
};

export default CreditBalance;
