import { Button } from "primereact/button";
import DownArrow from "../../../assets/CreditOverview/ArrowDown.svg";
import CoinIcon from "../../../assets/CreditOverview/CreditsIcon.svg";
import "./creditSummary.css";
import OrgTransactionsChart from "./OrgTranscationsChart";
import { Menu } from "primereact/menu";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useCreditSummary from "hooks/useCreditSummary";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";

const CreditSummary = () => {
  const menu = useRef(null);
  const toast = useRef(null);
  const navigate = useNavigate();
  const { getCreditUsage, getTotalCredits, loading } = useCreditSummary();
  const [oneDayCredit, setOneDayCredit] = useState("");
  const [sevenDaysCredit, setSevenDaysCredit] = useState("");
  const [totalCredits, setTotalCredits] = useState("");
  const [loadingCred, setLoadingCred] = useState("");

  useEffect(() => {
    setLoadingCred(loading);
  }, [loading]);

  const fetchCreditUsage = async () => {
    try {
      const today = new Date();
      const sevenDaysAgo = new Date(today);
      sevenDaysAgo.setDate(today.getDate() - 6);

      const formatDate = (date) => date.toISOString().split("T")[0];

      const oneDayData = await getCreditUsage({
        date_gte: formatDate(today),
        date_lte: formatDate(today),
      });

      const weekData = await getCreditUsage({
        date_gte: formatDate(sevenDaysAgo),
        date_lte: formatDate(today),
      });
      if (oneDayData.success && oneDayData.results) {
        setOneDayCredit(oneDayData.results.data);
      }
      if (weekData.success && weekData.results) {
        setSevenDaysCredit(weekData.results.data);
      }

      const totalCreditValue = await getTotalCredits();
      if (totalCreditValue?.results?.data) {
        setTotalCredits(totalCreditValue?.results?.data?.credit_balance);
      }
    } catch (err) {
      console.error(err);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please try again later",
        life: 3000,
      });
    }
  };

  useEffect(() => {
    fetchCreditUsage();
  }, []);

  return (
    <div className="p-4 surface-0 h-screen credit-summary">
      <Toast ref={toast} />
      <div className="flex justify-content-between">
        <h1 className="text-lg font-semibold">Credit Overview Dashboard</h1>
        <Button
          id="downloadReport"
          iconPos="right"
          icon="pi pi-chevron-down"
          label="Download Report"
          size="small"
          outlined
          className="p-button-primary"
          onClick={(e) => menu.current.toggle(e)}
        />
        <Menu
          ref={menu}
          model={[
            {
              label: "Today",
              className: "text-sm font-light",
            },
            {
              label: "Past 7 days",
              className: "text-sm font-light",
            },
            {
              label: "Past 30 days",
              className: "text-sm font-light",
            },
            {
              label: "Custom Date Range",
              className: "text-sm font-light",
            },
          ]}
          popup
        />
      </div>
      <div className="flex justify-content-between mt-5 gap-5">
        <SummaryCard
          title={"Organization Credits Balance"}
          value={totalCredits}
          icon={CoinIcon}
          iconSize={32}
          loading={loadingCred}
        />
        <SummaryCard
          title={"Today's Usage"}
          value={oneDayCredit}
          icon={DownArrow}
          iconSize={28}
          loading={loadingCred}
        />
        <SummaryCard
          title={"Last 7 days Usage"}
          value={sevenDaysCredit}
          icon={DownArrow}
          iconSize={28}
          loading={loadingCred}
        />
      </div>

      <div className="mt-6 flex justify-content-between gap-4">
        <div className="w-6">
          <ViewCard
            title="User Credit Summary"
            description="View, allocate, and manage credits for multiple users."
            handleClick={() => {
              navigate("/credits-list");
            }} //navigate("/credits-list")}
          />
          <div className="mt-4" />
          <ViewCard
            title="Organization Credit History"
            handleClick={() => {
              navigate("/credits-org-history");
            }}
            description="View Credit allocation and recharge history"
          />
        </div>
        <div className="w-6">
          <OrgTransactionsChart />
        </div>
      </div>
    </div>
  );
};

const ViewCard = ({ title, description, handleClick }) => {
  return (
    <div className="border-round-lg border-300 border-1 h-10rem pl-4 pr-4 flex justify-content-between align-items-center">
      <div className="flex flex-column gap-2">
        <h2 className="m-0 text-lg text-700 font-semibold">{title}</h2>
        <p className="text-500 mt-1 m-0 text-sm font-light">{description}</p>
      </div>
      <Button
        outlined
        label="View"
        // onClick={() => navigate("/credits-list")}
        onClick={handleClick}
        size="small"
        className="border-300"
      />
    </div>
  );
};

export const SummaryCard = ({ title, value, icon, iconSize, loading }) => {
  return (
    <div
      className="surface-50 w-4 border-round-xl h-8rem flex align-items-center gap-3"
      style={{ paddingLeft: 32 }}
    >
      <img src={icon} style={{ height: iconSize, width: iconSize }} alt="Coin Icon" />
      <div className="flex flex-column justify-content-center">
        {loading ? (
          <div className=" h-4 w-4 bg-white opacity-50 z-5">
            <div className="flex">
              <ProgressSpinner />
            </div>
          </div>
        ) : (
          <>
            <h2 className="font-semibold text-700 text-xl m-0">{value}</h2>
            <p className="text-500 m-0 mt-1 text-sm">{title}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default CreditSummary;
