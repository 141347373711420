import Keycloak from "keycloak-js";
const keycloak = new Keycloak({
    url: process.env.REACT_APP_KEY_CLOAK_URL,
    realm: process.env.REACT_APP_KEY_CLOAK_REALM,
    clientId: process.env.REACT_APP_KEY_CLOAK_CLIENT_ID,
    oidcProvider: {
        authorization_endpoint: `${process.env.REACT_APP_KEY_CLOAK_URL}realms/${process.env.REACT_APP_KEY_CLOAK_REALM}/protocol/openid-connect/auth`,
        token_endpoint: `${process.env.REACT_APP_API_BASE_URL}/api/v3/token_from_code_idp/`,
        end_session_endpoint: `${process.env.REACT_APP_KEY_CLOAK_URL}realms/${process.env.REACT_APP_KEY_CLOAK_REALM}/protocol/openid-connect/logout`,
    },
});

export default keycloak;