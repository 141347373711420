import React, { createContext, useState, useContext } from "react";

const UploadedFilesContext = createContext();

export const UploadedFilesProvider = ({ children }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  return (
    <UploadedFilesContext.Provider value={{ uploadedFiles, setUploadedFiles }}>
      {children}
    </UploadedFilesContext.Provider>
  );
};

export const useUploadedFiles = () => useContext(UploadedFilesContext);
