// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credit .p-datepicker-trigger {
    border: none;
}
.no-data-div {
    margin-top: 7rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/CreditSummary/OrgTransactionChart.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".credit .p-datepicker-trigger {\n    border: none;\n}\n.no-data-div {\n    margin-top: 7rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
