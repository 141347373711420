// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: #21213f; /* Custom background color */
  padding: 0.75rem 1.25rem; /* Add some padding */
  border: 0px;
  flex-wrap: nowrap;
}

.header-title h1 {
  margin: 0;
  font-size: 20px;
  color: #21213f;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1rem;
}

.p-overlaypanel {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.noTaskContainer {
  margin-top: 10rem;
}
.header-profile {
  display: flex;
  align-items: center;
}

.header-profile .profile-name {
  margin-right: 10px; /* Space between name and icon */
  color: #21213f;
  background-color: #21213f;
}

.header-profile .pi-user {
  font-size: 1.5rem; /* Adjust icon size */
}

.logout-popup-button {
  border: 1px solid #e4e9f1;

}

.logout-popup-button span {
  font-weight: 400 !important;

}

.hr-popup-border {
  border-top: 0px;
}
.title-container {
  height: 2.8rem;
  line-height: 3;
}

.header-center {
  font-weight: 400;
  background-color: rgba(209, 216, 255, 0.3);
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(66, 133, 246, 1);
}

@media screen and (max-width: 767px) {
  .header {
    margin-bottom: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/Header/header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,4BAA4B;EACvD,wBAAwB,EAAE,qBAAqB;EAC/C,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB,EAAE,gCAAgC;EACpD,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB,EAAE,qBAAqB;AAC1C;;AAEA;EACE,yBAAyB;;AAE3B;;AAEA;EACE,2BAA2B;;AAE7B;;AAEA;EACE,eAAe;AACjB;AACA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,0CAA0C;EAC1C,eAAe;EACf,qBAAqB;EACrB,uCAAuC;AACzC;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".header {\n  background-color: #21213f; /* Custom background color */\n  padding: 0.75rem 1.25rem; /* Add some padding */\n  border: 0px;\n  flex-wrap: nowrap;\n}\n\n.header-title h1 {\n  margin: 0;\n  font-size: 20px;\n  color: #21213f;\n}\n\n.p-overlaypanel .p-overlaypanel-content {\n  padding: 1rem;\n}\n\n.p-overlaypanel {\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n}\n\n.noTaskContainer {\n  margin-top: 10rem;\n}\n.header-profile {\n  display: flex;\n  align-items: center;\n}\n\n.header-profile .profile-name {\n  margin-right: 10px; /* Space between name and icon */\n  color: #21213f;\n  background-color: #21213f;\n}\n\n.header-profile .pi-user {\n  font-size: 1.5rem; /* Adjust icon size */\n}\n\n.logout-popup-button {\n  border: 1px solid #e4e9f1;\n\n}\n\n.logout-popup-button span {\n  font-weight: 400 !important;\n\n}\n\n.hr-popup-border {\n  border-top: 0px;\n}\n.title-container {\n  height: 2.8rem;\n  line-height: 3;\n}\n\n.header-center {\n  font-weight: 400;\n  background-color: rgba(209, 216, 255, 0.3);\n  padding: 0.5rem;\n  border-radius: 0.5rem;\n  border: 1px solid rgba(66, 133, 246, 1);\n}\n\n@media screen and (max-width: 767px) {\n  .header {\n    margin-bottom: 1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
