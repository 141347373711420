// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.creditSummaryTable {
    .p-button-filter {
        border-color: var(--theme_primary-button);
        color: var(--theme_primary-button);
    }

    table {
        font-size: 14px;

        .p-datatable-thead>tr>th {
            padding: 1rem 0.75rem;
        }

        .p-datatable-tbody>tr>td {
            padding: 0.75rem;
        }


    }

    .p-datatable .p-datatable-tbody>tr.p-highlight {
        color: #495057;
    }


}
.credit-history-btn {
    .p-button.p-highlight {
        background-color: var(--surface-100);
        border-color: var(--surface-300);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Credits/CreditSummary.css"],"names":[],"mappings":"AAAA;IACI;QACI,yCAAyC;QACzC,kCAAkC;IACtC;;IAEA;QACI,eAAe;;QAEf;YACI,qBAAqB;QACzB;;QAEA;YACI,gBAAgB;QACpB;;;IAGJ;;IAEA;QACI,cAAc;IAClB;;;AAGJ;AACA;IACI;QACI,oCAAoC;QACpC,gCAAgC;IACpC;AACJ","sourcesContent":[".creditSummaryTable {\n    .p-button-filter {\n        border-color: var(--theme_primary-button);\n        color: var(--theme_primary-button);\n    }\n\n    table {\n        font-size: 14px;\n\n        .p-datatable-thead>tr>th {\n            padding: 1rem 0.75rem;\n        }\n\n        .p-datatable-tbody>tr>td {\n            padding: 0.75rem;\n        }\n\n\n    }\n\n    .p-datatable .p-datatable-tbody>tr.p-highlight {\n        color: #495057;\n    }\n\n\n}\n.credit-history-btn {\n    .p-button.p-highlight {\n        background-color: var(--surface-100);\n        border-color: var(--surface-300);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
