import React, { useEffect, useRef, useState } from "react";
import "./uploader.css";
import upoloadIcon from "../assets/WorkSpaceIcons/UploadIcon.svg";
import { useUploadProgress } from "../context/uploaderContext";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import { FileUpload } from "primereact/fileupload";

const FileUploader = ({
  files,
  setFiles,
  fileSize,
  disableFileLimit,
  isCancelTrue = false,
  uploaderComponent = "",
  showProgress = true,
  fileSizeValue = "",
  cancelRequest,
}) => {
  const toast = useRef(null);
  const { uploadProgress, setUploadProgress } = useUploadProgress();
  const [uploaderText, setUploaderText] = useState(false);
  const disableUploadComponent = files.length >= disableFileLimit;
  const fileUploadRef = useRef(null);

  // Checks if all the files are 100% upload.
  useEffect(() => {
    const allFilesUploaded = Object.values(uploadProgress).every((value) => value === 100);
    setUploaderText(allFilesUploaded);
  }, [uploadProgress]);

  //Upload files method
  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.files);
    event.originalEvent.preventDefault();

    if (newFiles.length + files.length > disableFileLimit) {
      toast.current.show({
        severity: "error",
        detail: `You can only upload up to ${disableFileLimit} files.`,
      });
      fileUploadRef.current.clear();
      return;
    }

    if (newFiles.length > 0) {
      const validFiles = newFiles.filter((file) => {
        if (file.name.length > 50) {
          toast.current.show({
            severity: "error",
            detail: `Filename "${file.name}" exceeds 50 characters.`,
          });
          return false;
        }
        if (file.size > fileSize) {
          toast.current.show({
            severity: "error",
            detail:
              "Some files exceed the maximum size limit. Please upload files up to 30MB in size.",
          });
          return false;
        }
        return true;
      });
      setFiles([...files, ...validFiles]);
    }
    fileUploadRef.current.clear();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);
    if (
      newFiles.find((file) => {
        const extension = file.name.split(".").pop().toLowerCase();
        return extension !== "pdf" && extension !== "doc" && extension !== "docx";
      })
    ) {
      return toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please upload only PDF, DOC, and DOCX files.",
      });
    }
    setFiles([...files, ...newFiles]);
  };
  const handleRemoveFile = (index) => {
    cancelRequest();
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };
  const preventDefault = (event) => event.preventDefault();

  return (
    <>
      <Toast ref={toast} />
      <div className="file-uploader">
        <FileUpload
          ref={fileUploadRef}
          pt={{
            basicButton: {
              id: "file-upload",
              className: "file-input",
            },
          }}
          multiple={disableFileLimit > 1}
          mode="basic"
          onSelect={handleFileUpload}
          disabled={disableUploadComponent}
          accept=".pdf,.doc,.docx"
          className="file-input"
          uploadHandler={(e) => e.options.clear()}
        />
        <div
          className={`upload-area ${
            disableUploadComponent ? "disable-button cursor-not-allowed" : "hover:border-blue-500"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            document.getElementById("file-upload").click();
          }}
          onDragOver={preventDefault}
          onDrop={handleDrop}
        >
          <label
            htmlFor="file-upload"
            className={`upload-message text-sm ${disableUploadComponent ? "text-blue-200" : "text-blue-400"}`}
          >
            <img src={upoloadIcon} alt="uploadIcon" />
            <p className="mb-1">
              <span
                className={`underline  ${disableUploadComponent ? "text-blue-300" : "text-blue-600"}`}
              >
                Click To Upload
              </span>{" "}
              or drag and drop
            </p>
            <p className="w-12 md:w-8 mt-0 mx-auto">
              PDF, DOCUMENT, and DOCX files (
              {disableFileLimit === 1
                ? "Max file size 30 MB"
                : "Max 10 files allowed with file size 30 MB each"}
              )
            </p>
          </label>
        </div>
        {uploaderComponent === "dialog" && files.length !== 0 && (
          <div className="text-md font-bold mt-4">{`${uploaderText ? "Uploaded" : "Uploading"} ${files.length}/${files.length}`}</div>
        )}

        <div className="uploaded-files">
          {files.map((file, index) => {
            const truncatedFileName = file.name;
            return (
              <div key={index} className="uploaded-file-item text-sm flex">
                <div className="file-details">
                  <div className="file-name-container">
                    <i className="pi pi-file-pdf file-icon text-md"></i>

                    <div className="file-name">
                      <div className="text-sm text-ellipsis">{truncatedFileName}</div>
                      <div className="text-sm file-size">
                        {isCancelTrue ? (file.size / 1024 / 1024).toFixed(2) : fileSizeValue} MB
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 align-items-center">
                    {showProgress && uploadProgress[file.name] > 0 && (
                      <div className="text-right ">
                        <div className="mb-2">{`${uploadProgress[file.name]}%`}</div>
                        <ProgressBar
                          value={uploadProgress[file.name]}
                          className="file-progress text-blue-500"
                          showValue={false}
                        />
                      </div>
                    )}
                    {isCancelTrue && (
                      <button className="remove-button" onClick={() => handleRemoveFile(index)}>
                        &times;
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FileUploader;
