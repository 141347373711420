import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect } from "react";

const validateAllocation = (allocationType) => {
  const dailyLimitValid = /^[0-9]\d*$/.test(allocationType?.daily_limit);
  const errors = {};
  if (allocationType.allocation_type === "unlimited") return errors;
  if (allocationType.is_daily_limit_enabled) {
    if (!allocationType.daily_limit) {
      errors.daily_limit = "Please enter daily limit";
    } else if (!dailyLimitValid) {
      errors.daily_limit = "Please enter only positive numbers";
    }
  }
  if (allocationType.allocation_type === "one_time") {
    if (!allocationType.credits_to_be_allotted) {
      errors.credits_to_be_allotted = "Please enter credits";
    }
    if (allocationType.credits_to_be_allotted < 100) {
      errors.credits_to_be_allotted = "Minimum credits should be 100";
    }
    return errors;
  }
  if (allocationType.allocation_type === "recurring") {
    if (!allocationType.credits_to_be_allotted) {
      errors.credits_to_be_allotted = "Please enter credits";
    }
    if (allocationType.credits_to_be_allotted < 100) {
      errors.credits_to_be_allotted = "Minimum credits should be 100";
    }
    if (!allocationType.frequency) {
      errors.frequency = "Please enter frequency";
    }
    if (!allocationType.frequency_unit) {
      errors.frequency = "Please select frequency unit";
    }
    return errors;
  }
};

const ManageAllocation = ({
  allocationType,
  setAllocationType,
  errors,
  setErrors,
  isEditRecurring = false,
  email = "",
  creditPool = 0,
}) => {
  const onAllocationTypeChange = (e) => {
    setAllocationType((prev) => ({
      ...prev,
      allocation_type: e.value,
    }));
  };

  useEffect(() => {
    setErrors(validateAllocation(allocationType));
  }, [allocationType]);

  return (
    <div className="w-full">
      {!isEditRecurring && (
        <>
          <p className="text-sm m-0">Allocation Type</p>
          <div className="flex flex-wrap gap-3 mt-2">
            <div className="flex align-items-center">
              <RadioButton
                inputId="type1"
                name="allocation-type"
                value="one_time"
                onChange={onAllocationTypeChange}
                checked={allocationType.allocation_type === "one_time"}
              />
              <label htmlFor="ingredient1" className="text-sm ml-2">
                One-Time
              </label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                inputId="type2"
                name="allocation-type"
                value="recurring"
                onChange={onAllocationTypeChange}
                checked={allocationType.allocation_type === "recurring"}
              />
              <label htmlFor="ingredient2" className="text-sm ml-2">
                Recurring
              </label>
            </div>
            <div className="flex align-items-center">
              <RadioButton
                inputId="type3"
                name="allocation-type"
                value="unlimited"
                onChange={onAllocationTypeChange}
                checked={allocationType.allocation_type === "unlimited"}
              />
              <label htmlFor="ingredient3" className="text-sm ml-2">
                Unlimited
              </label>
            </div>
          </div>
        </>
      )}
      {allocationType.allocation_type === "unlimited" ? (
        <>
          <div
            className="text-xs px-3 py-2 mt-4 border-round-md"
            style={{
              backgroundColor: "rgba(255, 241, 218, 1)",
              fontWeight: 400,
              lineHeight: "16px",
            }}
          >
            {`You've set a No credit limit for ${email}, giving them unrestricted credits for their tasks. You can always change the credit limit from their profile if needed.`}
          </div>
          <small className="text-xs text-500 font-light mt-1">Credit Pool: {creditPool}</small>
        </>
      ) : (
        <div className="ml-3">
          <div className="flex flex-column gap-2 mt-2">
            <p className="text-sm mt-3 m-0">Enter Credits</p>
            <InputText
              className="w-8 text-sm"
              placeholder="Enter Credits"
              value={allocationType.credits_to_be_allotted}
              onChange={(e) =>
                setAllocationType((prev) => ({ ...prev, credits_to_be_allotted: e.target.value }))
              }
            />
            {errors.credits_to_be_allotted && (
              <small className="text-xs text-red-500">{errors.credits_to_be_allotted}</small>
            )}
            <small className="text-xs text-500">Credit Pool: {creditPool}</small>
          </div>

          {allocationType.allocation_type === "recurring" && (
            <div className="flex flex-column gap-2 mt-3 w-8">
              <p className="text-sm m-0">Select Frequency</p>
              <div className="flex gap-3 mt-2">
                <InputText
                  className="text-sm w-6"
                  placeholder="e.g. 2"
                  value={allocationType.frequency}
                  onChange={(e) =>
                    setAllocationType((prev) => ({
                      ...prev,
                      frequency: e.target.value,
                    }))
                  }
                />

                <Dropdown
                  className="text-sm w-6"
                  pt={{
                    input: {
                      className: "text-sm",
                    },
                  }}
                  placeholder="Select"
                  options={[
                    { label: "Day", value: "days" },
                    { label: "Week", value: "weeks" },
                    { label: "Month", value: "months" },
                  ]}
                  value={allocationType.frequency_unit}
                  onChange={(e) =>
                    setAllocationType((prev) => ({
                      ...prev,
                      frequency_unit: e.target.value,
                    }))
                  }
                />
              </div>
              {errors.frequency && (
                <small className="text-xs text-red-500">{errors.frequency}</small>
              )}
            </div>
          )}

          <div className="flex align-items-center mt-3">
            <Checkbox
              inputId="active"
              checked={allocationType.is_daily_limit_enabled}
              onChange={(e) =>
                setAllocationType((prev) => ({
                  ...prev,
                  is_daily_limit_enabled: e.checked,
                  daily_limit: e.checked ? "" : null,
                }))
              }
            />
            <label htmlFor="active" className="text-sm ml-2">
              Set Daily Usage Limit
            </label>
          </div>

          {allocationType.is_daily_limit_enabled && (
            <div className="flex flex-column gap-1">
              <InputText
                className="w-8 mt-2 text-sm"
                placeholder="Max credits per day"
                value={allocationType.daily_limit}
                onChange={(e) => {
                  setAllocationType((prev) => ({ ...prev, daily_limit: e.target.value }));
                }}
                pattern={/[^0-9]/g}
              />
              {errors.daily_limit && (
                <small className="text-xs text-red-500">{errors.daily_limit}</small>
              )}
            </div>
          )}

          {isEditRecurring && (
            <div
              className="mt-3  p-2 px-3 border-round-md"
              style={{
                backgroundColor: "rgba(255, 241, 218, 1)",
              }}
            >
              <p className="text-xs font-light m-0">
                Note: Changing the recurring credit limit will replace user’s current limit. No
                additional recurring credits will be added.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ManageAllocation;
