// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-round-top-12 {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.p-timeline .p-timeline-event-marker {
  border: 3px solid #3b82f6;
  border-radius: 60%;
  width: 0px;
  height: 0px;
  background-color: #ffffff;
}

.p-timeline-event-opposite {
  flex: none;
}

.input-doc-info,
.audit-log {
  transition: all 0.3s ease;
  overflow: hidden;
  height: 0;
}

.input-doc-info:has(> div),
.audit-log:has(> .custom-timeline) {
  height: auto;
  max-height: 500px; /* Adjust this value as needed */
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/DocumentEngine/WorkspaceSection/StatusTab.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,UAAU;AACZ;;AAEA;;EAEE,yBAAyB;EACzB,gBAAgB;EAChB,SAAS;AACX;;AAEA;;EAEE,YAAY;EACZ,iBAAiB,EAAE,gCAAgC;EACnD,gBAAgB;AAClB","sourcesContent":[".border-round-top-12 {\n  border-top-left-radius: 12px;\n  border-top-right-radius: 12px;\n}\n\n.p-timeline .p-timeline-event-marker {\n  border: 3px solid #3b82f6;\n  border-radius: 60%;\n  width: 0px;\n  height: 0px;\n  background-color: #ffffff;\n}\n\n.p-timeline-event-opposite {\n  flex: none;\n}\n\n.input-doc-info,\n.audit-log {\n  transition: all 0.3s ease;\n  overflow: hidden;\n  height: 0;\n}\n\n.input-doc-info:has(> div),\n.audit-log:has(> .custom-timeline) {\n  height: auto;\n  max-height: 500px; /* Adjust this value as needed */\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
